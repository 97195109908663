import React, { RefObject } from 'react'

import { Box, BoxProps, withCitrusTheme } from '@gousto-internal/citrus-react'

import { onSpaceOrEnter } from '../../../utils'
import pauseSvg from '../../../assets/ValueYouCanTasteSection/pause.svg'
import playSvg from '../../../assets/ValueYouCanTasteSection/play.svg'

type PlayPauseButtonProps = {
  videoRef: RefObject<HTMLVideoElement>
}

const StyledButton = ({ btnIcon, ...props }: BoxProps & { btnIcon: string }) => {
  const Component = withCitrusTheme(Box, () => ({
    cursor: 'pointer',
    background: `center/contain  no-repeat url(${btnIcon})`,
  }))
  return <Component {...props} />
}

export function PlayPauseButton({ videoRef }: PlayPauseButtonProps) {
  const [isPlaying, setIsPlaying] = React.useState(true)

  const handleClick = () => {
    if (!videoRef.current) {
      return
    }

    if (videoRef.current.paused) {
      videoRef.current.play()
      setIsPlaying(true)
    } else {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  return (
    <StyledButton
      aria-label={isPlaying ? 'Pause video' : 'Play video'}
      borderRadius="round"
      btnIcon={isPlaying ? pauseSvg : playSvg}
      height="32px"
      onClick={handleClick}
      onKeyDown={onSpaceOrEnter(handleClick)}
      role="button"
      width="32px"
      tabIndex={0}
    ></StyledButton>
  )
}
