/**
 * Commonly used colors
 */
export const colors = {
  black: '#333D47',
  blackberry: '#333D47',
  bluebase: '#615CFF',
  blueberry: '#3E3AC2',
  bluecheese: '#615cff',
  mackerel: '#D2D6D9',
  oyster: '#C0C5C9',
  white: '#ffffff',
  s200: '#A29EFF',
  s600: '#412dee',
  bluey: '#ABE0FA',
  darkBluey: '#5A5A87',
}

const fontFamilyAxiformaBook = 'AxiformaBook,Helvetica,sans-serif'

export const typography = {
  axiformaFontStack: 'Axiforma,Helvetica,sans-serif',
  sizeXXS: '11px',
  sizeXS: '13px',
  sizeSM: '14px',
  sizeMD: '16px',
  sizeLG: '18px',
  sizeXL: '20px',
  sizeXXL: '28px',
  sizeXXL2: '48px',
  fontFamilyAxiformaBook,
  fontStyleBody: `Medium ${fontFamilyAxiformaBook}`,
  fontFamilyAxiformaSemiBold: 'AxiformaSemiBold,Helvetica,sans-serif',
}

export const screen = {
  screen3XSMax: '(max-width: 350px)',
  screen3XSMin: '(min-width: 351px)',
  screenXXSMax: '(max-width: 543px)',
  screenXXSMin: '(min-width: 544px)',
  screenXSMax: '(max-width: 767px)',
  screenSMMin: '(min-width: 768px)',
  screenSMMax: '(max-width: 991px)',
  screenMDMin: '(min-width: 992px)',
  screenMLMin: '(min-width: 1025px)',
  screenMLMax: '(max-width: 1024px)',
  screenMDMax: '(max-width: 1199px)',
  screenLGMin: '(min-width: 1200px)',
  screenLGMax: '(max-width: 1599px)',
  screenXLMin: '(min-width: 1600px)',
}

export const zIndex = {
  top: '10000',
  modalBase: '20000',
  modalTop: '20010',
}
