import React from 'react'

import {
  AlignItems,
  Body1,
  Box,
  Button,
  Color,
  Display,
  Display3,
  Icon,
  IconVariant,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'

import { EFFORTLESS_HOME_COOKING } from '../../../config'
import { HomepageSections, TrackingActions, trackCTAClick } from '../../../tracking'

type BenefitsProps = {
  signupCTA: string
  onCTAClick: () => void
}

const { header, benefits } = EFFORTLESS_HOME_COOKING

export function Benefits({ signupCTA, onCTAClick }: BenefitsProps) {
  const clickCTA = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, HomepageSections.EFFORTLESS_HOME_COOKING)
  }

  const benefitList = benefits.map((benefit) => (
    <Box key={`key-${benefit}`}>
      <Box alignItems={AlignItems.FlexStart} display={Display.Flex}>
        <Icon name="tick" variant={IconVariant.Confirmation} />
        <Space direction="horizontal" size={1} />
        <Body1 size={[4, null, null, 6]}>{benefit}</Body1>
      </Box>
      <Space size={2} />
    </Box>
  ))

  return (
    <Box display={Display.Flex} alignItems={AlignItems.Center} width={[null, null, null, '100%']}>
      <Box>
        <Box paddingRight={0} width={[null, null, null, '350px']}>
          <Display3 color={Color.Primary_600}>{header}</Display3>
        </Box>
        <Space size={[4, null, null, 5]} />
        <Box maxWidth={[null, null, null, '380px']}>{benefitList}</Box>
        <Space size={3} />
        <Visible display={[Display.None, Display.Block, null, Display.Block]}>
          <Button width="320px" onClick={clickCTA} height="48px">
            {signupCTA}
          </Button>
          <Space size={[0, 3, 3, 0]} />
        </Visible>
      </Box>
    </Box>
  )
}
