import React, { ReactNode } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { Space, Button } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { homeGetStarted } from 'actions/home'

type CtaProps = {
  dataTesting?: string
  trackingSectionName: string
  ctaUri: string
  children: ReactNode
}

export const Cta = ({
  dataTesting = 'HomePageDefaultCta',
  trackingSectionName,
  ctaUri,
  children,
}: CtaProps) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    datadogRum.addAction('start_onboarding')
    dispatch(homeGetStarted(ctaUri, trackingSectionName))
  }

  return (
    <>
      <Button
        data-testing={dataTesting}
        onClick={handleClick}
        width="100%"
        maxWidth={['null', '17.5rem']}
        height="52px"
      >
        {children}
      </Button>
      <Space size={4} direction="vertical" />
    </>
  )
}
