import React from 'react'

import { useDeviceType, DeviceType } from '@library/dom'

import { Carousel } from '../../Carousel'
import { Slide } from './Slide/Slide'
import type { Recipe } from '../../../types/endlessVarietyCarousel'
import { HomepageSections } from '../../../tracking'

type RecipeCarouselProps = {
  recipes: Record<string, Recipe>
}

const propertiesForTracking = {
  carouselSection: HomepageSections.ENDLESS_VARIETY,
}

export const RecipeCarousel = ({ recipes }: RecipeCarouselProps) => {
  const deviceType = useDeviceType()
  const isMobileDevice = deviceType === DeviceType.MOBILE

  const recipesArr = Object.keys(recipes).map((key) => recipes[key])

  return (
    <Carousel
      carouselAreaLabel="Endless Variety"
      options={{ loop: true }}
      slidePaddingH={1}
      slidePaddingV={1}
      slideWidth="320px"
      showNavButtons={!isMobileDevice}
      snowplowTrackingProperties={propertiesForTracking}
    >
      {recipesArr.reduce((acc: JSX.Element[], recipe, index) => {
        if (recipe?.media?.images.length) {
          acc.push(<Slide key={`${recipe.title}-${index}`} recipe={recipe} />)
        }
        return acc
      }, [])}
    </Carousel>
  )
}
