import React, { useState } from 'react'
import useSWR from 'swr'
import { Field, FieldProps, Form, Formik, FormikValues } from 'formik'
import {
  Join,
  Space,
  Button,
  Checkbox,
  Box,
  Text,
  ButtonColorVariant,
  Color,
} from '@gousto-internal/citrus-react'
import { fetchCurrentUser, updateCurrentUser, User, UserRequestBody } from '@library/api-user'

import { ApiKeys } from '../../enums'

interface CheckboxMetadata {
  clientPropName: keyof User
  serverPropName: keyof UserRequestBody
}

const isChecked = (value: unknown): boolean => value === true || value === '1'

const checkboxMetadata: CheckboxMetadata[] = [
  { clientPropName: 'marketingDoAllowEmail', serverPropName: 'marketing_do_allow_email' },
  { clientPropName: 'marketingDoAllowPhone', serverPropName: 'marketing_do_allow_phone' },
  { clientPropName: 'marketingDoAllowPost', serverPropName: 'marketing_do_allow_post' },
  { clientPropName: 'marketingDoAllowSms', serverPropName: 'marketing_do_allow_sms' },
  { clientPropName: 'marketingDoAllowThirdparty', serverPropName: 'marketing_do_allow_thirdparty' },
]

function CommunicationPreferencesSection() {
  const { data, mutate } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const [errorMessage, setGenericError] = useState<string>()
  const onSubmit = async (values: FormikValues) => {
    setGenericError('')

    try {
      const requestPayload = checkboxMetadata.reduce(
        (memo, { serverPropName, clientPropName }) => ({
          ...memo,
          [serverPropName]: isChecked(values[clientPropName]) ? '1' : '0',
        }),
        {},
      )

      await mutate(updateCurrentUser(requestPayload))
    } catch (e) {
      setGenericError('Sorry, we couldn’t process your request right now. Please try again.')
    }
  }
  const initialValues = checkboxMetadata.reduce((memo, { clientPropName }) => {
    const user = (data?.user || {}) as User

    return {
      ...memo,
      [clientPropName]: isChecked(user[clientPropName]),
    }
  }, {})

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ isSubmitting, dirty }) => (
        <Form>
          <Space size={4} direction="vertical" />
          <Join with={<Space size={4} direction="vertical" />}>
            <Text>
              Get exclusive offers, fun tips, updates and be the first to hear about Gousto’s
              newest, tastiest recipes.
            </Text>
            <Box display="flex" wrap>
              <Box flexGrow={1} paddingRight={4}>
                <Field type="checkbox" name="marketingDoAllowEmail">
                  {({ field }: FieldProps) => (
                    <Checkbox id={field.name} {...field}>
                      Email
                    </Checkbox>
                  )}
                </Field>
              </Box>
              <Box flexGrow={1} paddingRight={4}>
                <Field type="checkbox" name="marketingDoAllowPost">
                  {({ field }: FieldProps) => (
                    <Checkbox id={field.name} {...field}>
                      Post
                    </Checkbox>
                  )}
                </Field>
              </Box>
              <Box flexGrow={1} paddingRight={4}>
                <Field type="checkbox" name="marketingDoAllowSms">
                  {({ field }: FieldProps) => (
                    <Checkbox id={field.name} {...field}>
                      SMS
                    </Checkbox>
                  )}
                </Field>
              </Box>
              <Box flexGrow={1} paddingRight={4}>
                <Field type="checkbox" name="marketingDoAllowPhone">
                  {({ field }: FieldProps) => (
                    <Checkbox id={field.name} {...field}>
                      Phone
                    </Checkbox>
                  )}
                </Field>
              </Box>
            </Box>
            <Field type="checkbox" name="marketingDoAllowThirdparty">
              {({ field }: FieldProps) => (
                <Checkbox id={field.name} {...field}>
                  Get relevant communications from Gousto’s partners, such as special promotions and
                  free product samples.
                </Checkbox>
              )}
            </Field>
            {errorMessage && <Text color={Color.Error_700}>{errorMessage}</Text>}
            <Button
              width="100%"
              type="submit"
              disabled={!dirty || isSubmitting}
              colorVariant={ButtonColorVariant.Secondary}
            >
              Save
            </Button>
          </Join>
        </Form>
      )}
    </Formik>
  )
}

export { CommunicationPreferencesSection }
