import { AnyAction, createAsyncThunk, ThunkAction } from '@reduxjs/toolkit'

import { basketNumPortionChange } from 'actions/basket'
import { applyPromoCodeAndShowModal } from 'actions/home'
import { redirect } from 'actions/redirect'
import { trackClickBuildMyBox } from 'actions/tracking'
import routesConfig from 'config/routes'
import { getPromoBannerState } from 'routes/Home/homeUtils'
import { getBasketPostcode } from 'selectors/basket'

import { State } from './boxPricesModels'

export const boxPricesBoxSizeSelected = createAsyncThunk(
  '',
  async (numPersons: number, { dispatch, getState }) => {
    const state = getState() as State
    const postcode = getBasketPostcode(state)
    const { canApplyPromo } = getPromoBannerState(state)
    const destination = postcode ? 'menu' : 'wizard'

    dispatch(trackClickBuildMyBox(`${numPersons} people`, destination))
    dispatch(basketNumPortionChange(numPersons))

    if (destination === 'menu') {
      dispatch(redirect(routesConfig.client.menu))
    } else {
      if (canApplyPromo) {
        await dispatch(
          applyPromoCodeAndShowModal() as ThunkAction<void, unknown, unknown, AnyAction>,
        )
      }
      dispatch(redirect(`${routesConfig.client.signup}/postcode`))
    }
  },
)
