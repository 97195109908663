import React from 'react'

import { Heading4, Join, Space } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { MenuRecipeOption } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { menuRecipeDetailVisibilityChangeByRecipeUuid } from 'routes/Menu/actions/menuRecipeDetails'

import { useTrackVariantListDisplay } from '../../RecipeAlternativeOptions/RecipeAlternativeOptions/useTracking'
import { RecipeDetailsPageOptionItem } from './RecipeDetailsPageOptionItem'

import css from './RecipeDetailsPageOptions.css'

type Props = {
  recipeUuid: string
  options: MenuRecipeOption[]
}

export const RecipeDetailsPageOptions = ({ recipeUuid, options }: Props) => {
  const dispatch = useDispatch()
  const onChange = (checkedRecipeId: string) => {
    dispatch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      menuRecipeDetailVisibilityChangeByRecipeUuid(checkedRecipeId) as any,
    )
  }

  const hasAlternativeOptions = options?.length > 1

  useTrackVariantListDisplay({
    hasAlternativeOptions,
    view: 'details',
  })

  const preventPropagation = (e: React.SyntheticEvent) => e.stopPropagation()

  if (!hasAlternativeOptions) {
    return null
  }

  return (
    <div
      className={css.recipeList}
      role="button"
      tabIndex={-1}
      onClick={preventPropagation}
      onKeyPress={preventPropagation}
    >
      <Join with={<Space size={4} />}>
        <Heading4>Choose an option</Heading4>
        <ul className={css.recipeListText}>
          {options.map((option) => (
            <RecipeDetailsPageOptionItem
              key={option.item_id}
              recipeId={option.item_id}
              recipeName={option.name}
              changeCheckedRecipe={() => onChange(option.item_id)}
              isChecked={recipeUuid === option.item_id}
              isOutOfStock={!option.is_available}
              surcharge={option.surcharge?.price_per_portion}
            />
          ))}
        </ul>
      </Join>
    </div>
  )
}
