import { useTheme } from '@gousto-internal/citrus-react'
import { BrandTag } from '../../model/context/useRecipeBrand'

export const LabelColorsRecipeTile = (tag: BrandTag) => {
  const { theme } = useTheme()

  switch (tag.slug) {
    case 'fine-dine-in-tagline': {
      return {
        ...tag.theme,
        color: theme.tokens.colors.White,
        backgroundColor: theme.tokens.colors.ColdGrey_600,
      }
    }
    default: {
      return {
        ...tag.theme,
        color: theme.tokens.colors.ColdGrey_800,
        backgroundColor: theme.tokens.colors.White,
      }
    }
  }
}
