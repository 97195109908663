import { createSelector } from 'reselect'

import { getBasketMenuUuid } from 'selectors/basket'
import { getCollectionIdWithName } from 'utils/collections'

export const getRecipesInCollection = (menuCollections, collectionId) =>
  menuCollections.getIn([collectionId, 'recipesInCollection'], null)

export const getCollectionId = (collection) => collection.get('id')
const getCollectionSlug = (collection) => collection.get('slug')
const isCollectionPublished = (collection) => Boolean(collection.get('published'))

const isCollectionDefault = (collection) => Boolean(collection.get('default'))
const isCollectionRecommendations = (collection) =>
  getCollectionSlug(collection) === 'recommendations'

export const getMenuCollections = (state) => state.menuCollections
export const getCollectionSlugFromQuery = (state) => {
  if (
    !state.routing ||
    !state.routing.locationBeforeTransitions ||
    !state.routing.locationBeforeTransitions.query ||
    !state.routing.locationBeforeTransitions.query.collection
  ) {
    return null
  }

  return state.routing.locationBeforeTransitions.query.collection
}

export const getRecommendationsCollection = createSelector(
  getMenuCollections,
  (menuCollections) => menuCollections.find(isCollectionRecommendations) || null,
)

const collectionHasRecipes = (menuCollections, collectionId) => {
  const recipes = getRecipesInCollection(menuCollections, collectionId)

  if (!recipes) {
    return false
  }

  return recipes.size > 0
}

export const getDisplayedCollections = createSelector(getMenuCollections, (menuCollections) => {
  const collections = menuCollections.filter(
    (collection) =>
      isCollectionPublished(collection) &&
      collectionHasRecipes(menuCollections, getCollectionId(collection)),
  )

  return collections
})

export const getDefaultCollection = (state) => {
  const collections = getDisplayedCollections(state)

  const defaultCollection = collections.find(isCollectionDefault)

  if (defaultCollection) {
    return defaultCollection
  }

  const recommendationsCollection = collections.find(isCollectionRecommendations)

  if (recommendationsCollection) {
    return recommendationsCollection
  }

  if (collections.size > 0) {
    return collections.first()
  }

  return null
}

export const getCurrentCollectionId = (state, props) => {
  if (props && props.categoryId) {
    // eslint-disable-line
    return props.categoryId
  }

  const slug = getCollectionSlugFromQuery(state)

  if (slug) {
    const collectionId = getCollectionIdWithName(state, slug)

    if (collectionId) {
      return collectionId
    }
  }

  const defaultCollection = getDefaultCollection(state)

  if (defaultCollection) {
    return defaultCollection.get('id')
  }

  return null
}

export const getRecommendationShortName = createSelector(
  [getRecommendationsCollection],
  (recommendationCollection) => {
    const shortName = recommendationCollection && recommendationCollection.get('shortTitle')

    return shortName || ''
  },
)

const getCollectionsPerMenuFromProps = (_, { collectionsPerMenu }) => collectionsPerMenu

const getCurrentMenuCollectionsWithHeaders = createSelector(
  [getCollectionsPerMenuFromProps, getBasketMenuUuid],
  (collectionsPerMenu, menuUuid) => {
    const currentMenuCollections =
      collectionsPerMenu && collectionsPerMenu.find((menu) => menu.attributes.uuid === menuUuid)
    if (currentMenuCollections) {
      return currentMenuCollections.relationships.collections.data
    }

    return null
  },
)

const getHeadersFromProps = (_, { headers }) => headers

export const getCollectionsHeaders = createSelector(
  [getCurrentMenuCollectionsWithHeaders, getCurrentCollectionId, getHeadersFromProps],
  (collectionWithHeaders, collectionId, headers) => {
    const collectionInfo =
      collectionWithHeaders &&
      collectionWithHeaders.find((collection) => collection.id === collectionId)
    if (collectionInfo && headers) {
      return headers.find((header) => header.id === collectionInfo.header)
    }

    return null
  },
)
