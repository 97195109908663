import React from 'react'

import {
  Display,
  Box,
  FlexDirection,
  JustifyContent,
  AlignItems,
  Color,
  Button,
  Divider,
  BorderStyle,
  Heading3,
  ButtonVariant,
  ButtonColorVariant,
  Space,
  Join,
} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'

export const DeliveryDetails = () => (
  <Box alignItems={AlignItems.Center} display={Display.Flex} flexDirection={FlexDirection.Column}>
    <Box
      bg={Color.White}
      borderColor={Color.NeutralGrey_100}
      borderStyle={BorderStyle.Solid}
      borderTopWidth={0}
      borderWidth={0.5}
      width={['100%', '100%', '80%', '60%']}
    >
      <Box alignItems={AlignItems.Center} display={Display.Flex} paddingH={6} paddingV={4}>
        <Join with={<Space direction="horizontal" size={1} />}>
          <Button
            aria-label="Back"
            colorVariant={ButtonColorVariant.Tertiary}
            data-testid="back-button"
            icon="arrow_back"
            onClick={() => browserHistory.goBack()}
            variant={ButtonVariant.Icon}
          />
          <Heading3 fontWeight={700}>Delivery details</Heading3>
        </Join>
      </Box>
      <Box minHeight="70vh" />
      <Divider />
      <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingV={3}>
        <Button onClick={() => browserHistory.push('/menu')} maxWidth="400px" width="60%">
          Choose recipes
        </Button>
      </Box>
    </Box>
  </Box>
)
