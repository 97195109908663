import { saveAndSavourDiscountPerPortion } from './boxPricesConfig'

type Brand<T, B> = T & { __brand: B }

export type NumberString = Brand<string, 'NumberString'>

// This is a user-defined type-guard that returns true if a string value is a string representation of either an integer or a floating point with 2 decimals precision
export const isNumberString = (value: string): value is NumberString =>
  `${parseInt(value, 10)}` === value || `${parseFloat(value).toFixed(2)}` === value

export const applySaveAndSavourDiscount = (
  pricePerBox: NumberString,
  numPortionsPerBox: number,
  regularPricePerPortion: NumberString,
) => {
  const pricePerBoxValue = Number(pricePerBox)
  const regularPricePerPortionValue = Number(regularPricePerPortion)

  const totalDiscount = numPortionsPerBox * saveAndSavourDiscountPerPortion
  const saveAndSavourPricePerPortion = regularPricePerPortionValue - saveAndSavourDiscountPerPortion
  const saveAndSavourPricePerBox = pricePerBoxValue - totalDiscount

  return {
    saveAndSavourPricePerBox: saveAndSavourPricePerBox.toFixed(2),
    saveAndSavourPricePerPortion: saveAndSavourPricePerPortion.toFixed(2),
  }
}
