import { useEffect } from 'react'

import Immutable from 'immutable'
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux'
import { createSelector } from 'reselect'

import { promoGet } from 'actions/promos'
import {
  BPPC_PROMO_CODE_DATA,
  DISCOUNT_OPTIMISATION_V2_PROMO_CODE_DATA,
} from 'routes/Home/homeConfig'
import { Pricing, usePricing } from 'routes/Menu/domains/pricing'
import { getIsAuthenticated } from 'selectors/auth'
import { getPromoCode, getBasketRecipesCount } from 'selectors/basket'
import { createGetPromoStoreEntry, getPromoStore } from 'selectors/promoStoreSelectors'

export type DiscountDescriptor = {
  isDiscountEnabled: boolean
  discountKind?: 'flat' | 'percentage'
  discountAmount?: string
  isDiscountOptimised?: boolean
}

const isPositive = (price?: string | null): boolean => {
  if (!price) {
    return false
  }
  const parsed = Number.parseFloat(price)

  return !Number.isNaN(parsed) && parsed > 0
}

export const getDiscountFromPricing = (pricing?: Pricing | null): DiscountDescriptor => {
  if (!pricing) {
    return {
      isDiscountEnabled: false,
    }
  }
  const { flatDiscountApplied, amountOff, percentageOff } = pricing
  if (flatDiscountApplied && isPositive(amountOff)) {
    return {
      isDiscountEnabled: true,
      discountKind: 'flat',
      discountAmount: amountOff as string,
    }
  } else if (isPositive(percentageOff)) {
    return {
      isDiscountEnabled: true,
      discountKind: 'percentage',
      discountAmount: percentageOff as string,
    }
  } else {
    return {
      isDiscountEnabled: false,
    }
  }
}

export const getDiscountFromStore = createSelector(
  getPromoCode,
  getPromoStore,
  (promoCode: string | undefined, promoStore: Immutable.Map<string, any>): DiscountDescriptor => {
    const promoCodeDetails = promoStore.getIn([promoCode, 'details'])
    if (!promoCodeDetails) {
      return {
        isDiscountEnabled: false,
      }
    }

    const amountOff = promoCodeDetails.get('discount-whole-order-amount', null)
    const percentageOff = promoCodeDetails.get('discount-whole-order-percent', null)

    const isDiscountOptimised =
      promoCode === DISCOUNT_OPTIMISATION_V2_PROMO_CODE_DATA.defaultPromoCode ||
      promoCode === BPPC_PROMO_CODE_DATA.defaultPromoCode

    if (isPositive(amountOff)) {
      return {
        isDiscountEnabled: true,
        discountKind: 'flat',
        discountAmount: amountOff,
      }
    } else if (isPositive(percentageOff)) {
      return {
        isDiscountEnabled: true,
        discountKind: 'percentage',
        discountAmount: percentageOff,
        isDiscountOptimised,
      }
    } else {
      return {
        isDiscountEnabled: false,
      }
    }
  },
)

export const useEnsureCurrentPromoCodeEntryIsFetched = () => {
  const dispatch = useDispatch()
  const promoCode = useSelector(getPromoCode)
  const promoCodeEntry = useSelector(createGetPromoStoreEntry(promoCode))

  useEffect(() => {
    if (promoCode && !promoCodeEntry) {
      dispatch(promoGet(promoCode))
    }
  }, [promoCode, promoCodeEntry, dispatch])
}

/**
 * Returns the descriptor for currently-applicable discount.
 */
export const useDiscountDescriptor = (): DiscountDescriptor => {
  const isAuthenticated = useSelector<RootStateOrAny, boolean>(getIsAuthenticated)
  const recipeCount = useSelector<RootStateOrAny, number>(getBasketRecipesCount)
  const { pricing } = usePricing()

  useEnsureCurrentPromoCodeEntryIsFetched()

  // For new users, the promo code discount is extracted from the `promoStore`
  // slice based on the current promo code stored in the `basket` slice, unless
  // they have two or more recipes (to support promo codes with discount rules).
  // For existing users, from the pricing hook.
  const discountForNewUsers = useSelector<RootStateOrAny, DiscountDescriptor>(getDiscountFromStore)

  const result =
    isAuthenticated || recipeCount > 1
      ? {
          ...getDiscountFromPricing(pricing),
          isDiscountOptimised: discountForNewUsers.isDiscountOptimised,
        }
      : discountForNewUsers

  return result
}

/**
 * Returns discount tip for display.
 */
export const formatDiscountTip = (
  discountDescriptor: DiscountDescriptor,
  numRecipes: number,
): string | null => {
  const { isDiscountEnabled, discountKind, discountAmount, isDiscountOptimised } =
    discountDescriptor
  if (!isDiscountEnabled) {
    return null
  }
  const formattedAmount = Math.ceil(parseFloat(discountAmount as string))

  // Discount rules aren't applied when less than 2 recipes are selected
  // as the pricing API isn't called. Until we know the pricing API response
  // we have a legal obligation to display "up to x%"
  const isPromoCodeDataStatic = numRecipes < 2

  const promoCodePrefix = isDiscountOptimised && isPromoCodeDataStatic ? 'up to ' : ''

  const discountTip =
    discountKind === 'flat' ? `£${formattedAmount}` : `${promoCodePrefix}${formattedAmount}%`

  return discountTip
}
