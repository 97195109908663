import React, { useRef, useEffect, useState, useCallback } from 'react'

import {
  Box,
  Display,
  FlexDirection,
  Image,
  JustifyContent,
  Overflow,
  useTheme,
} from '@gousto-internal/citrus-react'
import Slider from 'react-slick'

import { canUseWindow } from '@library/environment'

import { SliderWrapper } from './HeroCarousel.styled'
import { getHeroCarouselData } from './heroCarouselData'

export const HeroCarousel = () => {
  const { theme } = useTheme()
  const carouselRef = useRef<HTMLDivElement>(null)

  // default number of slides to show, if resize logic fails
  const [numSlides, setNumSlides] = useState(8)

  const heroCarouselData = getHeroCarouselData()

  // This function sets the number of slides that the carousel should show based on the screen size
  const onResize = useCallback(() => {
    if (!carouselRef.current) {
      return
    }
    const breakpointFromMedium = +theme.tokens.breakpoints[0].split('px')[0]
    // getBoundingClientRect().width is a float
    const sliderContainerWidth = Math.floor(carouselRef.current.getBoundingClientRect().width)
    const isOnMobile = sliderContainerWidth < breakpointFromMedium

    const mobileSlideWidth = 132 // in pixels
    const desktopSlideWidth = 220 // in pixels

    const slideWidth = isOnMobile ? mobileSlideWidth : desktopSlideWidth
    // on center mode the slider adds effectively an extra slide (2 halfs either side)
    const slidesToShowCount = Math.ceil(sliderContainerWidth / slideWidth) - 1

    setNumSlides(slidesToShowCount)
  }, [theme.tokens.breakpoints])

  useEffect(() => {
    if (canUseWindow()) {
      window.addEventListener('resize', onResize)
      onResize()
    }

    return () => {
      if (canUseWindow()) {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [onResize])

  return (
    <div ref={carouselRef}>
      <SliderWrapper
        data-testid="HeroCarousel"
        maxHeight="130px"
        height="100%"
        overflowY={Overflow.Hidden}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.Center}
      >
        <Slider
          dots={false}
          adaptiveHeight={false}
          infinite
          arrows={false}
          speed={400}
          slidesToShow={numSlides}
          initialSlide={8}
          slidesToScroll={1}
          centerMode
          focusOnSelect
          swipeToSlide
          lazyLoad="progressive"
        >
          {heroCarouselData.map((image) => (
            <Box paddingH={1} key={image.key}>
              <Image src={image.imageUrl} alt="" />
            </Box>
          ))}
        </Slider>
      </SliderWrapper>
    </div>
  )
}
