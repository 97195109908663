import Immutable from 'immutable'

export type ImmutableRecipe = Immutable.Map<string, any>

const ratingSort = (a: ImmutableRecipe, b: ImmutableRecipe) =>
  b.getIn(['rating', 'average'], 0) - a.getIn(['rating', 'average'], 0)
const isMeat = (recipe: ImmutableRecipe) => recipe.get('dietType', '').toLowerCase() === 'meat'
const isFish = (recipe: ImmutableRecipe) => recipe.get('dietType', '').toLowerCase() === 'fish'
const isOther = (recipe: ImmutableRecipe) => !(isFish(recipe) || isMeat(recipe))

export const orderRecipes = (recipesMap: ImmutableRecipe) => {
  const recipes = recipesMap.toList()

  const featuredRecipes = recipes.filter((recipe) => recipe.get('isFeaturedRecipe'))
  const meatRecipes = recipes
    .filter((recipe) => !recipe.get('isFeaturedRecipe'))
    .filter(isMeat)
    .sort(ratingSort)

  const fishRecipes = recipes
    .filter((recipe) => !recipe.get('isFeaturedRecipe'))
    .filter(isFish)
    .sort(ratingSort)

  // veggie and anything else
  const otherRecipes = recipes
    .filter((recipe) => !recipe.get('isFeaturedRecipe'))
    .filter(isOther)
    .sort(ratingSort)

  const limit = Math.max(meatRecipes.size, fishRecipes.size, otherRecipes.size)

  let orderedRecipes = featuredRecipes as Immutable.List<any>

  for (let i = 0; i < limit; i++) {
    const meatRecipe = meatRecipes.get(i)
    const fishRecipe = fishRecipes.get(i)
    const otherRecipe = otherRecipes.get(i)
    if (meatRecipe) {
      orderedRecipes = orderedRecipes.push(meatRecipe)
    }
    if (fishRecipe) {
      orderedRecipes = orderedRecipes.push(fishRecipe)
    }
    if (otherRecipe) {
      orderedRecipes = orderedRecipes.push(otherRecipe)
    }
  }

  return orderedRecipes.reduce<Immutable.OrderedMap<string, ImmutableRecipe>>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (reducerState, recipe) => reducerState!.set(recipe.get('id'), recipe),
    Immutable.OrderedMap({}),
  )
}
