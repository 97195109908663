import PropTypes from 'prop-types'
import React from 'react'
import {Space} from '@gousto-internal/citrus-react'
import { browserHistory } from 'react-router'
import {
  clickClaimDiscountPopup,
  clickCloseDiscountPopup,
  clickCloseDiscountFailurePopup,
  clickGodClaimDiscountPopup,
  clickCloseGodDiscountPopup,
  clickEnterPromoCodeManuallyButton,
} from 'actions/trackingKeys'
import { CTA, Modal } from 'goustouicomponents'
import headerImage from 'media/images/discount-modal-header.jpg'
import { signupConfig } from 'routes/Signup/signupConfig'
import { EnterPromoCodeManuallyButton } from './EnterPromoCodeManuallyButton'
import { AgeVerifyContainer } from './AgeVerify'
import css from './PromoModal.css'

class PromoModal extends React.Component {
  handleClick = (type) => () => {
    const {
      error,
      promoApply,
      justApplied,
      closeModal,
      trackUTMAndPromoCode,
      basketPromoCodeChange,
      isGoustoOnDemandError,
      isGoustoOnDemandEnabled,
      percentageOff,
      promoResetGoustoOnDemandFlow,
    } = this.props

    if (isGoustoOnDemandError) {
      promoResetGoustoOnDemandFlow()

      return browserHistory.push('/')
    }

    if (error || justApplied) {
      const event = error ? clickCloseDiscountFailurePopup : clickCloseDiscountPopup
      trackUTMAndPromoCode(isGoustoOnDemandEnabled ? clickCloseGodDiscountPopup : event)
      if (!justApplied) {
        basketPromoCodeChange('')
      }

      return closeModal()
    } else {
      const closeModalEvent = isGoustoOnDemandEnabled
        ? clickCloseGodDiscountPopup
        : clickCloseDiscountPopup
      const claimDiscountEvent = isGoustoOnDemandEnabled
        ? clickGodClaimDiscountPopup
        : clickClaimDiscountPopup
      const eventType = type === 'close' ? closeModalEvent : claimDiscountEvent
      trackUTMAndPromoCode(
        eventType,
        isGoustoOnDemandEnabled ? { discount_amount: percentageOff } : null
      )

      return promoApply()
    }
  }

  handleEnterPromoCodeManuallyClick = () => {
    const { trackUTMAndPromoCode, closeModal, promoClear } = this.props
    trackUTMAndPromoCode(clickEnterPromoCodeManuallyButton)
    promoClear()
    closeModal()
    browserHistory.push(`/signup/${signupConfig.enterPromoCodeManuallyPageSlug}`)
  }

  render() {
    const {
      modalTextContent,
      title,
      error,
      buttonText,
      needsAgeVerification,
      isAgeVerified,
      pending,
      isGoustoOnDemandError,
      isGoustoOnDemandEnabled,
    } = this.props

    return (
      <div className={css.hideScroll} data-testing="promoModal">
        <Modal
          isOpen
          variant="floating"
          name="promo-modal"
          description="promo code modal"
          handleClose={this.handleClick('close')}
        >
          <div className={css.container} data-testing="promoModal">
            {!error && !isGoustoOnDemandError && (
              <img className={css.header} src={headerImage} alt="Enjoy a tasty offer on us" />
            )}
            {(error || isGoustoOnDemandError) && <h4 className={css.errorSubHeader}>{title}</h4>}
            <div className={css.contentContainer}>
              {!error && !isGoustoOnDemandError && <h4 className={css.subHeader}>{title}</h4>}
              {modalTextContent}
              <Space size={3} />
              {needsAgeVerification && !error ? <AgeVerifyContainer /> : null}
              <CTA
                size="medium"
                testingSelector="promoModalButton"
                onClick={this.handleClick('claimDiscount')}
                variant="primary"
                isFullWidth
                disabled={(needsAgeVerification && !isAgeVerified && !error) || pending}
              >
                {buttonText}
              </CTA>
              <EnterPromoCodeManuallyButton
                isGoustoOnDemandEnabled={isGoustoOnDemandEnabled}
                onClick={this.handleEnterPromoCodeManuallyClick}
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

PromoModal.propTypes = {
  modalTextContent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  promoApply: PropTypes.func,
  needsAgeVerification: PropTypes.bool,
  isAgeVerified: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  pending: PropTypes.bool,
  justApplied: PropTypes.bool,
  trackUTMAndPromoCode: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  isGoustoOnDemandError: PropTypes.bool,
  isGoustoOnDemandEnabled: PropTypes.bool,
  basketPromoCodeChange: PropTypes.func,
  percentageOff: PropTypes.string,
  promoResetGoustoOnDemandFlow: PropTypes.func,
  promoClear: PropTypes.func,

}

PromoModal.defaultProps = {
  promoApply: () => {},
  needsAgeVerification: false,
  isAgeVerified: false,
  pending: false,
  justApplied: false,
  trackUTMAndPromoCode: () => {},
  error: '',
  isGoustoOnDemandError: false,
  isGoustoOnDemandEnabled: false,
  basketPromoCodeChange: () => {},
  percentageOff: '',
  promoResetGoustoOnDemandFlow: () => {},
  promoClear: () => {}
}

export { PromoModal }
