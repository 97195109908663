import React from 'react'

import {
  Box,
  Icon,
  Text,
  Color,
  Display,
  AlignItems,
  JustifyContent,
  FontFamily,
  FontWeight,
  IconVariant,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { trackClickSubheadingBanner } from './highlightChoiceActions'

export const HighlightChoiceBanner = () => {
  const dispatch = useDispatch()

  const dispatchTrackClickSubheadingBanner = () => dispatch(trackClickSubheadingBanner())

  return (
    <Box
      paddingV={0}
      paddingBottom={4}
      display={Display.Flex}
      justifyContent={[
        JustifyContent.Center,
        JustifyContent.FlexStart,
        JustifyContent.FlexStart,
        JustifyContent.FlexStart,
      ]}
      data-testid="HighlightChoiceBannerContainer"
      onClick={dispatchTrackClickSubheadingBanner}
    >
      <Box
        bg={Color.Success_50}
        width="325px"
        height="2.5rem"
        display={Display.Flex}
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        gap={3}
        style={{ borderRadius: '3rem' }}
      >
        <Icon name="meals_per_box" size={6} variant={IconVariant.Confirmation} />
        <Text
          color={Color.ColdGrey_800}
          fontFamily={FontFamily.Book}
          fontWeight={FontWeight.Normal}
          size={2}
        >
          85+ veggie (incl vegan) recipes a month
        </Text>
      </Box>
    </Box>
  )
}
