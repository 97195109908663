import React from 'react'
import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  Space,
} from '@gousto-internal/citrus-react'

type RatingTextProps = {
  rating: number
  numReviews: number
  isFineDineIn?: boolean
}

export function RatingText({ rating, numReviews, isFineDineIn }: RatingTextProps) {
  return (
    <Box
      display={Display.Flex}
      alignItems={AlignItems.Center}
      color={isFineDineIn ? Color.White : Color.ColdGrey_600}
    >
      <Space size={2} direction="horizontal" />
      <Body2 size={2} display={Display.Inline} color={Color.Inherit}>
        |
      </Body2>
      <Space size={1} direction="horizontal" />
      <Icon name="rate_full" size={5} variant={IconVariant.Inherit} />
      <Space size={1} direction="horizontal" />
      <Body2 size={2} display={Display.Inline} color={Color.Inherit}>
        {rating} ({numReviews.toLocaleString()})
      </Body2>
    </Box>
  )
}
