import { createSelector } from 'reselect'

import { actionTypes } from 'actions/actionTypes'
import { getDisabledSlotDates, userHasAvailableSlots } from 'routes/Menu/selectors/boxSummary'
import { getPromoCode } from 'selectors/basket'
import { getLandingDay } from 'utils/deliveries'
import { addDisabledSlotIds } from 'utils/deliverySlotHelper'

import { SignupStore } from './models/SignupStore'

export const getPromoStore = ({ promoStore }: SignupStore) => promoStore

export const getCurrentPromoCodeData = createSelector(
  [getPromoCode, getPromoStore],
  (promoCode, promoStore) => {
    if (!promoCode) {
      return null
    }

    return promoStore.getIn([promoCode, 'codeData'], null)
  },
)
export const getCurrentPromoCodeCustomText2 = createSelector(
  [getCurrentPromoCodeData],
  (promoCodeData) => {
    if (!promoCodeData) {
      return null
    }

    return promoCodeData.getIn(['campaign', 'landingDetails2'], null)
  },
)

export const getPendingSlice = (state: SignupStore) => state.pending

export const createSelectIsPendingByActionType = (actionType: any) =>
  createSelector(getPendingSlice, (pendingSlice: any) => pendingSlice.get(actionType))

export const getNumberOfPeople = (state: SignupStore) => state.signup.getIn(['numberOfPeople'])

export const getTempDeliveryOptions = (state: SignupStore) => {
  const deliveryDaysWithDisabled = addDisabledSlotIds(state.boxSummaryDeliveryDays)

  const landing = getLandingDay(state, {
    useCurrentSlot: true,
    cantLandOnOrderDate: true,
    useBasketDate: false,
  })

  const tempDate = state.temp.get('date', landing.date)
  const tempSlotId = state.temp.get('slotId', landing.slotId)
  const disabledSlots = getDisabledSlotDates(state)

  return { deliveryDaysWithDisabled, tempDate, tempSlotId, disabledSlots }
}

export const getDistrict = (state: SignupStore) => state.signup.getIn(['wizard', 'district'])
export const getAmountOfCustomers = (state: SignupStore) =>
  state.signup.getIn(['wizard', 'amountOfCustomers'])
export const getUserHasAvailableSlots = (state: SignupStore) => userHasAvailableSlots(state)
export const getTempPostcode = (state: SignupStore) => state.temp.get('postcode', '')

export const getDeliveryDaysError = (state: SignupStore): string =>
  state.error.get(actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE, false) as string

export const getPostcodePending = (state: SignupStore) => state.basket.get('postcodePending')

export const getSignupIsDietaryConfirmed = ({ signup }: SignupStore) =>
  signup.get('isDietaryConfirmed')
export const getSignupDietaryPreferences = ({ signup }: SignupStore) =>
  signup.get('dietaryPreferences')
export const getSignupIsRecipeConfirmed = ({ signup }: SignupStore) =>
  signup.get('isRecipeConfirmed')
export const getSignupRecipePreferences = ({ signup }: SignupStore) =>
  signup.get('recipePreferences')

export const getMenuRecipes = (state: SignupStore) => state.menuRecipes

const getRecipesFromStore = ({ recipes }: SignupStore) => ({
  recipes,
})

export const getStoreRecipes = createSelector(getRecipesFromStore, ({ recipes }) => recipes)
