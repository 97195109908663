import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchRecipe } from 'routes/Menu/components/Detail/hooks'
import { getBasketDate, getBasketMenuUuid, getNumPortions } from 'selectors/basket'
import { getRecipePosition } from 'selectors/collections'
import { getRecipes } from 'selectors/root'

import { closeRecipeDetails } from '../../../actions/closeRecipeDetails'
import { getMenuRecipeIdForDetails } from '../../../selectors/menuRecipeDetails'
import { Detail } from '../Detail'

interface DetailOverlayProps {
  showOverlay?: boolean
}

const DetailOverlay = ({ showOverlay: showOverlayProp }: DetailOverlayProps) => {
  const showOverlay = Boolean(useSelector(getMenuRecipeIdForDetails)) && showOverlayProp
  const recipeId = useSelector(getMenuRecipeIdForDetails)
  const menuRecipeDetailShow = recipeId || ''
  const position = useSelector((state) => getRecipePosition(state, recipeId)) || null
  const recipesStore = useSelector(getRecipes)
  const numPortions = useSelector(getNumPortions)
  const detailRecipe = recipesStore.get(menuRecipeDetailShow)
  const dispatch = useDispatch()
  const onClose = useCallback(() => dispatch(closeRecipeDetails()), [dispatch])
  const deliveryDate = useSelector(getBasketDate)
  const menuId = useSelector(getBasketMenuUuid)
  const recipeUuid = recipesStore.getIn([recipeId, 'uuid'])

  const { loading, recipe, error, reload } = useFetchRecipe(
    menuId,
    recipeUuid,
    numPortions,
    deliveryDate,
  )

  if (!(showOverlay && detailRecipe && menuRecipeDetailShow && recipe)) {
    return null
  }

  return (
    <Detail
      id={detailRecipe.get('id')}
      title={detailRecipe.get('title')}
      showOverlay={showOverlay}
      loading={loading}
      error={error}
      recipe={recipe}
      reload={reload}
      position={position}
      onClose={onClose}
    />
  )
}

DetailOverlay.propTypes = {
  showOverlay: PropTypes.bool,
}

DetailOverlay.defaultProps = {
  showOverlay: false,
}

export { DetailOverlay }
