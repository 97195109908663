import React, { ReactNode } from 'react'

import {
  Box,
  DisplayPosition,
  Display,
  FlexDirection,
  Color,
  ZIndex,
  useTheme,
} from '@gousto-internal/citrus-react'

export const FixedCtaContainer = ({ children }: { children: ReactNode }) => {
  const { theme } = useTheme()

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      zIndex={ZIndex.Sticky}
      position={DisplayPosition.Fixed}
      bottom="0"
      right="0"
      left="0"
      paddingV={3}
      paddingH={3}
      paddingBottom={4}
      bg={Color.White}
      boxShadow={`0 -2px 12px ${theme.color(Color.ColdGrey_300)}`}
    >
      {children}
    </Box>
  )
}
