import {
  AlignItems,
  Box,
  Button,
  Col,
  Display,
  Display1,
  FlexDirection,
  Grid,
  Heading2,
  Image,
  JustifyContent,
  Space,
  TextAlign,
  Visible,
} from '@gousto-internal/citrus-react'
import { canUseWindow } from '@library/environment'

import React, { useEffect, useRef, useState } from 'react'
import DesktopSplitDishJPG from '../../assets/HeroSection/desktop-large-gousto-dish.jpg'
import DesktopSplitDishWEBP from '../../assets/HeroSection/desktop-large-gousto-dish.webp'
import MobileHeroJPG from '../../assets/HeroSection/mobile-gousto-dish.jpg'
import MobileHeroWEBP from '../../assets/HeroSection/mobile-gousto-dish.webp'
import DesktopHeroDishJPG from '../../assets/HeroSection/tablet-desktop-gousto-dish.jpg'
import DesktopHeroDishWEBP from '../../assets/HeroSection/tablet-desktop-gousto-dish.webp'
import { HERO } from '../../config'
import { HomepageSections, trackCTAClick, TrackingActions } from '../../tracking'
import { Benefits, GetStartedFloatingCTA, HighlightChoice } from './components'

export type HeroSectionProps = {
  signupCTA: string
  onCTAClick: () => void
}

export const HeroSection = ({ signupCTA, onCTAClick }: HeroSectionProps) => {
  const [isHeroInView, setIsHeroInView] = useState(true)
  const heroRef = useRef(null)

  useEffect(() => {
    if (!canUseWindow) return

    const observer = new IntersectionObserver(([entry]) => setIsHeroInView(entry.isIntersecting), {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    })

    if (heroRef.current) {
      observer.observe(heroRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const clickCTA = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, HomepageSections.HERO)
  }

  return (
    <section data-testid="heroSection">
      <Box ref={heroRef}>
        <Grid
          alignItems={AlignItems.Stretch}
          gap={4}
          paddingH={4}
          paddingV={4}
          paddingBottom={[0, 4]}
        >
          <Col size={[12, 6]}>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Column}
              justifyContent={JustifyContent.SpaceBetween}
              height="100%"
            >
              <Box>
                <Visible display={['block', 'none']}>
                  <Image alt="" src={MobileHeroJPG} srcSet={MobileHeroWEBP} />
                </Visible>
                <Space size={[4, 0]} />
                <Box paddingRight={[null, null, 8]}>
                  <Display1 textAlign={[TextAlign.Center, TextAlign.Left]} size={[10, 12, 13, 15]}>
                    {HERO.header}
                  </Display1>
                  <Space size={1} />
                  <Heading2 textAlign={[TextAlign.Center, TextAlign.Left]} size={[7, 8, 9, 11]}>
                    {HERO.subheader}
                  </Heading2>
                  <Space size={4} />
                  <HighlightChoice />
                  <Space size={4} />
                  <Button
                    width="100%"
                    maxWidth={['null', '280px']}
                    onClick={clickCTA}
                    height="48px"
                  >
                    {signupCTA}
                  </Button>
                  <Space size={4} />
                  <Benefits />
                </Box>
              </Box>
              <Box>
                <Visible display={['none', 'block']}>
                  <Image alt="" src={DesktopSplitDishJPG} srcSet={DesktopSplitDishWEBP} />
                </Visible>
              </Box>
            </Box>
          </Col>
          <Col size={[12, 6]}>
            <Box paddingH={[null, null, null, 2]} height="100%">
              <Visible display={['none', 'block']}>
                <Image
                  fit="cover"
                  height={['auto', '500px', '557px', '652px']}
                  alt=""
                  src={DesktopHeroDishJPG}
                  srcSet={DesktopHeroDishWEBP}
                />
              </Visible>
            </Box>
          </Col>
        </Grid>
        {!isHeroInView && <GetStartedFloatingCTA signupCTA={signupCTA} onCTAClick={onCTAClick} />}
      </Box>
    </section>
  )
}
