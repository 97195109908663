import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Space,
} from '@gousto-internal/citrus-react'

import { carousel } from 'routes/Home/homeConfig'

import { Cta } from '../Cta'
import { SectionTitle } from '../SectionTitle'
import { RecipeCarousel } from './RecipeCarousel/RecipeCarousel'

type CarouselProps = {
  numRecipes?: number
  ctaUri: string
  ctaText: string
}

export const Carousel = ({ numRecipes = 0, ctaUri, ctaText }: CarouselProps) => (
  <Box data-testid="RecipeCarouselSection" bg={Color.ColdGrey_900}>
    <Box paddingH={[3, 8]}>
      <Space direction="vertical" size={10} />
      <SectionTitle darkMode title={carousel.title} subTitle={carousel.subtitle} />
    </Box>
    {numRecipes > 0 ? <RecipeCarousel /> : null}
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} alignItems={AlignItems.Center}>
      <Box display={[Display.None, Display.Block]} width={['17.5rem']}>
        <Cta ctaUri={ctaUri} trackingSectionName="recipeCarousel">
          {ctaText}
        </Cta>
      </Box>
      <Space direction="vertical" size={[0, 6]} />
    </Box>
  </Box>
)
