import { composeParser } from '@library/http'
import { extractCoreData } from '@library/http/parsers'
import type {
  DeliveryInstruction,
  Order,
  ShippingAddress,
  ShippingAddressResponse,
  UpdatedCurrentUser,
} from './interfaces'
import { DELIVERY_INSTRUCTIONS } from './constants'

export const extractPendingOrders = composeParser(extractCoreData<Order[]>(), (orders: Order[]) =>
  orders.filter((o: Order) => o.state === 'pending'),
)

const extractAddressData = (data: ShippingAddressResponse): ShippingAddress => {
  const { name, companyname: companyName, deliveryInstructions, ...rest } = data
  const nameAndCompanyName = { companyName, name: name && name.length > 0 ? name : companyName }
  const neighbourPrefix = 'Neighbour:'

  if (deliveryInstructions && deliveryInstructions.indexOf(neighbourPrefix) === 0) {
    return {
      ...rest,
      ...nameAndCompanyName,
      deliveryInstructions: 'Neighbour',
      deliveryInstructionsCustom: (deliveryInstructions.split(neighbourPrefix)[1] || '').trim(),
    }
  }

  if (!DELIVERY_INSTRUCTIONS.includes(deliveryInstructions as DeliveryInstruction)) {
    return {
      ...rest,
      ...nameAndCompanyName,
      deliveryInstructions: 'Other',
      deliveryInstructionsCustom: deliveryInstructions,
    }
  }

  return { ...rest, ...nameAndCompanyName, deliveryInstructions }
}

export const extractShippingAddressData = composeParser(
  extractCoreData<ShippingAddressResponse[]>(),
  (data): ShippingAddress[] => data.map(extractAddressData),
)

export const extractAddressUpdatedData = composeParser(
  extractCoreData<{ updated: ShippingAddressResponse }>(),
  (data: { updated: ShippingAddressResponse }): ShippingAddress => extractAddressData(data.updated),
)

export const extractAddressCreatedData = composeParser(
  extractCoreData<{ created: ShippingAddressResponse }>(),
  (data: { created: ShippingAddressResponse }): ShippingAddress => extractAddressData(data.created),
)

export const extractUserResponse = composeParser(
  extractCoreData<UpdatedCurrentUser>(),
  (data: UpdatedCurrentUser) => ({
    user: {
      ...data.updated,
    },
  }),
)

export const extractEmptyResponse = async (resp: Response) => {
  if (resp.status >= 400) {
    throw new Error(resp.statusText)
  }
}
