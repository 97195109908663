import React from 'react'
import {
  Box,
  Space,
  TextAlign,
  Display,
  FlexDirection,
  BorderStyle,
  Color,
  Heading2,
  AlignItems,
  FontFamily,
} from '@gousto-internal/citrus-react'
import { PeekInTheBoxText } from './PeekInTheBoxText'

import { PEEK_BOX_TEXT } from '../PeekInTheBox.config'
import { CTAButton } from '../../CTAButton/CTAButton'
import { CTA_TEXT } from '../../'
import { peekInsideBoxGetStartedTrackingEvent } from '../../../tracking/trackingConfig'
import { SkipTheWizardExperimentType } from '../../../Pages/RafLandingPage'

export const PeekInTheBoxContent = ({ isSkipTheWizardEnabled }: SkipTheWizardExperimentType) => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    borderStyle={BorderStyle.Solid}
    borderColor={Color.ColdGrey_50}
    borderWidth={1}
    maxWidth={['500px', '800px', '425px']}
    style={{
      borderRadius: '15px',
    }}
  >
    <Box
      borderStyle={BorderStyle.Solid}
      borderWidth={1}
      borderColor={Color.Primary_600}
      style={{
        borderRadius: '15px',
        margin: '-1px -1px 0px -1px',
      }}
    >
      <Space direction="vertical" size={3} />
      <Heading2 size={6} textAlign={TextAlign.Center} fontFamily={FontFamily.Bold}>
        {PEEK_BOX_TEXT.title}
      </Heading2>
      <Space direction="vertical" size={3} />
    </Box>
    <Space direction="vertical" size={3} />
    <Box>
      <PeekInTheBoxText />
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        alignItems={AlignItems.Center}
        flexGrow={1}
      >
        <CTAButton
          trackingEvent={peekInsideBoxGetStartedTrackingEvent(isSkipTheWizardEnabled)}
          text={isSkipTheWizardEnabled ? CTA_TEXT.BUILD_BOX : CTA_TEXT.GET_STARTED}
          isSkipTheWizardEnabled={isSkipTheWizardEnabled}
        />
      </Box>
    </Box>
    <Space direction="vertical" size={3} />
  </Box>
)
