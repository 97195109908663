import React from 'react'

import { Box } from '@gousto-internal/citrus-react'
import Helmet from 'react-helmet'

import { RafLandingPage } from '@features/raf-landing-page'

import { useIsRafPersonalisationEnabled } from 'hooks/useIsRafPersonalisationEnabled'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { onScrollHandler } from 'routes/Home/Hero/heroUtils'

import { seo } from './seoConfig'

export const RafPage = () => (
  <Box>
    <Helmet title={seo.title} meta={seo.meta} />
    <RafLandingPage
      onScrollHandler={onScrollHandler}
      useIsSkipTheWizardEnabled={useIsSkipTheWizardEnabled}
      useIsRafPersonalisationEnabled={useIsRafPersonalisationEnabled}
    />
  </Box>
)
