import React, { useEffect, useRef, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  AlignItems,
  Body2,
  Box,
  Display,
  Divider,
  FlexDirection,
  Heading4,
  Space,
  Color,
  Text,
  FontFamily,
} from '@gousto-internal/citrus-react'
import { Recaptcha } from 'Recaptcha'
import { NullableReCAPTCHA } from 'Recaptcha/useChallengeClosingObserver'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { ErrorMessage } from 'routes/Checkout/Components/ErrorMessage/ErrorMessage'
import {
  loginAfterSignup,
  openLoginModal,
  setCurrentPaymentMethod,
  trackClickPayWithApplePay,
} from 'routes/Checkout/checkoutActions'
import { getApplePayRecaptchaExecutionStarted } from 'routes/Checkout/checkoutSelectors'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import css from './ApplePayButton.css'

type ExpressCheckoutPayWithApplePayProps = {
  initializeApplePay: () => void
}

const ExpressCheckoutDivider = () => (
  <Box display={Display.Flex} alignItems={AlignItems.Center} flexDirection={FlexDirection.Row}>
    <Box flexGrow={1}>
      <Divider />
    </Box>
    <Box paddingH={4}>
      <Text color={Color.ColdGrey_400} fontFamily={FontFamily.Bold} size={2}>
        or
      </Text>
    </Box>
    <Box flexGrow={1}>
      <Divider />
    </Box>
  </Box>
)

export const ExpressCheckoutPayWithApplePay = ({
  initializeApplePay,
}: ExpressCheckoutPayWithApplePayProps) => {
  const dispatch = useDispatch()

  // handler for Pay button
  const onPayClick = () => {
    dispatch(setCurrentPaymentMethod(PaymentMethod.ApplePay))
    dispatch({ type: actionTypes.APPLE_PAY_ERRORS_CLEAR })
    dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, null))
    dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, null))
    dispatch(trackClickPayWithApplePay())
    initializeApplePay()
    datadogLogs.logger.info('ApplePay Session Initiated')
  }

  // ReCAPTCHA related fields
  const recaptchaRef = useRef<NullableReCAPTCHA>(null)
  const [hasGotRecaptchaToken, setHasGotRecaptchaToken] = useState(false)
  const recaptchaExecutionStarted = useSelector(getApplePayRecaptchaExecutionStarted)

  useEffect(() => {
    if (!recaptchaExecutionStarted) return

    if (recaptchaRef.current) recaptchaRef.current.execute()
    // in case feature isRecaptchaEnabled is switched off
    else dispatch(loginAfterSignup())
  }, [dispatch, recaptchaExecutionStarted])

  const onRecaptchaTokenChange = (token: string | null) => {
    // to react only on first reCAPTCHA token (when token is expired, this method is called with null)
    if (hasGotRecaptchaToken) return

    setHasGotRecaptchaToken(true)
    dispatch(loginAfterSignup(token))
  }

  const onRecaptchaChallengeClose = () => {
    if (!hasGotRecaptchaToken) {
      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP_LOGIN, true))
    }
  }

  return (
    <>
      <Box
        paddingBottom={6}
        data-testing="expressCheckoutPayWithApplePay"
        data-testid="expressCheckoutPayWithApplePay"
      >
        <Heading4>Express Checkout</Heading4>
        <Space size={2} />
        <Body2>We’ll get your delivery details from Apple</Body2>
        <Space size={4} />
        <ErrorMessage onLoginClick={(e) => dispatch(openLoginModal(e))} />

        <button className={css.applePayButton} type="submit" onClick={onPayClick}>
          <span className={css.applePayButtonText}>Pay with</span>
          <span className={css.applePayButtonLogo} />
        </button>

        <Recaptcha
          ref={recaptchaRef}
          onChange={onRecaptchaTokenChange}
          onChallengeClose={onRecaptchaChallengeClose}
        />
        <Space size={3} />
        <ExpressCheckoutDivider />
      </Box>
    </>
  )
}
