import React from 'react'

import Immutable from 'immutable'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { ReactReduxContext } from 'react-redux'
import { queueTrackingEvent } from '@library/tracking-integration'

import { withOptimizely, ReactSDKClient } from '@optimizely/react-sdk'
import { AppAwarenessBanner } from './AppAwarenessBanner'
import { Cookbook } from './Cookbook'
import { CustomNotice } from './CustomNotice'
import { HeaderContainer } from './Header'
import { Notification } from './Notification'
import { OrderConflictConfirmationModalContainer } from './OrderConflictConfirmationModal/OrderConflictConfirmationModalContainer'
import { PaymentDetailsNotification } from './PaymentDetailsNotification'
import { ReferAFriend } from './ReferAFriend'
import { Section } from './Section'

import css from './MyGousto.css'

const propTypes = {
  userLoadOrders: PropTypes.func.isRequired,
  userGetReferralDetails: PropTypes.func.isRequired,
  card: PropTypes.instanceOf(Immutable.Map),
  orders: PropTypes.instanceOf(Immutable.Map),
  nameFirst: PropTypes.string,
  referralDetails: PropTypes.instanceOf(Immutable.Map),
  redirect: PropTypes.func,
  isCustomNoticeEnabled: PropTypes.bool,
  isMobileViewport: PropTypes.bool.isRequired,
  showAppAwareness: PropTypes.bool,
  rateRecipeCount: PropTypes.number,
  trackClickRateRecipes: PropTypes.func,
  userCheck3dsCompliantToken: PropTypes.func,
  goustoRef: PropTypes.string,
  isCardTokenNotCompliantFor3ds: PropTypes.bool,
  track3dsCompliantClick: PropTypes.func,
  userReset3dsCompliantToken: PropTypes.func,
  pending: PropTypes.bool,
  serverRedirectedFrom: PropTypes.string,
  setServerRedirectedFrom: PropTypes.func,
  optimizely: PropTypes.shape(ReactSDKClient),
}

const defaultProps = {
  card: Immutable.Map(),
  orders: Immutable.Map(),
  nameFirst: '',
  referralDetails: Immutable.Map(),
  redirect: () => {},
  isCustomNoticeEnabled: false,
  showAppAwareness: false,
  rateRecipeCount: 0,
  trackClickRateRecipes: () => {},
  userCheck3dsCompliantToken: () => {},
  goustoRef: '',
  isCardTokenNotCompliantFor3ds: false,
  track3dsCompliantClick: () => {},
  userReset3dsCompliantToken: () => {},
  pending: false,
  serverRedirectedFrom: null,
  setServerRedirectedFrom: () => {},
  optimizely: null,
}

class MyGoustoComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      is3dsTokenFetched: false,
    }
  }

  componentDidMount() {
    const {
      userLoadOrders,
      userGetReferralDetails,
      serverRedirectedFrom,
      setServerRedirectedFrom,
      optimizely,
    } = this.props

    userLoadOrders()
    userGetReferralDetails()
    const isRedirectingToMyGoustoExperimentEnabled = optimizely?.isFeatureEnabled('turnips_ofx_redirecting_to_my_gousto_page')
    if (isRedirectingToMyGoustoExperimentEnabled) {
      queueTrackingEvent({
        action: 'web_flow_redirect',
        properties: {
          returning_logged_in_user: serverRedirectedFrom === '/'
        }
      })
      setServerRedirectedFrom({url: serverRedirectedFrom, processed: true})
    }
  }

  componentDidUpdate(_en, _prevProps, _prevState) {
    const { userCheck3dsCompliantToken, goustoRef, pending, isCardTokenNotCompliantFor3ds } =
      this.props
    const { is3dsTokenFetched } = this.state

    if (!isCardTokenNotCompliantFor3ds && goustoRef && !pending && !is3dsTokenFetched) {
      this.setState({
        is3dsTokenFetched: true,
      })

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.debounceTimeout = setTimeout(() => {
        userCheck3dsCompliantToken()
      }, 500)
    }
  }

  componentWillUnmount() {
    const { userReset3dsCompliantToken, isCardTokenNotCompliantFor3ds } = this.props
    if (isCardTokenNotCompliantFor3ds) {
      userReset3dsCompliantToken()
      this.setState({
        is3dsTokenFetched: false,
      })
    }

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout)
    }
  }

  render() {
    const {
      card,
      orders,
      nameFirst,
      referralDetails,
      redirect,
      isCustomNoticeEnabled,
      isMobileViewport,
      showAppAwareness,
      rateRecipeCount,
      trackClickRateRecipes,
      track3dsCompliantClick,
      isCardTokenNotCompliantFor3ds,
    } = this.props
    const headerTitle = `Hello ${nameFirst},`
    const showAppAwarenessBanner = !isMobileViewport && showAppAwareness

    return (
      <div>
        <Helmet title="My Gousto Account | View Your Most Recent Deliveries and Recipes" />
        <div className={css.wrapper}>
          {isCardTokenNotCompliantFor3ds && (
            <PaymentDetailsNotification track3dsCompliantClick={track3dsCompliantClick} />
          )}
          <div className={css.notificationContent}>
            {showAppAwarenessBanner && <AppAwarenessBanner />}
            {isCustomNoticeEnabled && <CustomNotice />}
          </div>
          <div className={css.notificationContent}>
            <Notification card={card} orders={orders} />
          </div>
        </div>
        <Section hasPaddingBottom={false}>
          <OrderConflictConfirmationModalContainer />
        </Section>
        <Section title={headerTitle} largeTitle alternateColour hasPaddingBottom={false}>
          <HeaderContainer />
        </Section>
        <Cookbook rateRecipeCount={rateRecipeCount} trackClickRateRecipes={trackClickRateRecipes} />
        <Section title="Your Gousto wins" alternateColour>
          <ReferAFriend referralDetails={referralDetails} redirect={redirect} />
        </Section>
      </div>
    )
  }
}

MyGoustoComponent.propTypes = propTypes
MyGoustoComponent.defaultProps = defaultProps
MyGoustoComponent.contextType = ReactReduxContext

const MyGousto = withOptimizely(MyGoustoComponent)

export { MyGousto }
