import qs from 'qs'
import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setHeaders,
} from '@library/http/requests'

export type MenuRequestParams = {
  headers?: Record<string, string>
  accessToken?: string
  isAuthenticated?: boolean
  date?: string
  deliveryDate?: string
  userId?: string
  recipePreferenceIds?: string
  // admin link data
  'preview[menu_id]'?: string
  'preview[auth_user_id]'?: string
  'preview[expiry]'?: string
  'preview[signature]'?: string
}

type MenuRequestQueryParams = {
  include?: string[]
  addAlternatives: boolean
  date?: string
  delivery_date?: string
  userId?: string
  recipe_preference_ids?: string
  // admin link data
  'preview[menu_id]'?: string
  'preview[auth_user_id]'?: string
  'preview[expiry]'?: string
  'preview[signature]'?: string
}

const parseMenuResponse = async (response: Response) => {
  const responseClone = await response.text()
  return JSON.parse(responseClone)
}

const menuRequest = composeRequest(
  setServiceUrl('menu', 2),
  addPath((params: MenuRequestParams | undefined) => {
    const queryString: MenuRequestQueryParams = {
      addAlternatives: true,
    }

    const stringifyOptions: qs.IStringifyOptions = { arrayFormat: 'comma', encode: false }

    const isAdminQuery = params && params['preview[auth_user_id]']

    if (isAdminQuery) {
      queryString['preview[menu_id]'] = params['preview[menu_id]']
      queryString['preview[auth_user_id]'] = params['preview[auth_user_id]']
      queryString['preview[expiry]'] = params['preview[expiry]']
      queryString['preview[signature]'] = params['preview[signature]']
      return `menus?${qs.stringify(queryString, stringifyOptions)}`
    }

    if (params?.isAuthenticated && params.userId) {
      queryString.userId = params.userId
    }

    if (params?.deliveryDate) {
      queryString.delivery_date = params.deliveryDate
    }

    if (params?.recipePreferenceIds) {
      queryString.recipe_preference_ids = params.recipePreferenceIds
    }

    if (params?.date) {
      queryString.date = params.date
      return `menus?${qs.stringify(queryString, stringifyOptions)}`
    }

    return `menus/current?${qs.stringify(queryString, stringifyOptions)}`
  }),
  setMethod('GET'),
  setHeaders((params) => {
    return params?.accessToken
      ? { Authorization: `Bearer ${params.accessToken}` }
      : ({} as Record<string, string>)
  }),
  setHeaders((params) => {
    return params?.headers || {}
  }),
)

export const menuFetcher = composeFetch(menuRequest, parseMenuResponse)
