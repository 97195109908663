import React, { Fragment, useState, useEffect } from 'react'

import {
  AlignItems,
  Box,
  Display,
  DisplayPosition,
  Heading1,
  JustifyContent,
  Space,
  TextAlign,
  ZIndex,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import { useDispatch, useSelector } from 'react-redux'

import { getAvailableBoxSizes } from 'actions/basket'
import { menuLoadBoxPrices } from 'actions/menu'
import { boxSizes as boxSize, getHeroDetails } from 'routes/BoxPrices/boxPricesConfig'
import { getAvailableNumPortions } from 'selectors/basket'

import { BoxPricesContent } from './BoxPricesContent'
import { BoxPricesWithAllPortions } from './BoxPricesWithAllPortions/BoxPricesWithAllPortions'
import { NumPersonsToBoxDescriptors } from './boxPricesModels'
import { getNumPersonsToBoxDescriptors, getMenuBoxPricesRecieve } from './boxPricesSelectors'

const BoxPricesComponent = () => {
  const dispatch = useDispatch()

  const loading = useSelector(getMenuBoxPricesRecieve)
  const availableBoxSizes = useSelector(getAvailableNumPortions)

  const numPersonsToBoxDescriptors = useSelector(
    getNumPersonsToBoxDescriptors,
  ) as NumPersonsToBoxDescriptors

  const [activeNumPortion, setActiveNumPortion] = useState(2)
  const [activeRecipes, setActiveRecipes] = useState(boxSize[2].length - 1)

  const handleActivePortionSize = (portionSize: number) => {
    setActiveNumPortion(portionSize)
    setActiveRecipes(boxSize[portionSize].length - 1)
  }

  useEffect(() => {
    if (!availableBoxSizes?.length) {
      dispatch(getAvailableBoxSizes())
    }
    dispatch(menuLoadBoxPrices())
  }, [dispatch, availableBoxSizes?.length])
  const conditionalLoadingStyle = loading ? { filter: 'blur(5px)' } : {}

  return (
    <Fragment>
      {loading && (
        <Box
          display={Display.Flex}
          position={DisplayPosition.Fixed}
          top="0px"
          zIndex={ZIndex.Modal}
          width="100%"
          height="100%"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          data-testid="boxPricesComponentLoading"
        >
          <Loading />
        </Box>
      )}
      <Box style={conditionalLoadingStyle} data-testid="boxPricesComponentLoaded">
        <Space size={[6, 8]} direction="vertical" />
        <Heading1 textAlign={TextAlign.Center}>{getHeroDetails().header}</Heading1>
        <Space size={[6, 8]} direction="vertical" />
        {!loading && (
          <Box
            display={Display.Flex}
            justifyContent={JustifyContent.Center}
            gap={8}
            data-testid="boxPricesWithAllPortions"
          >
            {availableBoxSizes && (
              <BoxPricesWithAllPortions
                boxInfo={numPersonsToBoxDescriptors}
                onClickPortionSize={handleActivePortionSize}
                activeNumPortion={activeNumPortion}
                portionSizes={availableBoxSizes}
                activeRecipes={activeRecipes}
                setActiveRecipes={setActiveRecipes}
              />
            )}
          </Box>
        )}
        <Space size={[0, 18]} direction="vertical" />
        <BoxPricesContent />
      </Box>
    </Fragment>
  )
}

export { BoxPricesComponent }
