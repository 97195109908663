import { datadogLogs } from '@datadog/browser-logs'

import { actionTypes } from 'actions/actionTypes'
import { basketReset } from 'actions/basket'
import { trackSignupFinished } from 'actions/loggingmanager'
import loginActions from 'actions/login'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import { getEmail, getPasswordValue } from 'routes/Checkout/checkoutSelectors'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { getPreviewOrderId } from 'selectors/basket'
import { getUserLoginError } from 'selectors/status'
import Cookies from 'utils/GoustoCookies'
import { basketResetPersistent } from 'utils/basket'

const errorCodes = {
  duplicateDetails: '409-duplicate-details',
  promoCodeHasBeenUsed: '409-offer-has-been-used',
  challengeFailed: '3ds-challenge-failed',
  signupLoginFailed: 'signupLoginFailed',
}

export const loginAfterSignup: CheckoutActionCreator =
  (recaptchaToken: string) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.CHECKOUT_SIGNUP_START_RECAPTCHA_EXECUTION,
      executionStarted: false,
    })
    dispatch({ type: actionTypes.APPLE_PAY_START_RECAPTCHA_EXECUTION, executionStarted: false })
    dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP_LOGIN, null))
    dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP_LOGIN, true))

    const state = getState()

    const email = getEmail(state)
    const password = getPasswordValue(state)
    const orderId = getPreviewOrderId(state)

    await dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      loginActions.loginUser({ email, password, rememberMe: true, recaptchaToken }, orderId),
    )

    const stateAfterLogin = getState()
    const loginError = getUserLoginError(stateAfterLogin)
    if (loginError) {
      dispatch(
        trackCheckoutError(
          actionTypes.CHECKOUT_SIGNUP_LOGIN,
          errorCodes.signupLoginFailed,
          'checkoutPostSignup',
        ),
      )
      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP_LOGIN, true))
    } else {
      dispatch(trackSignupFinished({ email }))
      basketResetPersistent(Cookies)
      dispatch(basketReset())
      datadogLogs.logger.info('User logged in after signup')
    }

    dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP_LOGIN, false))
  }
