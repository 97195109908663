import React from 'react'
import { Body1, Box, Color, CSSKeywords, FontFamily } from '@gousto-internal/citrus-react'

/**
 * This component renders the promo banner copy exactly as in the
 *  immersive hero experiment and it's usage depends on the Immersive Hero
 *  being the Hero component used in the homepage redesign experiment.
 */
export function PromoBannerText({ children }: { children: string }) {
  return (
    <Box maxWidth={'100%'}>
      <Body1 color={Color.White} fontFamily={FontFamily.Bold}>
        {children}
      </Body1>
    </Box>
  )
}
