import React, { useEffect } from 'react'

import { debounce } from 'lodash'
import { datadogLogs } from '@datadog/browser-logs'

import {
  Box,
  Color,
  Display,
  DisplayPosition,
  Image,
  withCitrusTheme,
  Visible,
} from '@gousto-internal/citrus-react'
import { PlayPauseButton } from './PlayPauseButton'

import { VALUE_YOU_CAN_TASTE } from '../../../config'

import posterWebp from '../../../assets/ValueYouCanTasteSection/poster.webp'
import posterJpg from '../../../assets/ValueYouCanTasteSection/poster.jpg'

const GradientOverlay = withCitrusTheme(Box, (theme) => {
  const startColor = theme.color(Color.ColdGrey_800)
  const endColor = theme.color(Color.ColdGrey_900) + '00'

  return {
    background: `linear-gradient(to bottom, ${startColor}, ${endColor})`,
  }
})

export function Video() {
  const [showControls, setShowControls] = React.useState(false)

  const videoRef = React.useRef<HTMLVideoElement>(null)

  useEffect(() => {
    /*
     * Play the video if it is in the viewport
     * This is a performance optimisation to prevent the video file from being downloaded if it's, for example, is below the fold
     */
    const playIfInViewport = () => {
      const video = videoRef.current
      if (!video) {
        return
      }

      const { top, left } = video.getBoundingClientRect()
      if (top < window.innerHeight && left < innerWidth) {
        if (video.paused) {
          video.play().catch((error) => {
            datadogLogs.logger.error('ValueYouCanTaste video play failed:', error)
          })
          // remove the scroll listener once the video starts playing in order not to interfere with the user interaction with the controls
          window.removeEventListener('scroll', onScroll)
        }
      }
    }

    const onScroll = debounce(playIfInViewport, 100)

    window.addEventListener('scroll', onScroll)

    playIfInViewport()
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Box height={['560px', '100%']} position={DisplayPosition.Relative}>
      <video
        muted
        preload="none"
        loop
        poster={posterWebp}
        style={{ display: 'block', width: '100%', height: '100%', objectFit: 'cover' }}
        ref={videoRef}
        onCanPlay={() => setShowControls(true)}
      >
        <source src={VALUE_YOU_CAN_TASTE.videoURL} type="video/mp4" />
        {/* Fallback image in case browser does not support the video element*/}
        <Image src={posterJpg} srcSet={posterWebp} alt="video-poster" />
      </video>
      <Visible display={[Display.Block, Display.None]}>
        {/* Top position slightly above the video component to eliminate a tiny screen line not covered by the overlay at some screen sizes */}
        <GradientOverlay position={DisplayPosition.Absolute} top="-1px" height="60%" width="100%" />
      </Visible>
      {showControls && (
        <Box position={DisplayPosition.Absolute} bottom="16px" right="16px">
          <PlayPauseButton videoRef={videoRef} />
        </Box>
      )}
    </Box>
  )
}
