import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Map as ImmutableMap } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { RecipeTile, RecipeTileDependencies } from '@features/recipe-tile'
import { MenuApiPreferencesResponse } from '@library/menu-service/queries/menuApiPreferences/types'

import { useIsMenuPersonalisationEnabled } from 'hooks/useIsMenuPersonalisationEnabled'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { areEqualArrays } from 'routes/Menu/MenuRecipesPage/RecipeList/utils'
import {
  menuClosePropositionTileAction,
  selectRecipeVariant,
  showDetailRecipe,
  trackCloseThePropositionTile,
  trackShowThePropositionTile,
} from 'routes/Menu/actions/menuRecipeDetails'
import { CaloriesTutorial } from 'routes/Menu/components/MenuPreferences'
import { useTag } from 'routes/Menu/context/recipeContext'
import { useBasket, useStock } from 'routes/Menu/domains/basket'
import { CollectionId } from 'routes/Menu/domains/collections/constants'
import {
  useGetAlternativeOptionsForRecipe,
  useMenu,
  useSetBrowserCTAVisibility,
} from 'routes/Menu/domains/menu'
import { useIsMenuSocialProofEnabled } from 'routes/Menu/hooks/useIsMenuSocialProofEnabled'
import { getHasClosedPropositionTile } from 'routes/Menu/selectors/menu'
import { getSignupDietaryPreferences } from 'routes/Signup/signupSelectors'

import { useAuth } from '../../domains/auth/useAuth'
import { useCurrentCollectionId } from '../../domains/collections'
import { CTAToAllRecipes } from '../CTAToAllRecipes'
import { PropositionTile } from './PropositionTile'
import { buildTracker } from './recipeListTracking'
import { useSoldOutTracking } from './useSoldOutTracking'
import { useTracking as useTrackRecipeList } from './useTracking'

import css from './RecipeList.css'

export type RecipeListRecipe = {
  originalId: string
  recipe: ImmutableMap<string, any>
  reference: string
}

export const RecipeList = ({
  menuPreferences,
}: {
  menuPreferences?: MenuApiPreferencesResponse
}) => {
  const currentCollectionId = useCurrentCollectionId() as string
  const { getRecipesForCollectionId } = useMenu()
  const { getOutOfStockRecipeIds } = useStock()
  const { trackSoldOutRecipes } = useSoldOutTracking()

  const recipes = useMemo(
    () => getRecipesForCollectionId(currentCollectionId),
    [getRecipesForCollectionId, currentCollectionId],
  )
  const recipesArray = recipes.recipes.toJS()

  const shownRecipeIds = recipesArray.map(({ recipe }: { recipe: ImmutableMap<string, any> }) =>
    recipe.get('id'),
  )

  const recipesIdsRef = useRef(shownRecipeIds)

  if (!areEqualArrays(recipesIdsRef.current, shownRecipeIds)) {
    recipesIdsRef.current = shownRecipeIds
  }

  // to avoid eslint react-hooks/use-exhaustive-deps rule we have to introduce a new variable
  const recipeIds = recipesIdsRef.current
  useEffect(() => {
    const soldOutRecipes = getOutOfStockRecipeIds(recipeIds)
    trackSoldOutRecipes(soldOutRecipes)
  }, [recipeIds, getOutOfStockRecipeIds, trackSoldOutRecipes])

  const dispatch = useDispatch()
  const track = useTrackRecipeList()

  const { isAuthenticated } = useAuth()
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()

  const isMenuPersonalisationEnabled = useIsMenuPersonalisationEnabled()

  const hasClosedTile = useSelector(getHasClosedPropositionTile)

  const [isOnAllRecipesCategory, setIsOnAllRecipesCategory] = useState(
    currentCollectionId === CollectionId.AllRecipes,
  )
  const [showPropositionTile, setShowPropositionTile] = useState(
    (isSkipTheWizardEnabled || isMenuPersonalisationEnabled) &&
      !isAuthenticated &&
      !hasClosedTile &&
      isOnAllRecipesCategory,
  )

  useEffect(() => {
    const isOnAllRecipes = currentCollectionId === CollectionId.AllRecipes
    setIsOnAllRecipesCategory(isOnAllRecipes)
    setShowPropositionTile(
      (isSkipTheWizardEnabled || isMenuPersonalisationEnabled) &&
        !isAuthenticated &&
        !hasClosedTile &&
        isOnAllRecipes,
    )
  }, [
    currentCollectionId,
    isAuthenticated,
    isSkipTheWizardEnabled,
    hasClosedTile,
    isMenuPersonalisationEnabled,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => buildTracker({ recipes: recipesArray, currentCollectionId, track })(), [])

  useEffect(() => {
    if (showPropositionTile) {
      dispatch(trackShowThePropositionTile())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeTile = () => {
    setShowPropositionTile(false)
    dispatch(menuClosePropositionTileAction())
    dispatch(trackCloseThePropositionTile())
  }

  const { numPortions, canAddRecipes, addRecipe, removeRecipe, reachedLimit, isRecipeInBasket } =
    useBasket()

  const { isRecipeOutOfStock } = useStock()

  const renderPropositionTile = () => <PropositionTile onClose={closeTile} />

  const useRecipeBrand = useCallback(
    () => ({
      useTag,
    }),
    [],
  )

  const { setBrowserCTAVisible } = useSetBrowserCTAVisibility()

  const getAlternativeOptionsForRecipe = useGetAlternativeOptionsForRecipe()

  const onClick = useCallback((recipeId) => dispatch(showDetailRecipe(recipeId)), [dispatch])

  const selectVariant = useCallback(
    ({
      variantId,
      variantOutOfStock,
      originalRecipeId,
      recipeReference,
    }: {
      variantId: string
      variantOutOfStock: boolean
      originalRecipeId: string
      recipeReference: string
    }) => {
      dispatch(
        selectRecipeVariant({
          originalRecipeId,
          variantId,
          collectionId: currentCollectionId,
          variantOutOfStock,
          view: 'grid',
          close: true,
          recipeReference,
        }),
      )
    },
    [currentCollectionId, dispatch],
  )

  const isMenuSocialProofEnabled = useIsMenuSocialProofEnabled()
  const dietaryPreferences = useSelector(getSignupDietaryPreferences)

  return (
    <div className={css.threeTileRecipeList}>
      {showPropositionTile && renderPropositionTile()}

      <RecipeTileDependencies useRecipeBrand={useRecipeBrand}>
        {recipesArray.map((value: RecipeListRecipe, index: number) => {
          const alternativeOptions = getAlternativeOptionsForRecipe({
            recipeId: value.recipe.get('id'),
            categoryId: currentCollectionId,
          })

          return (
            <React.Fragment key={value.reference}>
              {index === 5 && <CaloriesTutorial />}
              <RecipeTile
                recipe={value.recipe.toJS()}
                currentCollectionId={currentCollectionId}
                onClick={onClick}
                isMenuSocialProofEnabled={isMenuSocialProofEnabled}
                menuPreferences={menuPreferences}
                setBrowserCTAVisible={setBrowserCTAVisible}
                numPortions={numPortions}
                canAddRecipes={canAddRecipes}
                addRecipe={addRecipe}
                removeRecipe={removeRecipe}
                reachedLimit={reachedLimit}
                isRecipeInBasket={isRecipeInBasket}
                isRecipeOutOfStock={isRecipeOutOfStock}
                selectVariant={selectVariant}
                alternativeOptions={alternativeOptions}
                recipeReference={value.reference}
                originalId={value.originalId}
                dietaryPreferences={dietaryPreferences}
                isMenuPersonalisationEnabled={isMenuPersonalisationEnabled}
              />
            </React.Fragment>
          )
        })}
      </RecipeTileDependencies>
      <CTAToAllRecipes />
    </div>
  )
}
