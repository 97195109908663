import { parseTimeRange } from 'utils/deliverySlot'
import { dayNumberMap } from '../../enum/day'

export const reduceUpdateFrequency = (state, subscription) => {
  if (!subscription.interval) {
    return state.slots.frequency
  }

  return {
    currentValue: subscription.interval
  }
}

export const reduceCurrentDeliverySlot = (state, reducedSlots) => {
  if (!state.subscription || !reducedSlots) {
    return state
  }

  const { deliverySlotId } = state.subscription

  return reducedSlots.find(({ coreSlotId }) => coreSlotId === deliverySlotId)
}

export const reduceDeliverySlot = (slot) => {
  const { deliveryStartTime, deliveryEndTime, defaultDay } = slot

  return {
    ...slot,
    timeRange: parseTimeRange(deliveryStartTime, deliveryEndTime),
    day: dayNumberMap[defaultDay],

  }
}

export const reduceDeliveryFrequency = (subscriptionData) => {
  if (!subscriptionData || !subscriptionData.subscription) {
    return subscriptionData
  }

  const { subscription } = subscriptionData
  const { interval } = subscription

  return {
    currentValue: interval
  }
}

export const reduceDeliveriesData = (state, data) => {
  const { slots, subscription } = data
  const frequency = reduceDeliveryFrequency(subscription)

  const slotsWithFrequencyAndRequestState = {
    slots: {
      ...slots,
      frequency,
      requestState: {
        isLoaded: true,
        isLoading: false
      },
    }
  }

  return {
    ...state,
    ...slotsWithFrequencyAndRequestState,
  }
}

export const reduceUpdatedDeliveriesData = (state, subscription) => {
  const currentDeliverySlot = reduceCurrentDeliverySlot(state, state.slots.data)
  const frequency = reduceUpdateFrequency(state, subscription)

  return {
    ...state,
    slots: {
      ...state.slots,
      currentDeliverySlot,
      frequency,
      requestState: {
        isLoaded: true,
        isLoading: false
      },
    },
  }
}
