import { DiscountDescriptor } from 'routes/Menu/components/BoxSummary/Banner/PriceAndDiscountTip/priceAndDiscountTipUtils'

export const formatDiscountTipWithDescription = (
  discountDescriptor: DiscountDescriptor,
): string | null => {
  const { isDiscountEnabled, discountKind, discountAmount, isDiscountOptimised } =
    discountDescriptor
  if (!isDiscountEnabled) {
    return null
  }
  const formattedAmount = Math.ceil(parseFloat(discountAmount as string))
  const promoCodePrefix = isDiscountOptimised ? 'up to ' : ''
  const discountTip =
    discountKind === 'flat'
      ? `£${formattedAmount} off first order`
      : `${promoCodePrefix}${formattedAmount}% off first order`

  return discountTip
}
