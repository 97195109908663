import React from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  Heading3,
  Image,
  JustifyContent,
} from '@gousto-internal/citrus-react'

import BritishBroadcastingCorporation from 'routes/Home/BrandEndorsements/assets/bbc.png'
import EveningStandard from 'routes/Home/BrandEndorsements/assets/eveningstandard.png'
import Independent from 'routes/Home/BrandEndorsements/assets/independent.png'
import MarieClaire from 'routes/Home/BrandEndorsements/assets/marieclaire.png'
import TheTelegraph from 'routes/Home/BrandEndorsements/assets/thetelegraph.png'

export const LogoImage = styled(Image)(() => ({
  opacity: 0.7,
  mixBlendMode: 'multiply',
}))

export const BrandEndorsements = () => (
  <Box
    data-testid="brandEndorsements"
    paddingV={6}
    paddingH={6}
    paddingBottom={12}
    bg={Color.NeutralGrey_50}
  >
    <Box
      paddingV={6}
      paddingH={6}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      alignItems={AlignItems.Center}
    >
      <Heading3>Tested and loved by</Heading3>
    </Box>
    <Box
      alignItems={AlignItems.Center}
      display={Display.Flex}
      flexDirection={[FlexDirection.Column, null, null, FlexDirection.Row]}
      justifyContent={JustifyContent.Center}
      gap={4}
      paddingH={4}
    >
      <Box
        alignItems={AlignItems.Center}
        display={Display.Flex}
        flexDirection={[FlexDirection.Column, FlexDirection.Row]}
        gap={4}
      >
        <Box
          alignItems={AlignItems.Center}
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          gap={4}
        >
          <Box>
            <LogoImage
              height="32px"
              width="auto"
              alt="bbclogo"
              src={BritishBroadcastingCorporation}
            />
          </Box>
          <LogoImage height="40px" width="auto" alt="thetelegraphlogo" src={TheTelegraph} />
        </Box>
        <LogoImage height="32px" width="auto" alt="marieclairelogo" src={MarieClaire} />
      </Box>
      <Box
        alignItems={AlignItems.Center}
        display={Display.Flex}
        flexDirection={[FlexDirection.Column, FlexDirection.Row]}
        gap={4}
      >
        <LogoImage height="40px" width="auto" alt="independentlogo" src={Independent} />
        <LogoImage height="32px" width="auto" alt="eveningstandardlogo" src={EveningStandard} />
      </Box>
    </Box>
  </Box>
)
