import calendarMonth from './assets/calendar-month.png'
import cooking from './assets/cooking.png'
import shoppingBag from './assets/shopping-bag.png'

export const title = 'All your dinner problems sorted'

export const conveniences = [
  {
    key: 'info-text-0',
    text: 'Plan your weekly meals in a few clicks',
    icon: calendarMonth,
  },
  {
    key: 'info-text-1',
    text: 'No mid-week supermarket dashes',
    icon: shoppingBag,
  },
  {
    key: 'info-text-2',
    text: 'Enjoy home-cooked meals after a busy day',
    icon: cooking,
  },
]

export const valueForMoneyTrackingSectionName = 'valueForMoney'
