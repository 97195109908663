import React from 'react'

import PropTypes from 'prop-types'

import css from './WaveLinkHeader.css'

const WaveLinkHeader = ({ headerAttributes, onClick }) => {
  const {
    backgroundColor,
    color,
    headerImage,
    description,
    link: { collectionSlug },
  } = headerAttributes

  return (
    <div className={css.waveLinkHeaderContanier}>
      <div
        className={css.waveLinkHeader}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
        data-testing="waveLinkHeader"
        style={{
          backgroundColor: `${backgroundColor}`,
        }}
      >
        <div className={css.arrowRightWrapper}>
          <span className={css.arrowRight} style={{ color }} />
        </div>
        <div style={{ color: `${color}` }} className={css.waveLinkInfo}>
          {headerImage[0] && (
            <img
              className={css.waveLinkTitleImage}
              src={headerImage[0].url}
              alt={collectionSlug}
              style={{
                width: 200,
                height: 'auto',
                zIndex: 1,
              }}
            />
          )}
          <p className={css.waveLinkDescription}>{description}</p>
        </div>
      </div>
    </div>
  )
}

WaveLinkHeader.propTypes = {
  headerAttributes: PropTypes.shape({
    color: PropTypes.string,
    description: PropTypes.string,
    backgroundColor: PropTypes.string,
    headerImage: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
      }),
    ),
    link: PropTypes.shape({
      collectionId: PropTypes.string,
      collectionSlug: PropTypes.string,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

export { WaveLinkHeader }
