import QualityImg from 'routes/Home/WhyChooseGousto/assets/quality.jpg'
import SimplicityImg from 'routes/Home/WhyChooseGousto/assets/simplicity.jpg'
import VarietyImg from 'routes/Home/WhyChooseGousto/assets/variety.jpg'

export const STEPS_LIST = [
  {
    title: 'Variety',
    img: VarietyImg,
    list: [
      '75+ recipes to choose from every week',
      'Family classics, global cuisines, and healthy choices',
      'Plant-based, gluten-free and dairy-free options',
    ],
  },
  {
    title: 'Quality',
    img: QualityImg,
    list: [
      'Fresh ingredients from trusted suppliers',
      'High quality fresh meat that’s British wherever possible',
      'All recipes tried, tested and loved by our chefs and customers',
    ],
  },
  {
    title: 'Simplicity',
    img: SimplicityImg,
    list: [
      'Easy-to-use recipe cards, cooked in as little as 10 mins',
      'Pre-measured ingredients for zero food waste',
      'Delivered to your door, 7 days a week',
    ],
  },
]
export const SECTION_TITLE = 'So why Gousto?'
