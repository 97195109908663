import { actionTypes } from 'actions/actionTypes'
import tempActions from 'actions/temp'
import { trackSubscriptionCreated } from 'actions/tracking'
import { trackPurchase } from 'routes/Checkout/checkoutActions'
import { isApplePayPayment } from 'routes/Checkout/checkoutPaymentSelectors'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { getBasketRecipes, getPreviewOrderId } from 'selectors/basket'

export const checkoutPostSignup: CheckoutActionCreator =
  ({ pricing }) =>
  async (dispatch, getState) => {
    dispatch(trackSubscriptionCreated({ pricing }))
    const state = getState()

    const orderId = getPreviewOrderId(state)
    const basketRecipes = getBasketRecipes(state)
    dispatch({ type: actionTypes.CHECKOUT_SIGNUP_SUCCESS, orderId, basketRecipes, pricing }) // used for data layer tracking
    dispatch(tempActions.temp('originalGrossTotal', pricing.grossTotal))
    dispatch(tempActions.temp('originalNetTotal', pricing.netTotal))
    dispatch(trackPurchase({ orderId, pricing }))

    // this triggers the reCAPTCHA execution in PayWithApplePay component or CheckoutPayment component
    // on success user will log in automatically
    if (isApplePayPayment(getState())) {
      dispatch({
        type: actionTypes.APPLE_PAY_START_RECAPTCHA_EXECUTION,
        executionStarted: true,
      })
    } else {
      dispatch({
        // this is not always recaptcha related. This action type also determines
        // when to login after checkout is completed even when recapcha is off
        type: actionTypes.CHECKOUT_SIGNUP_START_RECAPTCHA_EXECUTION,
        executionStarted: true,
      })
    }
  }
