import React from 'react'

import classNames from 'classnames'

import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { useGetHomePageExperiments } from 'routes/Home/homeHooks'
import { useAuth } from 'routes/Menu/domains/auth'

import { Benefits, BenefitType } from '../../Benefits'
import { Cta, CtaTexts, useGetCtaProps } from '../../Cta'
import { GetStartedProps } from './GetStartedModels'

import css from './GetStartedStyles.css'

export const GetStarted = ({
  isHeroCTA,
  isSticky,
  maxHeight,
  ctaRef,
  showBenefits = true,
}: GetStartedProps) => {
  const { isAuthenticated } = useAuth()
  const { ctaText, ctaUri } = useGetCtaProps()
  const { isImmersiveHeroEnabled } = useGetHomePageExperiments()
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()

  const ctaTextDynamic =
    isSticky && !isHeroCTA && !isAuthenticated && isSkipTheWizardEnabled
      ? CtaTexts.BUILD_BOX
      : ctaText

  const className = isHeroCTA
    ? ''
    : classNames(css.mobileStickyInitial, css.mobileStickyRemoval, {
        [css.mobileSticky]: isSticky,
        [css.hideShadow]: maxHeight && isSticky,
      })

  const pickFilter = isImmersiveHeroEnabled
    ? [BenefitType.NO_LOCK_IN_IMMERSIVE]
    : [BenefitType.NO_LOCK_IN]

  return (
    <div
      className={className}
      ref={ctaRef}
      style={maxHeight && !isHeroCTA ? { top: `${maxHeight}px` } : {}}
    >
      <Cta
        ctaUri={ctaUri}
        trackingSectionName={isHeroCTA ? 'hero' : 'stickyCTA'}
        dataTesting={isHeroCTA ? 'homepageHeroCTA' : 'stickyCTA'}
      >
        {ctaTextDynamic}
      </Cta>
      {showBenefits && <Benefits pickFilter={pickFilter} />}
    </div>
  )
}
