import React from 'react'
import { getIsAuthenticated } from 'selectors/auth'
import { getIsInWizardFunnel } from 'selectors/signup'
import { Header } from 'Header'
import { useSelector } from 'react-redux'
import { useDoubleDeckerNav } from 'hooks/useDoubleDeckerNav'
import { getIsProspectFirstVisitMenu } from 'routes/Menu/selectors/menu'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'

export const MenuHeader = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isInWizardFunnel = useSelector(getIsInWizardFunnel)
  const isProspectFirstVisitMenu = useSelector(getIsProspectFirstVisitMenu)
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const isSimpleHeader = (isInWizardFunnel && !isAuthenticated) || (isProspectFirstVisitMenu || isSkipTheWizardEnabled)
  const doubleDeckerExperimentEnabled = useDoubleDeckerNav()

  return <Header simple={isSimpleHeader} doubleDeckerExperimentEnabled={doubleDeckerExperimentEnabled} />
}
