import { CSSObject } from '@emotion/styled'
import { Box, Color, withCitrusTheme } from '@gousto-internal/citrus-react'

import bgImageLargeJpg from './assets/background-large.jpg'
import bgImageSmallJpg from './assets/background-small.jpg'

export const ValueForMoneyContainer = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    [
      `65% bottom/639px no-repeat url(${bgImageSmallJpg})`,
      `bottom/contain no-repeat url(${bgImageSmallJpg})`,
      `30% 20%/cover no-repeat url(${bgImageLargeJpg})`,
    ],
    ['background'],
  ) as CSSObject),
}))

export const ConveniencesContainer = withCitrusTheme(Box, (theme) => ({
  borderRadius: '1rem',
  ...(theme.responsiveStyle(
    [theme.color(Color.Transparent), '#ecedefb3'],
    ['backgroundColor'],
  ) as CSSObject),
}))
