import { createSelector } from 'reselect'

/**
 * Legacy code for retrieving non-rollouts Optimizely variants
 */

export const getFeatures = ({ features }) => features

export const getNextDayDeliveryPaintedDoorFeatureEnabled = ({ features }) => (
  features
    ? features.getIn(['nextDayDeliveryPaintedDoor', 'value'])
    : false
)
export const getLogoutUserDisabledSlots = ({ features }) =>
  (features ? features.getIn(['logoutUserDisabledSlots', 'value']) : '')

export const getGoToMyGousto = ({ features }) =>
  (features ? features.getIn(['goToMyGousto', 'value']) : false)

export const getGoToMyDeliveries = ({ features }) =>
  (features ? features.getIn(['goToMyDeliveries', 'value']) : false)

export const isShowNoDiscountCTAFeatureEnabled = ({ features }) =>
  features && features.getIn(['showNoDiscountCTA', 'value'], false)

export const getFullScreenBoxSummary = ({ features }) =>
  features && features.getIn(['fullScreenBoxSummary', 'value'], false)

export const getIsCommunicationPanelEnabled = ({ features }) =>
  features && features.getIn(['isCommunicationPanelEnabled', 'value'], false)

export const getIsMobileMenuModalAppAwarenessEnabled = ({ features }) =>
  features && features.getIn(['isMobileMenuModalAppAwarenessEnabled', 'value'], false)

export const getIsCustomNoticeEnabled = ({ features }) =>
  features && features.getIn(['isCustomNoticeEnabled', 'value'], false)

export const getIsMyGoustoBannerAppAwarenessEnabled = ({ features }) =>
  features && features.getIn(['isMyGoustoBannerAppAwarenessEnabled', 'value'], false)

export const getIsSubscriberPricingEnabled = ({ features }) =>
  features && features.getIn(['isSubscriberPricingEnabled', 'value'], false)

export const getIsMyGoustoBannerSubscriberPricingEnabled = ({ features }) =>
  features && features.getIn(['isMyGoustoBannerSubscriberPricingEnabled', 'value'], false)

export const getIsGoustoOnDemandEnabled = ({ features }) =>
  features && features.getIn(['isGoustoOnDemandEnabled', 'value'], false)

export const getIsBackClosesModalEnabled = createSelector(getFeatures, (features) =>
  features.getIn(['isBackClosesModalEnabled', 'value'], false),
)

// Beetroots active experiment: https://gousto.atlassian.net/browse/TG-7369
export const getIsCheckoutRefactoredEnabled = ({ features }) =>
  features && features.getIn(['beetrootsIsCheckoutRefactoredEnabled', 'value'], false)
