import { useDecision } from '@optimizely/react-sdk'
import { useDispatch } from 'react-redux'

import actions from 'actions/auth'
import { useIsDiscountGiftEnabled } from 'hooks/useIsDiscountGiftEnabled'
import { useAuth } from 'routes/Menu/domains/auth'
import { useBasket } from 'routes/Menu/domains/basket'
import menuFetchData from 'routes/Menu/fetchData'

import { GIFTING_EXPERIMENT_PROMO_CODE_DATA, PROMO_CODE_DATA } from './homeConfig'

/**
 * Experiments running on home page that controls order or visibility
 * of homepage sections should be placed here
 */
export const useGetHomePageExperiments = () => {
  /** Immersive Hero experiment uses Optimizely FX
   */
  const [immersiveHeroEnabledDecison] = useDecision('beetroots_ofx_homepage_immersive_hero_test')
  const isImmersiveHeroEnabled = immersiveHeroEnabledDecison.variationKey === 't1'

  /** Homepage Redesign experiment uses Optimizely FX
   */
  const { isAuthenticated } = useAuth()
  const [homepageRedesignEnabledDecision] = useDecision('beetroots_ofx_homepage_redesign')
  const isHomepageRedesignEnabled =
    homepageRedesignEnabledDecision.variationKey === 't1' && !isAuthenticated

  return {
    isImmersiveHeroEnabled,
    isHomepageRedesignEnabled,
  }
}

export const useGetMenuData = () => {
  const dispatch = useDispatch()
  const { addRecipe } = useBasket()
  const dispatchRedirectLoggedInUser = () => dispatch(actions.redirectLoggedInUser)
  const dispatchMenuFetchData = () =>
    dispatch(menuFetchData({ query: {}, params: {} }, false, true, { addRecipe }))

  return {
    dispatchRedirectLoggedInUser,
    dispatchMenuFetchData,
  }
}

export const usePromocodeData = () => {
  const isDiscountGiftingExperimentEnabled = useIsDiscountGiftEnabled()

  const promoBannerData = isDiscountGiftingExperimentEnabled
    ? GIFTING_EXPERIMENT_PROMO_CODE_DATA
    : PROMO_CODE_DATA

  return promoBannerData
}
