import React, { useState, useEffect, useCallback } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  Box,
  Display,
  DisplayPosition,
  AlignItems,
  JustifyContent,
  Space,
  Text,
  Icon,
} from '@gousto-internal/citrus-react'
import { Loader } from 'goustouicomponents'
import { useDispatch, useSelector } from 'react-redux'

import { trackUTMAndPromoCode as trackEvent } from 'actions/tracking'
import { clickCancelPayPal, clickConfirmPayPal, clickContinuePayPal } from 'actions/trackingKeys'
import { firePayPalError, setPayPalNonce } from 'routes/Checkout/checkoutActions'
import { isPayPalReady } from 'routes/Checkout/checkoutPaymentSelectors'
import { getPayPalErrors } from 'routes/Checkout/checkoutSelectors'

/* The goal is to render the PayPal button and all the steps before are needed to achieve this goal
  Step 2 to 6 are done in this file
  1. Load paypalscripts (loaded in Checkout.tsx)
  2. Create Braintree client
  3. Create Braintree data collector
  4. Create PayPal Checkout component
  5. Load the PayPal JS SDK
  6. Render the PayPal button
*/

type CheckoutPayPalDetailsProps = {
  submit: () => void
  hide: boolean
  isPayPalInitialized: boolean
  setIsPayPalInitialized: (value: boolean) => void
  braintreeClientInstance: braintree.Client | null
  setBraintreeClientInstance: (value: braintree.Client | null) => void
}

export const CheckoutPayPalDetails = ({
  submit,
  hide,
  isPayPalInitialized,
  setIsPayPalInitialized,
  braintreeClientInstance,
  setBraintreeClientInstance,
}: CheckoutPayPalDetailsProps) => {
  const [braintreePayPalCheckoutComponent, setBraintreePayPalCheckoutComponent] =
    useState<braintree.PayPalCheckout | null>(null)

  const isPayPalPaymentApproved = useSelector(isPayPalReady)

  const hasErrors = useSelector(getPayPalErrors).size > 0
  const isLoading = !hasErrors && !isPayPalInitialized
  const dispatch = useDispatch()

  const initPayPalClient = useCallback(async () => {
    const createPayPalCheckout = async () => {
      if (!braintreeClientInstance) return
      const payPalCheckoutInstance = await braintree.paypalCheckout.create({
        client: braintreeClientInstance,
      })
      setBraintreePayPalCheckoutComponent(payPalCheckoutInstance)
    }

    try {
      await createPayPalCheckout()
    } catch (error: any) {
      datadogLogs.logger.error('PayPal client initialization failed', error)
      dispatch(firePayPalError(error))
    }
  }, [dispatch, braintreeClientInstance])

  const initPayPal = useCallback(async () => {
    if (!braintreePayPalCheckoutComponent) return
    const loadPayPalSDK = async () => {
      await braintreePayPalCheckoutComponent.loadPayPalSDK({
        vault: true,
        commit: false,
        currency: 'GBP',
        intent: 'tokenize',
      })
    }

    const createPayment = () =>
      braintreePayPalCheckoutComponent.createPayment({
        flow: 'vault' as paypal.FlowType.Vault,
        locale: 'en_GB',
        billingAgreementDescription: 'Gousto recipe box subscription',
      })

    const fetchPayPalNonce = async (approveData: paypal.AuthorizationData) => {
      try {
        const payload = await braintreePayPalCheckoutComponent.tokenizePayment(approveData)
        dispatch(setPayPalNonce(payload.nonce))
        datadogLogs.logger.info(
          'PayPal nonce is fetched successfully, proceed to submitting the form',
        )
        submit()

        return payload
      } catch (error: any) {
        datadogLogs.logger.error('Fetching PayPal nonce has failed', error)
        dispatch(firePayPalError(error))
      }

      return null
    }

    const renderPayPalButton = () => {
      const buttonsConfig = {
        fundingSource: 'paypal',
        createBillingAgreement: () => {
          dispatch(trackEvent(clickContinuePayPal))
          datadogLogs.logger.info('PayPal signup attempt')

          return createPayment()
        },
        onApprove: (data: paypal.AuthorizationData) => {
          dispatch(trackEvent(clickConfirmPayPal))
          const payload = fetchPayPalNonce(data) as Promise<paypal.AuthorizationResponse>
          datadogLogs.logger.info('PayPal onApprove')

          return payload
        },
        onCancel: () => {
          dispatch(trackEvent(clickCancelPayPal))
        },
        onError: (error: any) => {
          datadogLogs.logger.error('Error in rendering PayPal Button', error)
          dispatch(firePayPalError(error))
        },
        style: {
          height: 48,
          label: 'pay' as paypal.ButtonLabelOption.Pay,
          tagline: false,
        },
      }

      return paypal.Buttons(buttonsConfig).render('#paypal-container')
    }

    try {
      await loadPayPalSDK()
      renderPayPalButton()
      setIsPayPalInitialized(true)
    } catch (error: any) {
      datadogLogs.logger.error('PayPal initialization failed', error)
      dispatch(firePayPalError(error))
    }
    // Don't include submit as currently it is reinstantiating on every render causing
    // initPaypal to be called multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, braintreePayPalCheckoutComponent])

  // to initalise paypal client, needed before paypal can be initalised
  useEffect(() => {
    if (braintreeClientInstance) {
      initPayPalClient()
    }
  }, [braintreeClientInstance, initPayPalClient])

  useEffect(() => {
    if (braintreePayPalCheckoutComponent) {
      initPayPal()
    }
  }, [braintreePayPalCheckoutComponent, initPayPal])

  useEffect(
    () => () => {
      if (braintreeClientInstance) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore the type here is incorrect, callback is optional
        braintreeClientInstance.teardown()
        setBraintreeClientInstance(null)
      }
    },

    [braintreeClientInstance, setBraintreeClientInstance],
  )

  useEffect(
    () => () => {
      if (braintreePayPalCheckoutComponent) {
        braintreePayPalCheckoutComponent.teardown()
        setBraintreePayPalCheckoutComponent(null)
      }
    },
    [braintreePayPalCheckoutComponent],
  )

  return (
    <>
      <Box display={hide || isPayPalPaymentApproved ? Display.None : Display.Block} width="100%">
        <Box display={Display.Flex}>
          <Icon name="secure" />
          <Space size={2} direction="horizontal" />
          <Text size={2}>
            You will be prompted by PayPal for payment details to securely set up your subscription.
          </Text>
        </Box>
        <Space size={[3, 5]} direction="vertical" />

        <Box position={DisplayPosition.Relative} height="48px">
          <Box
            id="paypal-container"
            data-testid="paypalContainer"
            height={isPayPalInitialized ? 'auto' : '0'}
            style={isPayPalInitialized ? {} : { visibility: 'hidden', opacity: '0' }}
          />
          {isLoading && (
            <Box
              data-testid="loaderContainer"
              position={DisplayPosition.Absolute}
              top="0px"
              left="0px"
              height="100%"
              width="100%"
              display={Display.Flex}
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
            >
              <Loader color="Bluecheese" />
            </Box>
          )}
        </Box>
      </Box>
      <Space size={[4, 5]} direction="vertical" />
    </>
  )
}
