import React from 'react'

import { Box, DisplayPosition } from '@gousto-internal/citrus-react'

import {
  steamAnimation1,
  steamAnimation2,
  steamAnimation3,
  SteamAnimation,
  AnimationContainer,
} from './SteamFX.styled'
import steamFXImg from './assets/steamFX/SteamFx.png'

export const SteamFX = () => (
  <AnimationContainer>
    <Box position={DisplayPosition.Absolute} height="100%" width="100%" left="-20px">
      <SteamAnimation
        imageSrc={steamFXImg}
        animation={steamAnimation1}
        animationDelay="0s"
        animationDuration="2s"
      />
    </Box>
    <Box position={DisplayPosition.Absolute} height="100%" width="100%" left="0%">
      <SteamAnimation
        imageSrc={steamFXImg}
        animation={steamAnimation2}
        animationDelay="1s"
        animationDuration="2s"
      />
    </Box>
    <Box position={DisplayPosition.Absolute} height="100%" width="100%" left="20px">
      <SteamAnimation
        imageSrc={steamFXImg}
        animation={steamAnimation3}
        animationDelay="1.5s"
        animationDuration="2s"
      />
    </Box>
  </AnimationContainer>
)
