import React, { useCallback, useEffect } from 'react'

import { connect, useDispatch } from 'react-redux'

import { getAvailableBoxSizes } from 'actions/basket'
import { trackDiscountVisibilityBannerAppearance } from 'actions/tracking'
import { useIsMenuPersonalisationEnabled } from 'hooks/useIsMenuPersonalisationEnabled'
import {
  signupNextStep,
  signupStepsReceive,
  signupDismissDiscountAppliedBar,
  signupSetStep,
} from 'routes/Signup/signupActions'

import { useDiscountAppliedData } from '../../components/DiscountAppliedBar/discountAppliedBarHooks'
import { Signup } from './Signup'

const mapStateToProps = (state, ownProps) => ({
  secondarySlug: ownProps.params.secondarySlug,
  stepNames: state.signup.getIn(['wizard', 'stepNames']),
  orderDiscount: state.promoStore.getIn([
    state.promoCurrent,
    'details',
    'discount-whole-order-percent',
  ]),
  promoModalVisible: state.promoModalVisible,
  isDiscountAppliedBarDismissed: state.signup.get('isDiscountAppliedBarDismissed'),
})

const SignupPure = (props) => {
  const dispatch = useDispatch()
  const { isDiscountEnabled, discountTip } = useDiscountAppliedData()
  const isMenuPersonalisationEnabled = useIsMenuPersonalisationEnabled()
  const trackDiscountVisibility = useCallback(
    (wizardStep) => trackDiscountVisibilityBannerAppearance(wizardStep),
    [],
  )

  useEffect(() => {
    const fetchAvailableBoxSizes = async () => {
      await dispatch(getAvailableBoxSizes())
    }
    fetchAvailableBoxSizes()
  }, [dispatch])

  return (
    <Signup
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isDiscountEnabled={isDiscountEnabled}
      isMenuPersonalisationEnabled={isMenuPersonalisationEnabled}
      discountTip={discountTip}
      trackDiscountVisibility={trackDiscountVisibility}
    />
  )
}

const SignupContainer = connect(mapStateToProps, {
  goToStep: signupNextStep,
  signupStepsReceive,
  signupDismissDiscountAppliedBar,
  signupSetStep,
})(SignupPure)

export { SignupContainer }
