import React from 'react'

import {
  Box,
  Text,
  Display,
  FlexDirection,
  AlignItems,
  Color,
  Space,
  FontWeight,
  Image,
} from '@gousto-internal/citrus-react'

import { conveniences } from './data'

export const ConveniencesList = () => {
  const conveniencesList = conveniences.map(({ text, key, icon }) => (
    <Box key={key}>
      <Box
        color={Color.ColdGrey_800}
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        alignItems={AlignItems.Center}
        paddingH={4}
        paddingRight={0}
      >
        <Image src={icon} alt="" width="32px" minWidth="32px" />
        <Space size={4} direction="horizontal" />
        <Text size={4} fontWeight={FontWeight.Bold}>
          {text}
        </Text>
      </Box>
      <Space size={[6, 4]} direction="vertical" />
    </Box>
  ))

  return <>{conveniencesList}</>
}
