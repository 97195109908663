import { CSSObject } from '@emotion/react'
import { Box, withCitrusTheme } from '@gousto-internal/citrus-react'

export const SliderWrapper = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    [
      `linear-gradient(to bottom, black 60%, ${theme.tokens.colors.NeutralGrey_50} 40%)`,
      `linear-gradient(to bottom, black 80%, ${theme.tokens.colors.NeutralGrey_50} 20%)`,
    ],
    ['background'],
  ) as CSSObject),
}))
