import { hasProp, isNonNullObject } from '../util'
import { BadJSON, ErrorJSON, ErrorResponse, MalformedType } from './errors'
import { jsonParse } from './json'
import { parseObjectKeysToCamelCase } from './objectKeys'

/**
 * Parser middleware for 'Gousto basic' API response format
 * ============================================================================
 *
 * This is the format found in packages like
 * https://github.com/Gousto/lambda-response-node-package
 */

/**
 * Types
 * ============================================================================
 */
type GoustoBasicResponseData<T> = {
  status: 'ok'
  data: T
}

type GoustoBasicResponseError = {
  status: 'error'
  errors?: string[]
}

/**
 * Parsers
 * ============================================================================
 */
export async function parseGoustoBasicResponse(
  resp: Response,
): Promise<GoustoBasicResponseData<unknown>> {
  const jsonParseResult = await jsonParse(resp)

  // If we receive an error status, begin decoding as an error
  if (!resp.ok) {
    if (jsonParseResult.type === 'success') {
      throw new ErrorJSON(resp, jsonParseResult.value)
    } else {
      // Didn't decode any error JSON
      throw new ErrorResponse(resp)
    }
  }

  // If we receive 2XX but we failed to read JSON, that's invalid
  if (jsonParseResult.type === 'failure') {
    throw new BadJSON(jsonParseResult.error)
  }

  const { value } = jsonParseResult

  // If response had 2XX, it could still encode an error
  if (isGoustoBasicResponseError(value)) {
    throw new ErrorJSON(resp, value)
  }

  if (!isGoustoBasicResponseData(value)) {
    throw new MalformedType(
      `tried to decode a GoustoBasicResponseData but got json=${JSON.stringify(value)}`,
    )
  }

  return parseObjectKeysToCamelCase(value)
}

function isGoustoBasicResponseError(value: unknown): value is GoustoBasicResponseError {
  return isNonNullObject(value) && hasProp(value, 'status') && value.status === 'error'
}

function isGoustoBasicResponseData(value: unknown): value is GoustoBasicResponseData<unknown> {
  return (
    isNonNullObject(value) &&
    hasProp(value, 'status') &&
    (value.status === 'ok' || value.status === 'OK') &&
    hasProp(value, 'data') &&
    isNonNullObject(value.data)
  )
}
