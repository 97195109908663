import moment from 'moment'
import { createSelector } from 'reselect'

import { actionTypes } from 'actions/actionTypes'
import { CheckoutStore } from 'routes/Checkout/models/CheckoutStore'
import { State } from 'routes/Menu/fetchData/types'
import Cookies from 'utils/GoustoCookies'
// eslint-disable-next-line import/no-cycle
import { get } from 'utils/cookieHelper2'
import { getSlotTimes } from 'utils/deliveries'
import { parsePhoneNumber } from 'utils/phoneNumber/phoneNumber'

export const getCheckoutUrgency = ({ checkoutUrgency }: CheckoutStore) => checkoutUrgency

export const getCheckoutStartedTime = createSelector(getCheckoutUrgency, (checkoutUrgency) =>
  checkoutUrgency.get('checkoutStartedTime'),
)

export const getRecaptchaExecutionStarted = (state: CheckoutStore) =>
  state.checkout.get('recaptchaExecutionStarted')

export const getApplePayRecaptchaExecutionStarted = (state: CheckoutStore) =>
  state.checkout.get('applePayRecaptchaExecutionStarted')

export const hasCheckoutError = (state: CheckoutStore) => {
  const errors = state.checkout.get('errors')
  if (!errors) {
    return false
  }

  return errors.filter((error) => !!error).size > 0
}

export const deliveryFormName = 'delivery'

export const getFormattedPhoneNumber = (phone: string) => {
  const parsedPhone = parsePhoneNumber(phone, 'GB')

  return parsedPhone ? parsedPhone.formatNational().replace(/\s/g, '') : ''
}

export const getEmail = (state: CheckoutStore) => state.checkout.get('email')

export const getPasswordValue = (state: CheckoutStore) =>
  state.checkout.getIn(['passwordInfo', 'value'])

export const getPasswordErrors = (state: CheckoutStore) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore due do ImmutableMap not accounting for fact that "array extends object"
  state.checkout.getIn(['passwordInfo', 'errorCodes']) as string[]

export const getIsEmailMarketingEnabled = (state: CheckoutStore) =>
  state.checkout.get('isEmailMarketingEnabled')

export const getName = (state: CheckoutStore) => ({
  firstName: state.checkout.get('firstName'),
  lastName: state.checkout.get('lastName'),
})

export const getDeliveryAddress = (state: CheckoutStore) => state.checkout.get('deliveryAddress')

export const getPhone = (state: CheckoutStore) => state.checkout.get('phone')

export const getDeliveryInstruction = (state: CheckoutStore) => ({
  deliveryInstruction: state.checkout.get('deliveryInstruction'),
  deliveryInstructionCustom: state.checkout.get('deliveryInstructionCustom'),
})

export const getFeLoggingCorrelationData = (state: CheckoutStore) => ({
  session_id: get(Cookies, 'gousto_session_id', false, false),
  gousto_ref: state.checkout.get('goustoRef'),
})

/**
 * Returns true if Apple Pay is enabled in browser and supported by Apple Pay API
 */
export const getIsApplePayEnabled = (state: CheckoutStore) =>
  state.checkout.get('isApplePayEnabled')

export const getIsMobile = (state: CheckoutStore) => state.request.get('browser') === 'mobile'

export const getDeliveryTimeAndDate = (state: CheckoutStore) => {
  const deliveryDays = state.boxSummaryDeliveryDays
  const date = state.basket.get('date')
  const slotId = state.basket.get('slotId')

  const deliveryDate = moment(date).format('dddd Do MMMM')
  const deliveryTime = getSlotTimes({ date, deliveryDays, slotId })

  return {
    deliveryDate,
    deliveryTime,
  }
}

export const getApplePayErrors = (state: CheckoutStore) => state.checkout.get('applePayErrors')

export const getPayPalErrors = (state: CheckoutStore) => state.checkout.get('paypalErrors')

export const getGeneralErrors = (state: CheckoutStore) => state.checkout.get('errors')

export const getApplePayDeliveryInstruction = (state: CheckoutStore) =>
  state.checkout.getIn(['applePay', 'deliveryInstruction'])

export const getApplePayDeliveryInstructionCustom = (state: CheckoutStore) =>
  state.checkout.getIn(['applePay', 'deliveryInstructionCustom'])

export const getApplePayDeliveryAddress = (state: CheckoutStore) =>
  state.checkout.getIn(['applePay', 'deliveryAddress']).toJS()

export const getApplePayPersonalInfo = (state: CheckoutStore) =>
  state.checkout.getIn(['applePay', 'personalInfo']).toJS()

export const getGoustoRef = (state: CheckoutStore) => state.checkout.get('goustoRef')

export const getIsDiscountAppliedBannerDismissed = (state: State) =>
  state.signup.get('isDiscountAppliedBarDismissed')

export const getPreviewOrderError = (state: State) =>
  state.error.get(actionTypes.BASKET_PREVIEW_ORDER_CHANGE, false)

export const getPreviewOrderId = (state: State) => state.basket.get('previewOrderId')

export const getMenuCutoffUntil = (state: State) => state.menu.get('menuCutoffUntil')

export const getBasketOrderPreviewChangePending = (state: State) => {
  const previewOrderChangePending = state.pending?.get(
    actionTypes.BASKET_PREVIEW_ORDER_CHANGE,
    false,
  )

  return previewOrderChangePending
}
