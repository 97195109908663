import React from 'react'

import { keyframes, Keyframes } from '@emotion/react'
import { Box, Image, withCitrusTheme } from '@gousto-internal/citrus-react'

export const steamAnimation1 = keyframes`
0% {
   filter: blur(0px);
  transform: translateY(0px) scale(-1, 1);
  opacity: 0;
}

25% {
  filter: blur(3px);
  transform: translateY(-10px) scale(-1, 1.05);
  opacity: 0.5;
}

50% {
  filter: blur(5px);
  transform: translateY(-20px) scale(-1, 1.1);
  opacity: 1;
}

75% {
  filter: blur(5px);
  transform: translateY(-30px) scale(-1, 1.15);
  opacity: 0.5;
}

100% {
  filter: blur(7px);
  transform: translateY(-40px) scale(-1, 1.2);
  opacity: 0;
}
`
export const steamAnimation2 = keyframes`
0% {
  filter: blur(0px);
  transform: translateY(0px) scale(1);
  opacity: 0;
}

25% {
  filter: blur(3px);
  transform: translateY(-10px) scale(1.05);
  opacity: 0.5;
}

50% {
  filter: blur(5px);
  transform: translateY(-20px) scale(1.1);
  opacity: 1;
}

75% {
  filter: blur(5px);
  transform: translateY(-30px) scale(1.15);
  opacity: 0.5;
}

100% {
  filter: blur(7px);
  transform: translateY(-40px) scale(1.2);
  opacity: 0;
}
`
export const steamAnimation3 = keyframes`
0% {
  filter: blur(0px);
  transform: translateY(0px) scale(1);
  opacity: 0;
}

25% {
  filter: blur(3px);
  transform: translateY(-20px) scale(1.05);
  opacity: 0.5;
}

50% {
  filter: blur(5px);
  transform: translateY(-40px) scale(1.1);
  opacity: 1;
}

75% {
  filter: blur(5px);
  transform: translateY(-60px) scale(1.15);
  opacity: 0.5;
}

100% {
  filter: blur(7px);
  transform: translateY(-80px) scale(1.2);
  opacity: 0;
}
`
const fadeInAnimation = keyframes`
0% {
  opacity: 0;
}
80% {
opacity: 0.3;
}

100% {
  opacity: 1;
}
`

type SmokeImageProps = {
  imageSrc: string
  animation: Keyframes
  animationDelay: string
  animationDuration: string
}

export const SteamAnimation = ({
  imageSrc,
  animation,
  animationDelay,
  animationDuration,
}: SmokeImageProps) => {
  const Component = withCitrusTheme(Image, () => ({
    animation: `${animation} ${animationDuration} linear infinite`,
    animationDelay,
  }))

  return <Component src={imageSrc} alt="" />
}

export const AnimationContainer = withCitrusTheme(Box, () => ({
  animation: `${fadeInAnimation} 3s linear 1`,
}))
