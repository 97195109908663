import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Alert, Button } from 'goustouicomponents'
import Content from 'containers/Content'
import css from './OrderRestoreButton.css'

const OrderRestoreButton = (props) => {
  const {
    pending,
    error,
    orderId,
    userId,
    deliveryDayId,
    deliveryDay,
    projectedOrderRestore,
    orderState,
    restoreCancelledOrder,
    isProjected
  } = props

  const [selectedOrder, setSelectedOrder] = useState(null)

  const handleRestoreBox = () => {
    if (orderState === 'cancelled' && !isProjected) {
      restoreCancelledOrder(orderId)
    } else {
      projectedOrderRestore(orderId, userId, deliveryDayId, deliveryDay)
    }

    setSelectedOrder(orderId)
  }

  const isCurrentOrder = (selectedOrder === orderId)
  const isLoading = (pending && isCurrentOrder)

  return (
    <div>
      {error ? (
        <Alert type="danger">
          <Content contentKeys="mydeliveriesOrderOrderrestorebuttonRestoreprojectederror">
            <span>Whoops, there was a problem restoring this order, please try again.</span>
          </Content>
        </Alert>
      ) : null}
      <div className={css.button}>
        <Button onClick={() => handleRestoreBox()} pending={isLoading} data-testing="restoreButton">
          Restore delivery
        </Button>
      </div>
    </div>
  )
}

OrderRestoreButton.propTypes = {
  userId: PropTypes.string,
  orderId: PropTypes.string,
  deliveryDayId: PropTypes.string,
  deliveryDay: PropTypes.string,
  projectedOrderRestore: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  restoreCancelledOrder: PropTypes.func,
  error: PropTypes.string,
  pending: PropTypes.bool,
  orderState: PropTypes.string.isRequired,
  isProjected: PropTypes.bool
}

OrderRestoreButton.defaultProps = {
  userId: '',
  orderId: '',
  deliveryDayId: '',
  deliveryDay: '',
  error: null,
  pending: false,
  isProjected: false,
  projectedOrderRestore: () => { /* noop */ },
  restoreCancelledOrder: () => { /* noop */ }
}

export default OrderRestoreButton
