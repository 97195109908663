import { useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import actions from 'actions'
import { useDispatch, useSelector } from 'react-redux'

import { boxSummaryDeliveryDaysLoad } from 'actions/boxSummary'
import routesConfig from 'config/routes'
import {
  checkoutCreatePreviewOrder,
  fetchPayPalClientToken,
  firePayPalError,
} from 'routes/Checkout/checkoutActions'
import { useBasket } from 'routes/Menu/domains/basket'
import { loadMenuServiceDataIfDeepLinked } from 'routes/Menu/fetchData/menuService'
import logger from 'utils/logger'
import { getPreviewOrderErrorName } from 'utils/order'

import {
  getDeliveryTimeAndDate,
  getPreviewOrderError,
  getPreviewOrderId,
  getBasketOrderPreviewChangePending,
} from './checkoutSelectors'
import { loadCheckoutScript as fetchCheckoutScript } from './loadCheckoutScript'
import { loadPayPalScripts as fetchPayPalScripts } from './loadPayPalScripts'

export const useGeneratePreviewOrder = () => {
  const dispatch = useDispatch()
  const { addRecipe } = useBasket()
  const { deliveryTime } = useSelector(getDeliveryTimeAndDate)
  const previewOrderError = useSelector(getPreviewOrderError)
  const previewOrderId = useSelector(getPreviewOrderId)
  useEffect(() => {
    const fetchData = async () => {
      // defensive code to ensure menu load days works below for deeplinks
      await dispatch(loadMenuServiceDataIfDeepLinked(false, addRecipe))
      if (!deliveryTime) {
        await dispatch(boxSummaryDeliveryDaysLoad())
      }
      if (!previewOrderId && !previewOrderError) {
        try {
          await dispatch(checkoutCreatePreviewOrder())
        } catch (e) {
          // error is handled in useRedirectOnError
        }
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { previewOrderError, previewOrderId }
}

export const useRedirectOnError = () => {
  /**
   * This hook is used to redirect to the menu page if there is an error in the checkout flow.
   * Error could be an order item being out of stock, an expired basket, or an undefinded error.
   */
  const dispatch = useDispatch()
  const { previewOrderError } = useGeneratePreviewOrder()
  const pending = useSelector(getBasketOrderPreviewChangePending)
  const errorName = getPreviewOrderErrorName(previewOrderError)

  useEffect(() => {
    if (!pending && previewOrderError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(
        `checkoutHooks/useGeneratePreviewOrder failed to create preview order ID successfully, with error: ${previewOrderError}`,
      )
      dispatch(
        actions.redirect(`${routesConfig.client.menu}?from=newcheckout&error=${errorName}`, true),
      )
    }
  }, [previewOrderError, dispatch, errorName, pending])
}

export const useCheckoutScript = () => {
  const [isCheckoutScriptReady, setIsCheckoutScriptReady] = useState<boolean>(false)
  const loadCheckoutScript = () => {
    setIsCheckoutScriptReady(false)

    fetchCheckoutScript()
      .then(() => {
        datadogLogs.logger.info('Checkout scripts have been loaded in checkoutHooks')
        setIsCheckoutScriptReady(true)
      })
      .catch((error) => {
        datadogLogs.logger.error('checkout scripts have not been loaded in checkoutHooks', error)
      })
  }

  return { isCheckoutScriptReady, loadCheckoutScript }
}

export const usePaypalScript = () => {
  const dispatch = useDispatch()
  const [arePaypalScriptsReady, setArePaypalScriptsReady] = useState<boolean>(false)

  const loadPayPalScripts = () => {
    if (arePaypalScriptsReady) {
      return
    }

    setArePaypalScriptsReady(false)
    fetchPayPalScripts()
      .then(() => {
        datadogLogs.logger.info('PayPal scripts have been loaded in checkoutHooks')
        setArePaypalScriptsReady(true)
      })
      .catch((error) => {
        datadogLogs.logger.error('PayPal scripts have not been loaded in checkoutHooks', error)
        dispatch(firePayPalError(error))
      })

    dispatch(fetchPayPalClientToken())
  }

  return { arePaypalScriptsReady, loadPayPalScripts }
}
