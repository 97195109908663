import { CSSObject } from '@emotion/react'
import { colors, zIndex } from '../../styles'

/* Desktop dropdown section */

export const cssOuterWrapper: CSSObject = {
  display: 'flex',
  justifyContent: 'flex-end',
}

export const cssButton: CSSObject = {
  zIndex: 11,
  color: colors.bluecheese,
  fontWeight: '700',
  ['&:hover']: {
    color: colors.s600,
    cursor: 'pointer',
  },
}

export const cssBlueyButton: CSSObject = {
  color: colors.darkBluey,
  ['&:hover']: {
    color: colors.bluecheese,
    cursor: 'pointer',
  },
}

export const cssFineDineInButton: CSSObject = {
  color: colors.s200,
  ['&:hover']: {
    color: colors.bluecheese,
    cursor: 'pointer',
  },
}

export const cssDropWrapperV2: CSSObject = {
  position: 'absolute',
  background: colors.white,
  zIndex: zIndex.top,
  display: 'none',
  top: '2rem',
  boxShadow: '0 18px 14px 0 rgba(51, 61, 71, 0.2)',
  padding: '1rem',
  // list elements has 0.5rem margin-bottom
  // that's why we need 0.5rem padding for bottom
  paddingBottom: '0.5rem',
  minWidth: '100%',
  width: 'calc(100% + 2rem)',
  margin: '0 -1rem',
}

export const cssIsExpanded: CSSObject = {
  display: 'block',
}
