import React from 'react'

import styled from '@emotion/styled'
import {
  Color,
  BorderStyle,
  Box,
  ResponsiveValue,
  FontFamily,
  Space,
  Text,
  TextAlign,
  Display,
  AlignItems,
  FontWeight,
} from '@gousto-internal/citrus-react'

import { BoxComparisonTableHeaderProps, BoxComparisonTableProps } from './boxPriceBlockModels'

// workaround to allow BoxComparisonTableHeader to take citrus or non-citrus colours interchangeably.
const SaveAndSavourBox = styled(Box)({
  background: '#F3C435',
})

const colorSchemes = {
  saveAndSavour: {
    boxType: SaveAndSavourBox,
    bgColor: null,
    color: Color.NeutralGrey_900,
    testingId: 'save-and-savour',
    borderColor: Color.ColdGrey_100,
    fontWeight: FontWeight.Normal,
  },
  selectMoreRecipes: {
    boxType: Box,
    bgColor: Color.ColdGrey_700,
    color: Color.White,
    testingId: 'more-recipes',
    borderColor: Color.ColdGrey_100,
    fontWeight: FontWeight.Bold,
  },
  classicGousto: {
    boxType: Box,
    bgColor: Color.ColdGrey_100,
    color: Color.ColdGrey_700,
    testingId: 'classic-gousto',
    borderColor: Color.ColdGrey_100,
    fontWeight: FontWeight.Normal,
  },
  bestPrice: {
    boxType: Box,
    bgColor: Color.Success_800,
    color: Color.White,
    testingId: 'best-price',
    borderColor: Color.ColdGrey_200,
    fontWeight: FontWeight.Bold,
  },
}

export const BoxComparisonTableHeader = ({ type, headerValue }: BoxComparisonTableHeaderProps) => {
  const {
    boxType: HeaderBox,
    color,
    bgColor,
    borderColor,
    testingId,
    fontWeight,
  } = colorSchemes[type]

  return (
    <HeaderBox
      bg={bgColor as ResponsiveValue<Color>}
      borderStyle={BorderStyle.Solid}
      borderColor={borderColor}
      borderWidth={0.5}
      color={color}
      paddingV={1}
      data-testid={testingId}
    >
      <Text textAlign={TextAlign.Center} size={1} fontWeight={fontWeight}>
        {headerValue}
      </Text>
    </HeaderBox>
  )
}

export const BoxComparisonTable = ({
  headerType,
  headerValue,
  pricePerPortion,
  pricePerBox,
}: BoxComparisonTableProps) => (
  <>
    <BoxComparisonTableHeader type={headerType} headerValue={headerValue} />

    <Box width="100%" display={Display.InlineFlex}>
      <Box
        width="50%"
        bg={Color.NeutralGrey_50}
        borderStyle={BorderStyle.Solid}
        borderColor={Color.ColdGrey_200}
        borderWidth={0.5}
        borderTopWidth={0}
        alignItems={AlignItems.Center}
      >
        <Space size={2} direction="vertical" />
        <Text size={4} textAlign={TextAlign.Center}>
          £{pricePerBox}
        </Text>

        <Text size={3} fontFamily={FontFamily.Book} textAlign={TextAlign.Center}>
          per order
        </Text>

        <Space size={2} direction="vertical" />
      </Box>
      <Box
        width="50%"
        borderStyle={BorderStyle.Solid}
        borderColor={Color.ColdGrey_200}
        borderRightWidth={0.5}
        borderBottomWidth={0.5}
        alignItems={AlignItems.Center}
      >
        <Space size={2} direction="vertical" />
        <Text size={4} textAlign={TextAlign.Center} color={Color.Success_800}>
          £{pricePerPortion}
        </Text>
        <Text size={3} textAlign={TextAlign.Center} color={Color.Success_800}>
          per portion
        </Text>
        <Space size={2} direction="vertical" />
      </Box>
    </Box>
  </>
)
