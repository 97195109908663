import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useIsRafPersonalisationEnabled() {
  const { isAuthenticated } = useAuth()
  const [isRafPersonalisationEnabled] = useDecision('beetroots_ofx_raf_personalisation')

  return isRafPersonalisationEnabled.variationKey === 't1' && !isAuthenticated
}
