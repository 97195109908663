import React, { useRef, useEffect, useState } from 'react'

import { Display1, Heading2, TextAlign, useTheme, Space } from '@gousto-internal/citrus-react'

import { canUseWindow } from '@library/environment'

import { HighlightChoiceBanner } from 'routes/Home/HighlightChoice'
import { homeConfig } from 'routes/Home/homeConfig'

import { GetStarted } from './GetStarted'
import { HeroImage } from './HeroImage'
import { Container, TextContainer, Title, SubTitle } from './HeroStyled'
import { ProcessImage } from './ProcessImage'
import { onScrollHandler } from './heroUtils'

export const Hero = () => {
  const { theme } = useTheme()

  const breakpointFromExtraSmall = '320px'
  const breakpointFromSmall = '360px'
  const breakpointFromMedium = theme.tokens.breakpoints[0]
  const breakpointFromLarge = theme.tokens.breakpoints[1]

  const BreakpointFromExtraSmall = `@media (min-width: ${breakpointFromExtraSmall})`
  const BreakpointFromSmall = `@media (min-width: ${breakpointFromSmall})`
  const BreakpointFromMedium = `@media (min-width: ${breakpointFromMedium})`
  const BreakpointFromLarge = `@media (min-width: ${breakpointFromLarge})`

  const [isSticky, setIsSticky] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)

  const heroRef = useRef<HTMLDivElement>(null)
  const stickyCTARef = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    onScrollHandler({ heroRef, stickyCTARef, setIsSticky, setMaxHeight })
  }

  useEffect(() => {
    if (canUseWindow()) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (canUseWindow()) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    <Container
      {...{
        BreakpointFromMedium,
        BreakpointFromLarge,
      }}
      ref={heroRef}
      data-testid="HeroContainer"
    >
      <TextContainer {...{ BreakpointFromMedium, BreakpointFromLarge }}>
        <Title
          {...{
            BreakpointFromExtraSmall,
            BreakpointFromSmall,
            BreakpointFromMedium,
            BreakpointFromLarge,
          }}
        >
          <Display1 textAlign={[TextAlign.Center, TextAlign.Left]}>
            {homeConfig.hero.header}
          </Display1>
        </Title>
        <SubTitle {...{ BreakpointFromExtraSmall, BreakpointFromMedium, BreakpointFromLarge }}>
          <Heading2 textAlign={[TextAlign.Center, TextAlign.Left]}>
            {homeConfig.hero.subheader}
          </Heading2>
        </SubTitle>

        <HighlightChoiceBanner data-testid="highlightChoiceBanner" />

        <GetStarted ctaRef={stickyCTARef} isHeroCTA isSticky={isSticky} maxHeight={maxHeight} />

        <Space size={[2, null, 0]} />

        <ProcessImage />
      </TextContainer>
      <HeroImage />
      <GetStarted
        ctaRef={stickyCTARef}
        isHeroCTA={false}
        isSticky={isSticky}
        maxHeight={maxHeight}
      />
    </Container>
  )
}
