import React, { useEffect } from 'react'

import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { useDoubleDeckerNav } from 'hooks/useDoubleDeckerNav'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { useBasket } from 'routes/Menu/domains/basket'
import { getIsProspectFirstVisitMenu } from 'routes/Menu/selectors/menu'

import { useCollections } from '../../domains/collections'
import { CollectionsNav } from './CollectionsNav'
import { useTracking } from './useTracking'

import css from './CollectionsNav.css'

type CollectionsNavWrapperProps = {
  user: {
    nameFirst: string
  }
}

const CollectionsNavWrapper = (ownProps: CollectionsNavWrapperProps) => {
  const isProspectFirstVisitMenu = useSelector(getIsProspectFirstVisitMenu)
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const { currentCollectionId, collections, changeCollectionById } = useCollections()
  const { menuId } = useBasket()
  const doubleDeckerExperimentEnabled = useDoubleDeckerNav()

  const track = useTracking({ collections, menuId })
  useEffect(() => track(), [track])

  return (
    <div
      className={classnames({
        [css.doubleDeckerCollectionsNavWrapper]: doubleDeckerExperimentEnabled,
      })}
    >
      <CollectionsNav
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...ownProps}
        isProspectFirstVisitMenu={isProspectFirstVisitMenu}
        isSkipTheWizardEnabled={isSkipTheWizardEnabled}
        changeCollectionById={changeCollectionById}
        menuCollections={collections}
        menuCurrentCollectionId={currentCollectionId}
        doubleDeckerExperimentEnabled={doubleDeckerExperimentEnabled}
      />
    </div>
  )
}

export { CollectionsNavWrapper }
