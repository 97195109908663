import React from 'react'
import { browserHistory } from 'react-router'

import {
  AlignItems,
  Body1,
  Box,
  Button,
  ButtonColorVariant,
  Color,
  Display,
  Display3,
  FlexDirection,
  Join,
  JustifyContent,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'

import { ENDLESS_VARIETY } from '../../config'
import type { Recipe } from '../../types/endlessVarietyCarousel'
import { MenuCollections } from './MenuCollections/MenuCollections'
import { RecipeCarousel } from './RecipeCarousel'

import { HomepageSections, TrackingActions, trackCTAClick } from '../../tracking'

export type EndlessVarietySectionProps = {
  signupCTA: string
  onCTAClick: () => void
  recipes: Record<string, Recipe>
}

export const EndlessVarietySection = ({
  signupCTA,
  onCTAClick,
  recipes,
}: EndlessVarietySectionProps) => {
  const signupCTAClick = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, HomepageSections.ENDLESS_VARIETY)
  }
  const openMenuCTAClick = () => {
    browserHistory.push('/menu')
    trackCTAClick(TrackingActions.CLICK_OPEN_MENU, HomepageSections.ENDLESS_VARIETY)
  }

  return (
    <section data-testid="endlessVariety">
      <Box bg={Color.NeutralGrey_50} paddingV={[14, 16]}>
        <Box
          paddingH={4}
          paddingV={4}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          alignItems={[null, AlignItems.Center]}
        >
          <Join with={<Space size={4} />}>
            <Display3 color={Color.Primary_600}>{ENDLESS_VARIETY.heading}</Display3>
            <Body1>{ENDLESS_VARIETY.firstParagraph}</Body1>
            <MenuCollections />
          </Join>
        </Box>
        <RecipeCarousel recipes={recipes} />
        <Space size={6} />
        <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
          <Visible display={[Display.None, Display.Block]}>
            <Box minWidth="309px">
              <Button
                colorVariant={ButtonColorVariant.Primary}
                height="48px"
                onClick={signupCTAClick}
                width="100%"
              >
                {signupCTA}
              </Button>
            </Box>
          </Visible>
        </Box>
      </Box>
    </section>
  )
}
