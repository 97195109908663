import { Box, Color } from '@gousto-internal/citrus-react'
import React, { RefObject } from 'react'
import { Hero, LovedBy, PeekInTheBox, TrustPilot } from '../components'
import { ValueForMoney } from '../components/ValueForMoney/ValueForMoney'
import { useFetchRafCampaignDetails } from '../hooks/useFetchRafCampaignDetails'

export type RafLandingPageType = {
  useIsSkipTheWizardEnabled: () => boolean
  useIsRafPersonalisationEnabled: () => boolean
  onScrollHandler: ({
    heroRef,
    stickyCTARef,
    setIsSticky,
    setMaxHeight,
  }: {
    heroRef: RefObject<HTMLDivElement>
    stickyCTARef: RefObject<HTMLDivElement>
    setIsSticky: (isSticky: boolean) => void
    setMaxHeight: (isSticky: number) => void
  }) => void
}

export type SkipTheWizardExperimentType = {
  isSkipTheWizardEnabled: boolean
}

export const RafLandingPage = ({
  onScrollHandler,
  useIsSkipTheWizardEnabled,
  useIsRafPersonalisationEnabled,
}: RafLandingPageType) => {
  const isRafPersonalisationEnabled = useIsRafPersonalisationEnabled()

  const { firstBoxDiscount, secondBoxDiscount, giftYourFriendCredit, referrerFirstName } =
    useFetchRafCampaignDetails()

  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()

  return (
    <Box paddingBottom={[10, 6]} bg={Color.White}>
      <Hero
        firstBoxDiscount={firstBoxDiscount}
        secondBoxDiscount={secondBoxDiscount}
        giftYourFriendCredit={giftYourFriendCredit}
        onScrollHandler={onScrollHandler}
        isSkipTheWizardEnabled={isSkipTheWizardEnabled}
        referrerFirstName={isRafPersonalisationEnabled ? referrerFirstName : undefined}
      />
      <LovedBy />
      <ValueForMoney isSkipTheWizardEnabled={isSkipTheWizardEnabled} />
      <TrustPilot />
      <PeekInTheBox isSkipTheWizardEnabled={isSkipTheWizardEnabled} />
    </Box>
  )
}
