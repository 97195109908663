import { CSSObject } from '@emotion/styled'
import { Box, withCitrusTheme } from '@gousto-internal/citrus-react'

import saverRecipeLarge from './assets/saver_recipe_large.png'
import saverRecipeSmall from './assets/saver_recipe_small.png'

export const SaverRecipeImage = withCitrusTheme(Box, (theme) => ({
  marginLeft: 'auto',
  ...(theme.responsiveStyle(
    [
      `right/cover no-repeat url(${saverRecipeSmall})`,
      `right/cover no-repeat url(${saverRecipeSmall})`,
      `right/cover no-repeat url(${saverRecipeSmall})`,
      `0% 0%/contain no-repeat url(${saverRecipeLarge})`,
    ],
    ['background'],
  ) as CSSObject),
}))
