import { payment } from 'routes/Checkout/checkoutPaymentReducers'
import { checkoutReducers } from 'routes/Checkout/checkoutReducers'
import { checkoutUrgencyReducers } from 'routes/Checkout/checkoutUrgencyReducers'
import { signupReducers } from 'routes/Signup/signupReducers'

import { accountReducers } from './account'
import { appBanner } from './appBanner'
import auth from './auth'
import basket from './basket'
import { boxSummaryReducers } from './boxSummary'
import content from './content'
import { deliveryDetailsReducer } from './deliveryDetails'
import { featuresReducers } from './features'
import { feedback } from './feedback'
import { filtersReducers } from './filters'
import { getHelp } from './getHelp'
import { logger } from './logger'
import { loggingManager } from './loggingmanager'
import { loginReducers } from './login'
import { menuReducers } from './menu'
import { menuService } from './menuService'
import { myGousto } from './myGousto'
import { onScreenRecoveryReducers } from './onScreenRecovery'
import { persistReducers } from './persist'
import { productsReducers } from './products'
import { promosReducers } from './promos'
import { recipesReducers } from './recipes'
import { redirectReducers } from './redirect'
import { requestReducers } from './request'
import { ribbonReducer } from './ribbonReducer'
import { serverErrorReducers } from './serverError'
import status from './status'
import subscription from './subscription'
import { tempReducers } from './temp'
import { trackingReducers } from './tracking'
import { tutorial } from './tutorial'
import user from './user'

// Order items alphabetically
export const reducers = {
  ...appBanner,
  ...accountReducers,
  ...auth,
  ...basket,
  ...boxSummaryReducers,
  ...checkoutReducers,
  ...checkoutUrgencyReducers,
  ...content,
  ...deliveryDetailsReducer,
  ...feedback,
  ...featuresReducers,
  ...filtersReducers,
  getHelp,
  ...loginReducers,
  logger,
  loggingManager,
  ...menuReducers,
  menuService,
  myGousto,
  ...onScreenRecoveryReducers,
  payment,
  ...persistReducers,
  ...productsReducers,
  ...promosReducers,
  ...recipesReducers,
  ...redirectReducers,
  ...serverErrorReducers,
  ...signupReducers,
  ...status,
  ...subscription,
  ...tempReducers,
  ...trackingReducers,
  ...tutorial,
  ...user,
  ...requestReducers,
  ...ribbonReducer,
}
