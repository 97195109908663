import React from 'react'

import css from './ContentMask.css'

type ContentMaskProps = {
  children: React.ReactNode
  className?: string
  isBlueyRecipe?: boolean
}

export const ContentMask = ({
  className = '',
  children,
  isBlueyRecipe = false,
}: ContentMaskProps) => (
  <div className={className}>
    <div className={isBlueyRecipe ? css['bluey-container'] : css.container}>
      <div className={css.content}>{children}</div>
    </div>
  </div>
)
