import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useIsSkipTheWizardEnabled() {
  const [skipTheWizardDecision] = useDecision('beetroots_ofx_skip_the_wizard')
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) return false

  return skipTheWizardDecision.variationKey === 't1'
}
