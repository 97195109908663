import React, { memo } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import {
  Heading6,
  Modal,
  withCitrusTheme,
  ModalHeader,
  ModalBody,
  Body1,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { checkPaymentAuth } from 'routes/Checkout/checkoutActions'
import { getChallengeUrl, isModalOpen } from 'routes/Checkout/checkoutPaymentSelectors'
import { getUrlParams } from 'routes/Checkout/checkoutUtils'
import { usePricing } from 'routes/Menu/domains/pricing'

export const Checkout3DSModalName = 'Checkout3DSModal'

const Checkout3DSIframe = withCitrusTheme('iframe', () => ({
  display: 'block',
  background: 'transparent',
  width: '100%',
  height: '600px',
  maxWidth: '600px',
  border: 0,
}))

export const Checkout3DSModal = memo(() => {
  const dispatch = useDispatch()
  const { pricing } = usePricing()
  const onChallengeDone = (checkoutSessionId: string) => {
    dispatch(checkPaymentAuth(checkoutSessionId, { pricing }))
  }

  const isOpen = useSelector(isModalOpen)
  const challengeUrl = useSelector(getChallengeUrl)
  const showIframe = !!challengeUrl && isOpen

  const onFrameUrlChange = (url: string) => {
    // the url looks something like gousto.co.uk/payment/success?cko-session-id=sid_ryk66tljtq2u3idee7lyzb3tyt
    // we are interested in the “cko-session-id” params value
    const sessionIdName = 'cko-session-id'
    if (url.indexOf(sessionIdName) >= 0) {
      const sortedUrl = getUrlParams(url) as Record<string, string>
      const sessionId = sortedUrl[sessionIdName]
      onChallengeDone(sessionId)
    } else {
      datadogLogs.logger.warn(`Checkout3DSModal onFrameUrlChange error with following url: ${url}`)
    }
  }

  const onIframeLoad = (event: React.SyntheticEvent<HTMLIFrameElement>) => {
    const target = event.target as HTMLIFrameElement

    try {
      const url = target.contentWindow?.location.href
      if (url) {
        onFrameUrlChange(url)
      }
    } catch (error) {
      datadogLogs.logger.warn(`Checkout3DSModal oniFrameLoad error: ${error} with event ${event}`)
    }
  }

  return (
    <Modal
      name={Checkout3DSModalName}
      preventScroll
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <ModalHeader>
        <Heading6>Card verification</Heading6>
      </ModalHeader>
      <ModalBody>
        <Body1>
          {`You're almost there, Your bank will now verify your card details. If you're experiencing difficulties, please contact your bank.`}
        </Body1>
        {showIframe && (
          <Checkout3DSIframe src={challengeUrl} onLoad={onIframeLoad} title="3DS frame" />
        )}
      </ModalBody>
    </Modal>
  )
})

Checkout3DSModal.displayName = 'Checkout3DSModal'
