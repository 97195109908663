import { datadogLogs } from '@datadog/browser-logs'
import { browserCookies } from '@gousto-internal/storage-consent-web-package'

import { actionTypes } from 'actions/actionTypes'
import * as trackingKeys from 'actions/trackingKeys'
import { SOCIAL_TYPES } from 'components/SocialLinks/socialReferralHelper'
// eslint-disable-next-line import/no-unresolved
import { CHECKOUT_STEPS } from 'routes/Checkout/checkoutConfig'
import { getCurrentPaymentMethod } from 'routes/Checkout/checkoutPaymentSelectors'
import { getBasketRecipes, getPromoCode } from 'selectors/basket'
import { canUseWindow } from 'utils/browserEnvironment'
import logger from 'utils/logger'

import {
  getProductsValueForSingleRecipeById,
  getProductsValueForMultipleRecipes,
  getProductsValueForRecipeIds,
  getBoxTypeValue,
  getOrderDetails,
} from './dataLayerTrackerUtils'

const sendDataLayerEvent = (event) => {
  if (!(canUseWindow() && window.dataLayer)) {
    return
  }

  window.dataLayer.push(event)
}

const sendGA4Event = (event) => {
  if (!(canUseWindow() && window.gtag)) {
    return
  }

  window.gtag('event', event.name, event.params)
}

export const viewRecipe = ({ recipeId }, state) => {
  if (!recipeId) {
    return
  }

  const products = getProductsValueForSingleRecipeById(recipeId, state)
  const product = products[0]

  sendGA4Event({
    name: 'view_item',
    params: {
      items: [
        {
          item_id: recipeId,
          item_name: product.name,
          item_category: product.category,
          quantity: 1,
        },
      ],
    },
  })
}

export const viewRecipeList = (_, state) => {
  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'view_item_list',
    params: {
      box_type: boxType,
      items: [],
    },
  })
}

export const addRecipeToBasket = ({ recipeId, orderId }, state) => {
  if (orderId) {
    // The action is dispatched just after a signup, but it wasn't the
    // interaction from user, so we shouldn't track that.
    return
  }

  const products = getProductsValueForSingleRecipeById(recipeId, state)
  const product = products[0]

  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'add_to_cart',
    params: {
      items: [
        {
          item_id: recipeId,
          item_name: product.name,
          item_category: product.category,
          quantity: 1,
        },
      ],
      box_type: boxType,
    },
  })
}

export const removeRecipeFromBasket = ({ recipeId }, state) => {
  const boxType = getBoxTypeValue(state)
  const products = getProductsValueForSingleRecipeById(recipeId, state)
  const product = products[0]

  sendGA4Event({
    name: 'remove_from_cart',
    params: {
      box_type: boxType,
      items: [
        {
          item_id: recipeId,
          item_name: product.name,
          item_category: product.category,
          quantity: 1,
        },
      ],
    },
  })
}

export const viewCart = ({ show, trackingData }, state) => {
  // we don't want to track if box summary is being closed
  if (!show) {
    return
  }

  const { totalPrice } = trackingData
  const basketRecipes = getBasketRecipes(state)
  const products = getProductsValueForMultipleRecipes(basketRecipes, state)
  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'view_cart',
    params: {
      box_type: boxType,
      currency: 'GBP',
      value: totalPrice,
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
    },
  })
}

export const addShippingInfo = ({ trackingData }, state) => {
  const { totalPrice } = trackingData
  const basketRecipes = getBasketRecipes(state)
  const products = getProductsValueForMultipleRecipes(basketRecipes, state)
  const boxType = getBoxTypeValue(state)
  const promoCode = getPromoCode(state)

  sendGA4Event({
    name: 'add_shipping_info',
    params: {
      currency: 'GBP',
      value: totalPrice,
      coupon: promoCode,
      box_type: boxType,
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
    },
  })
}

export const addPaymentMethod = ({ trackingData }, state) => {
  const { promoCode, paymentMethod, totalPrice } = trackingData
  const basketRecipes = getBasketRecipes(state)
  const products = getProductsValueForMultipleRecipes(basketRecipes, state)
  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'add_payment_info',
    params: {
      currency: 'GBP',
      value: totalPrice,
      coupon: promoCode,
      payment_type: paymentMethod,
      box_type: boxType,
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
    },
  })
}

export const signupPurchaseCompleted = (action, state) => {
  const { orderId, basketRecipes, pricing } = action

  const { recipeTotalDiscounted: totalPrice, totalDiscount, promoCode } = pricing || {}

  const products = getProductsValueForMultipleRecipes(basketRecipes, state)
  const boxType = getBoxTypeValue(state)
  const paymentType = getCurrentPaymentMethod(state)

  sendGA4Event({
    name: 'purchase_welcome',
    params: {
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
      box_type: boxType,
      transaction_id: `signup ${orderId}`,
      value: totalPrice,
      currency: 'GBP',
      coupon: promoCode,
      coupon_value: totalDiscount,
      payment_type: paymentType,
    },
  })

  if (canUseWindow()) {
    const [, consentCookie] = browserCookies.getJSON('v1_gousto_cookie_consent')
    const consentedCategories = consentCookie?.categories || []

    datadogLogs.logger.info('Info: purchase_welcome event sent', {
      orderId,
      boxType,
      orderValue: totalPrice,
      paymentType,
      consent: consentedCategories,
    })
  }
}

export const customerPurchaseCompleted = ({ order }, state) => {
  const { recipeIds, totalPrice, orderId } = getOrderDetails(order)

  const products = getProductsValueForRecipeIds(recipeIds, state)

  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'purchase',
    params: {
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
      box_type: boxType,
      transaction_id: orderId,
      value: totalPrice,
      currency: 'GBP',
    },
  })
}

export const referFriendLinkCopied = () => {
  const event = {
    event: 'referral_click',
    type: SOCIAL_TYPES.link,
  }
  logger.debug({ message: 'dataLayerTracker - referFriendLinkCopied', extra: event })
  sendDataLayerEvent(event)
}

export const referFriendLinkShared = (action) => {
  const { trackingData } = action
  const event = {
    event: 'referral_click',
    type: trackingData.channel,
  }
  logger.debug({ message: 'dataLayerTracker - referFriendLinkShared', extra: event })
  sendDataLayerEvent(event)
}

export const locationChange = ({ payload }, state) => {
  const { pathname } = payload
  const checkoutRegexp = new RegExp('^/check-out/(.*)')
  const match = checkoutRegexp.exec(pathname)
  if (!match) {
    return
  }
  const stepName = match[1]
  const stepIndex = CHECKOUT_STEPS.findIndex((checkoutStep) => checkoutStep.id === stepName)
  if (stepIndex === -1) {
    return
  }
  const basketRecipes = getBasketRecipes(state)
  const products = getProductsValueForMultipleRecipes(basketRecipes, state)
  const boxType = getBoxTypeValue(state)

  sendGA4Event({
    name: 'checkout_step',
    params: {
      items: products.map((p) => ({
        item_id: p.id,
        item_name: p.name,
        item_category: p.category,
        quantity: p.quantity,
      })),
      box_type: boxType,
      step: stepIndex + 1,
    },
  })
}

const callbacks = {
  [actionTypes.MENU_RECIPE_DETAIL_VISIBILITY_CHANGE]: viewRecipe,
  [actionTypes.VIEW_RECIPE_LIST]: viewRecipeList,
  [actionTypes.BASKET_RECIPE_ADD]: addRecipeToBasket,
  [actionTypes.BASKET_RECIPE_REMOVE]: removeRecipeFromBasket,
  [actionTypes.BOXSUMMARY_VISIBILITY_CHANGE]: viewCart,
  [actionTypes.CHECKOUT_CONTINUE_TO_PAYMENT]: addShippingInfo,
  [trackingKeys.clickSubmitOrder]: addPaymentMethod,
  [actionTypes.CHECKOUT_SIGNUP_SUCCESS]: signupPurchaseCompleted,
  [actionTypes.ORDER_CREATE_TRANSACTIONAL]: customerPurchaseCompleted,
  [actionTypes.REFER_FRIEND_LINK_COPIED]: referFriendLinkCopied,
  [actionTypes.REFER_FRIEND_LINK_SHARE]: referFriendLinkShared,
  // eslint-disable-next-line no-underscore-dangle
  [actionTypes.__REACT_ROUTER_LOCATION_CHANGE]: locationChange,
}

export const dataLayerTracker = (action, state) => {
  const { type } = action
  const callback = callbacks[type]

  if (!callback) {
    return
  }
  callback(action, state)
}
