import logger from 'utils/logger'
import webClientStatusActions from 'actions/status'
import { actionTypes as webClientActionTypes } from 'actions/actionTypes'
import { getAccessToken } from 'selectors/auth'
import * as orderV2 from 'routes/Menu/apis/orderV2'
import { fetchRecipesWithIngredients } from '../apis/menu'
import { getIsMultiComplaintLimitReachedLastFourWeeks, getIsBoxDailyComplaintLimitReached } from '../selectors/orderSelectors'
import { getOrder, getNumOrdersChecked, getNumOrdersCompensated } from '../selectors/selectors'
import { getRecipes } from '../selectors/recipesSelectors'
import { getCompensation, getIsMultiComplaints } from '../selectors/compensationSelectors'
import { actionTypes, trackingKeys } from './actionTypes'
import { asyncAndDispatch } from './utils'
import { transformRecipesWithIngredients } from './transformers/recipeTransform'
import { validateOrder } from '../apis/ssrIngredients'

export const SE_CATEGORY_HELP = 'help'

export const trackDeliveryOther = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: 'GetHelpTrackDeliveryOther Clicked',
  },
})

export const trackDeliveryStatus = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: 'GetHelpTrackDeliveryStatus Clicked',
  },
})

export const trackNextBoxTrackingClick = orderId => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: 'GetHelpTrackMyBox Clicked',
    orderId,
  }
})

export const getUserOrders = (orderType = 'pending', number = 10) => (
  async (dispatch, getState) => {
    dispatch(webClientStatusActions.pending(actionTypes.GET_HELP_LOAD_ORDERS, true))
    dispatch(webClientStatusActions.error(actionTypes.GET_HELP_LOAD_ORDERS, null))
    try {
      const payload = {
        limit: number,
        sort_order: 'desc',
        state: orderType,
        includes: ['shipping_address']
      }
      const { data: orders } = await orderV2.fetchUserOrders(dispatch, getState, payload)

      dispatch({
        type: actionTypes.GET_HELP_LOAD_ORDERS,
        orders
      })
    } catch (err) {
      dispatch(webClientStatusActions.error(actionTypes.GET_HELP_LOAD_ORDERS, err.message))
      logger.error(err)
    }
    dispatch(webClientStatusActions.pending(actionTypes.GET_HELP_LOAD_ORDERS, false))
  }
)

export const loadOrderById = ({ orderId }) => async (dispatch, getState) => {
  const getPayload = async () => {
    const include = 'shipping_address'
    const { data: order } = await orderV2.fetchOrder(dispatch, getState, orderId, include)

    return { order }
  }

  const handleError = (err) => {
    throw err
  }

  await asyncAndDispatch({
    dispatch,
    actionType: webClientActionTypes.GET_HELP_LOAD_ORDERS_BY_ID,
    getPayload,
    errorMessage: `Failed to loadOrderById for orderId: ${orderId}`,
    handleError,
  })
}

export const loadOrderAndRecipesByIds = (orderId) => (
  async (dispatch, getState) => {
    const state = getState()

    const getPayload = async () => {
      let order = getOrder(state)
      let recipes = getRecipes(state)
      // recipeItems in the store is an array of recipe IDs
      let { recipeItems: recipeIds, recipeUuids } = order

      if (recipeIds.length === 0) {
        const response = await orderV2.fetchOrder(dispatch, getState, orderId, 'shipping_address')
        // copying the object so we do not mutate test's mocked response
        order = {...response.data}
        recipeIds = order.recipeItems.map(item => item.recipeId)
        recipeUuids = order.recipeItems.map(item => item.recipeUuid)
        order.recipeItems = recipeIds
      }

      if (!recipes.length) {
        const response = await fetchRecipesWithIngredients(recipeUuids)
        recipes = transformRecipesWithIngredients(response.data, response.included, order.box.numPortions)
      }

      return { order, recipes }
    }

    await asyncAndDispatch({
      dispatch,
      actionType: actionTypes.GET_HELP_LOAD_ORDER_AND_RECIPES_BY_IDS,
      getPayload,
      errorMessage: `Failed to loadOrderAndRecipesByIds for orderId: ${orderId}`,
    })
  }
)

export const storeGetHelpOrder = ({ id, recipeIds, recipeDetailedItems, deliverySlot, deliveryDate }) => ({
  type: actionTypes.GET_HELP_STORE_ORDER,
  payload: {
    id,
    recipeIds,
    recipeDetailedItems,
    deliverySlot,
    deliveryDate,
  },
})

export const setSelectedAddress = (address) => ({
  type: actionTypes.GET_HELP_STORE_SELECTED_ADDRESS,
  payload: {
    address,
  },
})

export const trackIngredientsGetInTouchClick = () => (dispatch, getState) => {
  const { amount } = getCompensation(getState())
  const isMultiComplaint = getIsMultiComplaints(getState())
  const isMultiComplaintLimitReachedLastFourWeeks = getIsMultiComplaintLimitReachedLastFourWeeks(getState())
  const isBoxDailyComplaintLimitReached = getIsBoxDailyComplaintLimitReached(getState())
  const numOrdersChecked = getNumOrdersChecked(getState())
  const numOrdersCompensated = getNumOrdersCompensated(getState())

  dispatch({
    type: webClientActionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.ssrIngredientsClickGetInTouch,
      amount,
      is_second_complaint: isMultiComplaint,
      prev_comp_same_day: isBoxDailyComplaintLimitReached,
      seCategory: SE_CATEGORY_HELP,
      reason: isMultiComplaintLimitReachedLastFourWeeks ? 'multi_complaint_limit_last_four_week' : undefined,
      num_orders_checked: numOrdersChecked,
      num_orders_compensated: numOrdersCompensated,
    }
  })
}

export const trackIngredientsGoToMyGousto = () => (dispatch, getState) => {
  const isMultiComplaintLimitReachedLastFourWeeks = getIsMultiComplaintLimitReachedLastFourWeeks(getState())
  const isBoxDailyComplaintLimitReached = getIsBoxDailyComplaintLimitReached(getState())
  let reason

  switch (true) {
  case isMultiComplaintLimitReachedLastFourWeeks:
    reason = 'multi_complaint_limit_last_four_week'
    break
  case isBoxDailyComplaintLimitReached:
    reason = 'box_daily_complaint_limit_reached'
    break
  default:
    break
  }

  dispatch({
    type: webClientActionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.ssrIngredientsClickGoToMyGousto,
      reason
    }
  })
}

export const trackConfirmationCTA = () => (dispatch, getState) => {
  const isMultiComplaint = getIsMultiComplaints(getState())

  dispatch({
    type: webClientActionTypes.TRACKING,
    trackingData: {
      actionType: trackingKeys.ssrClickDoneRefundAccepted,
      is_second_complaint: isMultiComplaint,
      seCategory: SE_CATEGORY_HELP,
    }
  })
}

export const trackClickGetHelpWithThisBox = (orderId) => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.clickGetHelpWithThisBox,
    order_id: orderId,
    seCategory: SE_CATEGORY_HELP,
  }
})

export const trackHelpPreLoginModalDisplayed = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.helpPreLoginModalDisplayed,
    seCategory: SE_CATEGORY_HELP,
  }
})

export const trackContinueAsNewCustomer = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.clickContinueAsNewCustomer,
    seCategory: SE_CATEGORY_HELP,
  }
})

export const trackIngredientReasonsConfirmed = (selectedIngredients) => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrIngredientsReasonsConfirmed,
    selected_ingredients: selectedIngredients,
    seCategory: SE_CATEGORY_HELP,
  },
})

export const trackMassIssueAlertDisplayed = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.helpMassIssueIngredientAlertDisplayed,
    seCategory: SE_CATEGORY_HELP,
  }
})

export const trackSelectIngredient = (selectedIngredient) => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrSelectIngredient,
    ingredient_name: selectedIngredient,
    seCategory: SE_CATEGORY_HELP,
  },
})

export const trackDeselectIngredient = (selectedIngredient) => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrDeselectIngredient,
    ingredient_name: selectedIngredient,
    seCategory: SE_CATEGORY_HELP,
  },
})

export const trackRecipeCardClick = recipeId => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrPrintedRecipeCardsClickViewCookbook,
    seCategory: SE_CATEGORY_HELP,
    recipe_id: recipeId,
  }
})

export const trackRefundFAQClick = ({
  compensationAmount,
  articleName,
  isMultiComplaints,
}) => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrIngredientsOpenRefundArticle,
    seCategory: SE_CATEGORY_HELP,
    amount: compensationAmount,
    article_name: articleName,
    is_second_complaint: isMultiComplaints,
  }
})

export const validateLatestOrder = ({
  orderId,
  customerId
}) => async (dispatch, getState) => {
  dispatch(webClientStatusActions.pending(webClientActionTypes.GET_HELP_VALIDATE_ORDER, true))
  dispatch(webClientStatusActions.error(webClientActionTypes.GET_HELP_VALIDATE_ORDER, ''))

  try {
    const response = await validateOrder(
      getAccessToken(getState()),
      {
        customer_id: Number(customerId),
        order_id: Number(orderId),
      },
    )
    const {
      previousIssues,
      numOrdersChecked,
      numOrdersCompensated,
    } = response.data

    dispatch({
      type: webClientActionTypes.GET_HELP_VALIDATE_ORDER,
      previousIssues,
      numOrdersChecked,
      numOrdersCompensated,
    })
  } catch (error) {
    dispatch(webClientStatusActions.error(webClientActionTypes.GET_HELP_VALIDATE_ORDER, error.message))
  } finally {
    dispatch(webClientStatusActions.pending(webClientActionTypes.GET_HELP_VALIDATE_ORDER, false))
  }
}

export const trackRecipeCardsAddressChangeArticle = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.openHowCanIAddAddressArticle,
    seCategory: SE_CATEGORY_HELP,
  },
})

export const trackClickChoosePrintedRecipeCards = () => ({
  type: webClientActionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.ssrClickChoosePrintedRecipeCards,
    seCategory: SE_CATEGORY_HELP,
  },
})
