import React from 'react'

import { useDecision } from '@optimizely/react-sdk'
import { RibbonTriggerContainer } from 'RibbonTrigger'

import { useIsOptimizelyFeatureEnabled } from 'containers/OptimizelyRollouts'
import { AboutYou } from 'routes/Checkout/Components/AboutYou/AboutYou'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'

import { CheckoutTrustPilot } from './CheckoutTrustPilot'

type CreateAccountProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
}

export const CreateAccount = ({ onStepChange }: CreateAccountProps) => {
  const isCheckoutSocialProofEnabled = useIsOptimizelyFeatureEnabled(
    'beetroots_checkout_social_proof_enabled',
  )
  /**
   * Experiment to test allocation for Optimizely FX implementation
   * Cleanup ticket - https://gousto.atlassian.net/browse/FEF-1188
   */
  useDecision('fef_ofx_account_creation_test')

  return (
    <>
      <AboutYou onStepChange={onStepChange} />
      <RibbonTriggerContainer name="checkout-account" />
      {isCheckoutSocialProofEnabled && <CheckoutTrustPilot />}
    </>
  )
}
