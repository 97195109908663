import React, { useCallback } from 'react'

import {
  AlignItems,
  Box,
  Button,
  Color,
  Display,
  StepperButton,
} from '@gousto-internal/citrus-react'
import actions from 'actions'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { recipesConfig } from 'config/recipes'
import { useBasket, useSupportedBoxTypes } from 'routes/Menu/domains/basket'

import { menuRecipeDetailVisibilityChange } from '../../../actions/menuRecipeDetails'
import { Surcharge } from './Surcharge'

import css from './RecipeDetailsButtons.css'

type ButtonsProps = {
  buttonText?: string
  isOutOfStock?: boolean
  position?: string
  recipeId: string
  surcharge?: number
  view: string
}

const generateGetSurchargeGridClass =
  (surchargePerPortion: number | null | undefined, view: string) =>
  (className: string, ...otherClasses: string[]) => {
    const viewsToExclude = recipesConfig.recipeDetailViews

    const shouldApplyClass = Boolean(surchargePerPortion && !viewsToExclude.includes(view))
    const otherClassNames = otherClasses.map((name) => css[name])

    return classnames({ [css[className]]: shouldApplyClass }, ...otherClassNames)
  }

export const RecipeDetailsButtons = ({
  buttonText = 'Add recipe',
  isOutOfStock = false,
  position = '',
  recipeId,
  surcharge,
  view,
}: ButtonsProps) => {
  const dispatch = useDispatch()
  const {
    numPortions,
    reachedLimit,
    getQuantitiesForRecipeId,
    canAddRecipes,
    addRecipe,
    removeRecipe,
  } = useBasket()
  const { maxRecipesForPortion } = useSupportedBoxTypes()
  const maxRecipesNum = maxRecipesForPortion(numPortions)
  const qty = getQuantitiesForRecipeId(recipeId)

  const handleAdd = useCallback(
    (isFirstInBatchOfSameRecipes: boolean) => {
      if (!isOutOfStock && canAddRecipes) {
        addRecipe(recipeId, view, { position }, maxRecipesNum)

        if (isFirstInBatchOfSameRecipes) {
          dispatch(menuRecipeDetailVisibilityChange())
        }
      } else if (recipesConfig.recipeDetailViews.includes(view)) {
        dispatch(menuRecipeDetailVisibilityChange())
        setTimeout(() => {
          dispatch(actions.menuBrowseCTAVisibilityChange(true))
        }, 500)
      } else {
        dispatch(actions.menuBrowseCTAVisibilityChange(true))
      }
    },
    [isOutOfStock, canAddRecipes, view, addRecipe, recipeId, position, maxRecipesNum, dispatch],
  )

  const handleRemove = useCallback(() => {
    removeRecipe(recipeId, view, position)
  }, [recipeId, view, position, removeRecipe])

  const getSegments = (disabled: boolean) => {
    const getSurchargeGridClass = generateGetSurchargeGridClass(surcharge, view)

    if (qty > 0) {
      const totalQty = qty * numPortions
      const defaultContent = ' Servings Added'
      const textContent = surcharge ? ' Added' : defaultContent

      return [
        <Box key={Math.random()} display={Display.Flex} alignItems={AlignItems.Center}>
          <StepperButton
            fullWidth
            increment={() => handleAdd(false)}
            decrement={handleRemove}
            count={1}
            disableIncrement={reachedLimit || isOutOfStock}
            disableDecrement={isOutOfStock}
          >
            <Box style={{ padding: '0 5rem' }}>
              {`${totalQty}${textContent}`}
              {surcharge && (
                <Box className={getSurchargeGridClass('surchargeHidden', 'surcharge')}>
                  <Surcharge surcharge={surcharge} />
                </Box>
              )}
            </Box>
          </StepperButton>
        </Box>,
      ]
    }

    return (
      <Button
        onClick={() => {
          handleAdd(true)
        }}
        disabled={disabled}
        className={getSurchargeGridClass('segment', 'sentenceCaseSegment')}
        width="100%"
      >
        {buttonText}
        {surcharge && (
          <Box className={getSurchargeGridClass('surchargeWrapped', 'surcharge')}>
            <Surcharge surcharge={surcharge} />
          </Box>
        )}
      </Button>
    )
  }

  const disabled = isOutOfStock || reachedLimit
  const dataTesting = qty < 1 ? 'menuRecipeAdd' : 'menuAddServings'

  return (
    <Button
      data-testing={disabled ? 'menuRecipeAddDisabled' : dataTesting}
      width="full"
      style={{ flex: 1, backgroundColor: Color.White }}
    >
      {getSegments(disabled)}
    </Button>
  )
}
