import { useSelector } from 'react-redux'

import { useGetCtaProps } from '../Cta'
import { getNumMenuRecipes } from './carouselSelectors'

export const useGetCarouselData = () => {
  const numRecipes = useSelector(getNumMenuRecipes)
  const ctaProps = useGetCtaProps()

  return { numRecipes, ...ctaProps }
}
