import React, { HTMLAttributes } from 'react'

import styled from '@emotion/styled'
import { useTheme } from '@gousto-internal/citrus-react'

export const ProcessImageElement = (props: HTMLAttributes<HTMLDivElement>) => {
  const { theme } = useTheme()

  const breakpointFromMedium = theme.tokens.breakpoints[0]
  const breakpointFromLarge = theme.tokens.breakpoints[1]

  const BreakpointFromMedium = `@media (min-width: ${breakpointFromMedium})`
  const BreakpointFromLarge = `@media (min-width: ${breakpointFromLarge})`

  const Component = styled.div({
    margin: 'auto 0 0 0',

    picture: {
      display: 'flex',
      width: '100%',
    },

    img: {
      display: 'none',

      [BreakpointFromMedium]: {
        display: 'flex',

        width: '100%',
        objectPosition: 'left bottom',
        objectFit: 'contain',
      },

      [BreakpointFromLarge]: {
        marginBottom: 0,
      },
    },
  })

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />
}
