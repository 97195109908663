import styled from '@emotion/styled'

export const TimeIndicatorContainer = styled.div({
  position: 'relative',
  borderRadius: '50%',
  backgroundColor: 'hsla(0,0%,100%,.75)',
  padding: '0.25rem',
  fontWeight: '900',
  fontFamily: 'AxiformaBold,Helvetica,sans-serif',
})
