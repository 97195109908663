import React from 'react'

import { Checkbox, Link, Text } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { checkoutClickPrivacyPolicy } from 'actions/trackingKeys'
import { client } from 'config/routes'
import { FieldContainer } from 'routes/Checkout/Components/AboutYou/FieldContainer'

type CheckboxFieldProps = {
  id: string
  checked: boolean
  onChange: () => void
}

export const CheckboxField = ({ id, checked, onChange }: CheckboxFieldProps) => {
  const dispatch = useDispatch()

  return (
    <FieldContainer>
      <Checkbox
        id={id}
        data-testing="checkoutAllowEmailCheckbox"
        checked={checked}
        onChange={onChange}
      >
        <Text size={1}>
          I’d like to receive news and offers from Gousto in line with the&nbsp;
          <Link
            size={1}
            href={client.privacyPolicy}
            target="_blank"
            onClick={() => dispatch(trackUTMAndPromoCode(checkoutClickPrivacyPolicy))}
          >
            Privacy Policy
          </Link>
          . I can unsubscribe anytime.
        </Text>
      </Checkbox>
    </FieldContainer>
  )
}
