import React from 'react'
import styled from '@emotion/styled'

import { Image } from './Image'
import { cssImageStyle, cssImageWrapper, cssRecipeImageAndCookingTimeWrapper } from './styles'
import { Recipe } from '../../model/recipe'

const ImageWrapper = styled.button(
  () =>
    ({
      ...cssImageWrapper,
      label: '-TileImage',
    } as any),
)

const ContentWrapper = styled.div(cssRecipeImageAndCookingTimeWrapper as any)

export function TileImage({ recipe }: { recipe: Recipe }) {
  return (
    <ImageWrapper>
      <ContentWrapper>
        <Image lazy styles={cssImageStyle} recipe={recipe} />
      </ContentWrapper>
    </ImageWrapper>
  )
}
