import React from 'react'

import { CSSObject } from '@emotion/styled'
import {
  AlignItems,
  Body2,
  Box,
  Color,
  Display1,
  Display,
  FlexDirection,
  FontWeight,
  Icon,
  IconVariant,
  Heading2,
  ResponsiveValue,
  Space,
  Text,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { BENEFITS, BenefitType } from '../Benefits/data'
import bgImageDesktop from './assets/immersiveHero/background-desktop.jpg'
import bgImageMobile from './assets/immersiveHero/background-mobile.jpg'
import bgCutoutDesktop from './assets/steamFX/cutout-desktop.png'
import bgCutoutMobile from './assets/steamFX/cutout-mobile.png'

type ImageSet = {
  mobileImage: string
  desktopImage: string
}

const createBackgroundStyle = ({ mobileImage, desktopImage }: ImageSet) => {
  const responsiveValue: ResponsiveValue<string> = [
    `center/cover no-repeat url(${mobileImage})`,
    `15%/cover no-repeat url(${desktopImage})`,
    `4%/cover no-repeat url(${desktopImage})`,
    `120px/cover no-repeat url(${desktopImage})`,
  ]

  return responsiveValue
}

const mainBackgroundImageSet = {
  mobileImage: bgImageMobile,
  desktopImage: bgImageDesktop,
}

const cutoutBackgroundImageSet = {
  mobileImage: bgCutoutMobile,
  desktopImage: bgCutoutDesktop,
}

export const ContainerWithBackground = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    [...createBackgroundStyle(mainBackgroundImageSet)],
    ['background'],
  ) as CSSObject),
}))

export const ContainerWithGradient = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    ['linear-gradient(to top, #000000, #00000009 50%)', 'none'],
    ['background'],
  ) as CSSObject),
}))

export const ContainerWithCutout = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(
    [...createBackgroundStyle(cutoutBackgroundImageSet)],
    ['background'],
  ) as CSSObject),
}))

export const HeroHeading = withCitrusTheme(Display1, () => ({
  textShadow: '2px 2px 8px rgba(30, 30, 30, 0.9)',
}))

export const HeadingText = withCitrusTheme(Text, () => ({
  [`@media (max-width: 360px)`]: { fontSize: '32px' },
}))

export const SteamFXContainer = withCitrusTheme(Box, () => ({
  [`@media (min-width: 1300px)`]: { right: '10%' },
  [`@media (min-width: 1400px)`]: { right: '15%' },
  [`@media (min-width: 1600px)`]: { right: '25%' },
}))

export const SubHeading = withCitrusTheme(Heading2, () => ({
  textShadow: '2px 2px 6px rgba(30, 30, 30, 0.9)',
}))

export function Benefits() {
  const heroBenefit = BENEFITS.find((benefit) => benefit.type === BenefitType.NO_LOCK_IN_IMMERSIVE)

  if (!heroBenefit) {
    return null
  }

  const { iconName, strongText, text, ariaLabel } = heroBenefit

  return (
    <Box
      aria-label={ariaLabel}
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      alignItems={AlignItems.Center}
    >
      <Icon name={iconName} size={6} variant={IconVariant.Inherit} />
      <Space size={2} direction="horizontal" />
      <Body2 fontWeight={FontWeight.Bold} color={Color.White}>
        {strongText}&nbsp;
      </Body2>
      <Body2 color={Color.White}>{text}</Body2>
    </Box>
  )
}
