/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable jsx-a11y/alt-text */
import React, { HTMLAttributes } from 'react'

export interface SourceProps extends HTMLAttributes<HTMLSourceElement> {
  srcSet: string
  media?: string
  type?: string
}

export interface ImageProps extends HTMLAttributes<HTMLImageElement> {
  src: string
  alt: string
  srcSet?: string
}

export interface PictureProps {
  sourcesMatrix: SourceProps[][]
  image: ImageProps
}

/**
 * Picture element is useful for:
 * - displaying alternative image formats (like AVIF or WEBP),
 * - cropping or modifying images for different media conditions,
 * - saving bandwidth and speeding page load times
 */
export const Picture = ({
  sourcesMatrix,
  image: { srcSet: imageSrcSet, src, alt, ...extraImageProps },
}: PictureProps) => {
  // if the parameters with 'undefined' values are set to the img tag, it will work incorrectly
  const imageProps: ImageProps = { src, alt }
  if (imageSrcSet) {
    imageProps.srcSet = imageSrcSet
  }

  return (
    <picture data-testid="picture">
      {sourcesMatrix.map((sources) =>
        sources.map(({ srcSet, media, type, ...extraSourceProps }) => {
          // if the parameters with 'undefined' values are set to the source tag, it will work incorrectly
          const sourceProps: SourceProps = { srcSet }
          if (media) {
            sourceProps.media = media
          }
          if (type) {
            sourceProps.type = type
          }

          return <source data-testid="source" key={srcSet} {...sourceProps} {...extraSourceProps} />
        }),
      )}

      <img {...imageProps} {...extraImageProps} />
    </picture>
  )
}
