import React, { useEffect } from 'react'

import { Button } from '@gousto-internal/citrus-react'
import { useFormik } from 'formik'
import { Loader } from 'goustouicomponents'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { CheckboxField } from 'routes/Checkout/Components/AboutYou/CheckboxField'
import { EmailField } from 'routes/Checkout/Components/AboutYou/EmailField'
import { PasswordField } from 'routes/Checkout/Components/AboutYou/PasswordField'
import { checkoutClearErrors, clearPayPalErrors } from 'routes/Checkout/checkoutActions'
import { emailValidator } from 'routes/Checkout/checkoutFormUtils'
import {
  getEmail,
  getIsEmailMarketingEnabled,
  getPasswordErrors,
  getPasswordValue,
} from 'routes/Checkout/checkoutSelectors'
import { useIsApplePayEnabled } from 'routes/Checkout/hooks/useIsApplePayEnabled'
import { isSubmitting as getIsSubmitting } from 'routes/Checkout/utils/state'
import { canUseWindow } from 'utils/browserEnvironment'
import { SessionStorageKeys } from 'utils/processQuery'

type EmailPasswordFormProps = {
  onSubmit: (
    email: string,
    password: string,
    areEmailsAllowed: boolean,
    isApplePay?: boolean,
  ) => void
}

export const EmailPasswordForm = ({ onSubmit }: EmailPasswordFormProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkoutClearErrors())
    dispatch(clearPayPalErrors())
    dispatch({ type: actionTypes.APPLE_PAY_ERRORS_CLEAR })
  }, [dispatch])

  const isApplePayEnabled = useIsApplePayEnabled()

  /**
   * To speed things up when signing up as a test user, we pre-fill
   * the email field with the test email address (if we have access
   * to it).
   */
  const testEmailAddress = canUseWindow()
    ? window.sessionStorage.getItem(SessionStorageKeys.GoustoTestEmailAddressKey)
    : null
  const emailAddress = useSelector(getEmail)
  const initialEmailValue = testEmailAddress || emailAddress

  const initialPasswordValue = useSelector(getPasswordValue)
  const initialMarketingCheckboxValue = useSelector(getIsEmailMarketingEnabled)
  const isSubmitting = useSelector(getIsSubmitting)
  const passwordErrors = useSelector(getPasswordErrors)

  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = useFormik({
    initialValues: {
      email: initialEmailValue,
      password: initialPasswordValue,
      isEmailMarketingEnabled: initialMarketingCheckboxValue,
    },
    validateOnBlur: true,
    validate: (formValues) => ({
      email: emailValidator(formValues.email),
    }),
    onSubmit: () => {},
  })

  const isPasswordValid = values.password.length > 0 && passwordErrors.length === 0
  const isCTADisabled = values.password.length === 0 || !!errors.email || !isPasswordValid

  const getCTAText = () => {
    if (isSubmitting) {
      return <Loader />
    } else if (isApplePayEnabled) {
      return 'Checkout'
    } else {
      return 'Continue to Delivery'
    }
  }

  return (
    <form autoComplete="off">
      <EmailField
        id="email"
        isTouched={touched.email}
        value={values.email}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.email}
      />

      <PasswordField
        id="password"
        isTouched={touched.password}
        value={values.password}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />

      <CheckboxField
        id="isEmailMarketingEnabled"
        checked={values.isEmailMarketingEnabled}
        onChange={() => setFieldValue('isEmailMarketingEnabled', !values.isEmailMarketingEnabled)}
      />
      <Button
        height="48px"
        width="100%"
        data-testid="checkoutCTA"
        disabled={isCTADisabled}
        onClick={() => onSubmit(values.email, values.password, values.isEmailMarketingEnabled)}
      >
        {getCTAText()}
      </Button>
    </form>
  )
}
