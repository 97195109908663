import React from 'react'

import { AlignItems, Box, Color, Display, JustifyContent } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { useAuth } from 'routes/Menu/domains/auth'

import { MenuDateRangeContainer } from '../MenuDateRange'
import { MenuPreferences } from '../MenuPreferences'

import css from './SubHeader.css'

type SubHeaderProps = {
  fromJoin?: boolean
}

export const SubHeader = ({ fromJoin = false }: SubHeaderProps) => {
  const { isAuthenticated } = useAuth()

  return (
    <div className={classnames(css[fromJoin ? 'subHeaderSimple' : 'subHeader'])}>
      <div className={css.subHeaderContent}>
        <div className={css.filter}>
          <MenuDateRangeContainer variant="desktop" />
          <div className={css.filterRight}>
            <div className={css.filterSection}>
              {isAuthenticated && <MenuPreferences variant="desktop" />}
            </div>
          </div>
        </div>
      </div>
      <div className={css.mobile}>
        <Box
          alignItems={AlignItems.Center}
          bg={Color.White}
          display={Display.Flex}
          justifyContent={JustifyContent.SpaceBetween}
          paddingH={3}
          paddingV={2}
        >
          <MenuDateRangeContainer variant="mobile" />
          {isAuthenticated && <MenuPreferences variant="mobile" />}
        </Box>
      </div>
    </div>
  )
}
