import { useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { getDeliveryDaysError, getPostcodePending } from 'routes/Signup/signupSelectors'
import {
  getBasketIsPortionSizeViewVisible,
  getBasketIsShowDetailsViewFirst,
  getBasketOrderId,
  getBasketPostcode,
  getBasketSlotId,
} from 'selectors/basket'
import { getReceiveMenuPending } from 'selectors/root'
import { createGetActionTypeIsPending } from 'selectors/status'

import { getIsProspectFirstVisitMenu } from '../selectors/menu'

export enum BoxSummaryViews {
  POSTCODE = 'POSTCODE',
  DELIVERY_SLOT = 'DELIVERY_SLOT',
  DETAILS = 'DETAILS',
  PORTION_SIZE = 'PORTION_SIZE',
}

export function useGetCurrentBoxSummaryView() {
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const postcode = useSelector(getBasketPostcode)
  const deliveryDaysError = useSelector(getDeliveryDaysError)
  const isPostcodePending = useSelector(getPostcodePending)
  const slotId = useSelector(getBasketSlotId)
  const orderId = useSelector(getBasketOrderId)
  const prospectFirstVisitIsMenu = useSelector(getIsProspectFirstVisitMenu)
  const isPortionSizeViewVisible = useSelector(getBasketIsPortionSizeViewVisible)
  const isShowDetailsViewFirst = useSelector(getBasketIsShowDetailsViewFirst)
  const isReceiveMenuPending = useSelector(getReceiveMenuPending)
  const isMenuBoxPricesReceivePending = useSelector(
    createGetActionTypeIsPending(actionTypes.MENU_BOX_PRICES_RECEIVE),
  )
  const isMenuFetchDataPending = useSelector(
    createGetActionTypeIsPending(actionTypes.MENU_FETCH_DATA),
  )

  const isMenuPending =
    isReceiveMenuPending || isMenuBoxPricesReceivePending || isMenuFetchDataPending

  const noPostcode = !postcode
  const noOrderId = !orderId
  const noSlotId = !slotId

  const isProspectFirstVisitMenu =
    (prospectFirstVisitIsMenu || isSkipTheWizardEnabled) && isShowDetailsViewFirst

  const showPostcode = (noPostcode || deliveryDaysError || isPostcodePending) && noOrderId

  const showDeliverySlot = ((!showPostcode && noSlotId) || isMenuPending) && noOrderId

  const showPortionsSizeView = !showPostcode && !showDeliverySlot && isPortionSizeViewVisible

  const showDetailsView = !showPostcode && !showDeliverySlot

  let currentView

  if (isProspectFirstVisitMenu) {
    currentView = BoxSummaryViews.DETAILS
  } else if (showPostcode) {
    currentView = BoxSummaryViews.POSTCODE
  } else if (showDeliverySlot) {
    currentView = BoxSummaryViews.DELIVERY_SLOT
  } else if (showPortionsSizeView) {
    currentView = BoxSummaryViews.PORTION_SIZE
  } else if (showDetailsView) {
    currentView = BoxSummaryViews.DETAILS
  }

  return currentView
}
