import React from 'react'

import { Button, Color, withCitrusTheme } from '@gousto-internal/citrus-react'

import { useStock } from '../../../domains/basket'
import { RecipeDetailsButtons } from '../RecipeDetailsButtons'

import css from './DetailAddRecipe.css'

type DetailAddRecipeProps = {
  id: string
  isChefPrepared: boolean
  position: string
  surcharge?: number
  view:
    | 'grid'
    | 'list'
    | 'featured'
    | 'simple'
    | 'chefPrepared'
    | 'fineDineIn'
    | 'fineDineInDetail'
    | 'detail'
    | 'smallGrid'
}

const DisabledButton = withCitrusTheme(Button, (theme) => ({
  '&:disabled, &:disabled:hover, &:disabled:focus': {
    color: theme.color(Color.White),
  },
}))

export const DetailAddRecipe = ({
  id,
  isChefPrepared,
  position,
  surcharge,
  view,
}: DetailAddRecipeProps) => {
  const { isRecipeOutOfStock } = useStock()
  const isOutOfStock = isRecipeOutOfStock(id)
  const buttonText = isChefPrepared ? 'Add meal' : 'Add recipe'

  return (
    <div className={css.addRecipeWrapper}>
      {isOutOfStock ? (
        <DisabledButton aria-label="Sold out recipe" disabled width="100%">
          Sold out
        </DisabledButton>
      ) : (
        <RecipeDetailsButtons
          position={position}
          recipeId={id}
          view={view}
          isOutOfStock={isOutOfStock}
          buttonText={buttonText}
          surcharge={surcharge}
        />
      )}
    </div>
  )
}
