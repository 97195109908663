import styled from '@emotion/styled'
import { Color } from '@gousto-internal/citrus-react'

import ArrowImage from 'media/svgs/icon-navigation-arrow-right.svg'

export const Button = styled.button<{ side: string }>(({ side }) => ({
  display: 'none',
  height: 0,
  opacity: 0,

  '@media (min-width: 640px)': {
    position: 'absolute',
    width: '2.5rem',
    height: '2.5rem',
    zIndex: 5,
    borderRadius: '50%',
    top: 'calc(50% - 1.25rem)',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0.45rem',
    opacity: 0.6,
    border: `1px solid ${Color.White}`,
    outline: 0,
    left: side === 'arrowLeft' ? '2rem' : 'auto',
    right: side === 'arrowRight' ? '2rem' : 'auto',
    transform: side === 'arrowLeft' ? 'rotate(180deg)' : 'rotate(0deg)',
    display: 'block',
    background: '#333D47',
  },

  '&:focus': {
    outline: 0,
  },

  '&:hover': {
    opacity: 0.9,
  },

  '&::before': {
    content: `url(${ArrowImage})`,
  },
}))
