import React, { useRef, useEffect } from 'react'

import {
  Box,
  Text,
  Link,
  Color,
  Heading4,
  AlignItems,
  JustifyContent,
  FontFamily,
  Display,
  TextAlign,
  useTheme,
  FlexDirection,
  Visible,
} from '@gousto-internal/citrus-react'

import { useGetHomePageExperiments } from 'routes/Home/homeHooks'

import { SeedBar } from './containers.styled'

const TrustPilot = () => {
  const ref = useRef(null)
  const { theme } = useTheme()
  const { isImmersiveHeroEnabled } = useGetHomePageExperiments()

  useEffect(() => {
    // widget may not load if the bootstrapper script has been loaded before the component gets rendered
    // we use the TrustPilot loadFromElement function to manually load the widget if this happens

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <Box
      display={Display.Flex}
      flexDirection={[FlexDirection.Column, FlexDirection.Row]}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.SpaceBetween}
      bg={Color.NeutralGrey_50}
      data-testid="TrustPilot"
    >
      {isImmersiveHeroEnabled ? (
        <Visible display={[Display.None, Display.Block]}>
          <SeedBar side="left" />
        </Visible>
      ) : (
        <SeedBar side="left" />
      )}
      <Box paddingV={6} paddingBottom={5} flexGrow={1} width={['100%', 'auto']} maxWidth="1171px">
        <Heading4 fontFamily={FontFamily.Bold} textAlign={TextAlign.Center}>
          Over{' '}
          <Text display={Display.Inline} color={Color.Primary_600} fontFamily={FontFamily.Bold}>
            143 million meals{' '}
          </Text>
          cooked and loved
        </Heading4>
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="512cda6e000064000522fb6a"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
          data-font-family="Montserrat"
          data-text-color={theme.color(Color.ColdGrey_800)}
          data-testid="TrustPilotWidget"
        >
          <Link href="https://uk.trustpilot.com/review/gousto.co.uk" target="_blank">
            Trustpilot
          </Link>
        </div>
      </Box>
      <SeedBar side="right" />
    </Box>
  )
}
export { TrustPilot }
