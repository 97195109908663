import React from 'react'

import { useTheme } from '@gousto-internal/citrus-react'

import { Picture } from '../../Picture'
import TabletDesktopBrandMerch from '../assets/tablet-desktop-gousto-merch.jpg'
import TabletDesktopBrandMerchWebp from '../assets/tablet-desktop-gousto-merch.webp'
import { ProcessImageElement } from './ProcessImageStyled'

export const ProcessImage = () => {
  const { theme } = useTheme()

  const breakpointFromMedium = theme.tokens.breakpoints[0]
  const BreakpointFromMedium = `(min-width: ${breakpointFromMedium})`

  return (
    <ProcessImageElement data-testid="pictureElementWrapper">
      <Picture
        sourcesMatrix={[
          [
            {
              srcSet: TabletDesktopBrandMerchWebp,
              media: BreakpointFromMedium,
              type: 'image/webp',
            },
            {
              srcSet: TabletDesktopBrandMerch,
              media: BreakpointFromMedium,
            },
          ],
        ]}
        image={{ src: TabletDesktopBrandMerch, alt: '' }}
      />
    </ProcessImageElement>
  )
}
