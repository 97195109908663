import React, { useRef, useEffect, useState, memo } from 'react'

import {
  Display,
  TextAlign,
  Color,
  Box,
  FlexDirection,
  JustifyContent,
  AlignItems,
  Space,
  Grid,
  Col,
  DisplayPosition,
  ZIndex,
  Overflow,
  CSSKeywords,
  FontFamily,
  useTheme,
} from '@gousto-internal/citrus-react'

import { canUseWindow } from '@library/environment'

import { homeConfig } from 'routes/Home/homeConfig'

import { GetStarted } from './GetStarted'
import { HighlightChoiceImmersive } from './HighlightChoiceImmersive/HighlightChoiceImmersive'
import {
  Benefits,
  ContainerWithBackground,
  ContainerWithCutout,
  ContainerWithGradient,
  HeadingText,
  HeroHeading,
  SteamFXContainer,
  SubHeading,
} from './ImmersiveHero.styled'
import { SteamFX } from './SteamFX'
import { onScrollHandler } from './heroUtils'

const ImmersiveHero = () => {
  const [isSticky, setIsSticky] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)
  const { theme } = useTheme()

  const heroRef = useRef<HTMLDivElement>(null)
  const stickyCTARef = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    onScrollHandler({ heroRef, stickyCTARef, setIsSticky, setMaxHeight })
  }

  const headerWords = homeConfig.hero.header.split(' ')
  const firstHeaderWord = headerWords[0]
  const remainingHeader = headerWords.slice(1).join(' ')

  useEffect(() => {
    if (canUseWindow()) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (canUseWindow()) {
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    // the div wrapper takes the ref as currently refs are unreliable when applied to Citrus components
    <div ref={heroRef}>
      <Box
        data-testid="ImmersiveHero"
        display={Display.Flex}
        justifyContent={JustifyContent.Center}
        style={{ backgroundColor: 'black' }}
        position={DisplayPosition.Relative}
        overflowY={Overflow.Hidden}
        overflowX={Overflow.Hidden}
      >
        <SteamFXContainer
          position={DisplayPosition.Absolute}
          bottom={['10%', '35%', '30%']}
          right={[null, '-100px', '80px', '5%']}
          height={['120%', '100%']}
          width="100%"
          maxWidth={['450px', null, null, '600px']}
          zIndex={ZIndex.Masked}
        >
          <SteamFX />
        </SteamFXContainer>
        <ContainerWithBackground
          height={['589px', 'calc(100vh - 240px)']}
          maxHeight="734px"
          minHeight={['unset', '600px']}
          display={Display.Flex}
          maxWidth={['531px', 'unset']}
          width="100%"
        >
          <ContainerWithCutout
            height="100%"
            width="100%"
            data-testid="ImmersiveHeroCutout"
            zIndex={ZIndex.Mask}
          >
            <ContainerWithGradient height="100%" data-testid="ImmersiveHeroGradient">
              <Grid height="100%">
                <Col
                  size={12}
                  display={Display.Flex}
                  flexDirection={FlexDirection.Column}
                  alignItems={[AlignItems.Center, AlignItems.FlexStart]}
                  justifyContent={[JustifyContent.FlexEnd, JustifyContent.Center]}
                  paddingH={[null, 8]}
                  paddingBottom={4}
                  color={Color.White}
                  zIndex={ZIndex.Header}
                >
                  <Box
                    maxWidth={['375px', '400px']}
                    display={Display.Flex}
                    flexDirection={FlexDirection.Column}
                    alignItems={[AlignItems.Center, AlignItems.FlexStart]}
                    paddingH={[3, 0]}
                  >
                    <HeroHeading color={Color.White}>
                      <HeadingText
                        textAlign={[TextAlign.Center, TextAlign.Left]}
                        fontFamily={FontFamily.Bold}
                        size={[13, CSSKeywords.Inherit]}
                      >
                        {firstHeaderWord}
                      </HeadingText>
                      <HeadingText
                        textAlign={[TextAlign.Center, TextAlign.Left]}
                        fontFamily={FontFamily.Bold}
                        size={[13, CSSKeywords.Inherit]}
                      >
                        {remainingHeader}
                      </HeadingText>
                    </HeroHeading>
                    <Space size={1} />
                    <Box width={['302px', null, '375px']}>
                      <SubHeading
                        size={theme.tokens.typographyDefaults.h3.size}
                        color={Color.White}
                        textAlign={[TextAlign.Center, TextAlign.Left]}
                        fontFamily={FontFamily.Bold}
                      >
                        {homeConfig.hero.subheader}
                      </SubHeading>
                    </Box>
                    <Space size={4} direction="vertical" />
                  </Box>
                  <Box width="302px">
                    <GetStarted
                      ctaRef={stickyCTARef}
                      isHeroCTA
                      isSticky={isSticky}
                      maxHeight={maxHeight}
                      showBenefits={false}
                    />
                  </Box>
                  <Benefits />
                  <HighlightChoiceImmersive />
                </Col>
              </Grid>
            </ContainerWithGradient>
          </ContainerWithCutout>
        </ContainerWithBackground>
        <GetStarted
          ctaRef={stickyCTARef}
          isHeroCTA={false}
          isSticky={isSticky}
          maxHeight={maxHeight}
        />
      </Box>
    </div>
  )
}

const ImmersiveHeroMemoized = memo(ImmersiveHero)

export { ImmersiveHeroMemoized as ImmersiveHero }
