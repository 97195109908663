import React from 'react'

import {
  Box,
  Body2,
  Icon,
  IconVariant,
  Display,
  Space,
  JustifyContent,
  Color,
} from '@gousto-internal/citrus-react'

import { hero } from '../../homeConfig'

// This component is used as a substitute for the HighlightChoiceBanner in the ImmersiveHero component because of HighlightChoiceBanner styling differences.
export const HighlightChoiceImmersive = () => (
  <Box
    data-testid="HighlightChoiceImmersive"
    display={Display.Flex}
    justifyContent={[JustifyContent.Center, JustifyContent.FlexStart]}
    paddingV={1}
  >
    <Icon name="meals_per_box" size={5} variant={IconVariant.Inherit} />
    <Space size={2} direction="horizontal" />
    <Body2 color={Color.White}>{hero.benefits}</Body2>
  </Box>
)
