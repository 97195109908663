import React, { Fragment } from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  FontWeight,
  JustifyContent,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'
import Svg from 'Svg'

import { contentBlocks, quotes } from 'routes/BoxPrices/boxPricesConfig'

import { BoxPricesQuotationBlock } from './BoxPricesQuotationBlock'

const SvgStyled = styled(Svg)({
  width: '100px',
  height: '100px',
  hidden: false,
  fill: 'transparent',
})

type ContentBlockProps = {
  block: {
    titles: string[]
    texts: string[]
  }
}
export const ContentBlock = ({ block }: ContentBlockProps) => (
  <Box
    height="100%"
    width="100%"
    maxWidth={['80%', '456px']}
    justifyContent={JustifyContent.Center}
    data-testid="boxPricesContentItem"
  >
    {block.titles.map((title, index) => (
      <>
        <Text
          fontWeight={FontWeight.Bold}
          size={5}
          textAlign={TextAlign.Center}
          data-testid="boxPricesContentTitle"
          key={`box-prices-content-${block.titles[0]}`}
        >
          {title}
        </Text>
        <Space size={2} direction="vertical" />
        <Text fontFamily={FontFamily.Book} size={2} textAlign={TextAlign.Center}>
          {block.texts[index]}
        </Text>
        {index !== block.titles.length - 1 && <Space size={6} direction="vertical" />}
        <Space size={2} direction="horizontal" />
      </>
    ))}
  </Box>
)

export const BoxPricesContent = () => {
  const blocks = contentBlocks

  return (
    <Fragment>
      <Box width="100%" bg={Color.White} data-testid="boxPricesContent">
        <Space size={14} direction="vertical" />
        <Box
          display={Display.Flex}
          flexDirection={[FlexDirection.Column, FlexDirection.Column, FlexDirection.Row]}
          justifyContent={JustifyContent.SpaceEvenly}
          alignItems={[AlignItems.Center, AlignItems.Center, AlignItems.FlexStart]}
          paddingBottom={2}
        >
          {blocks.map((contentBlock) => (
            <>
              <ContentBlock
                key={`box-prices-content-${contentBlock.titles[0]}`}
                block={contentBlock}
              />
            </>
          ))}
        </Box>
        <Box
          alignItems={AlignItems.Center}
          maxWidth="100%"
          display={Display.Flex}
          flexDirection={[FlexDirection.Column, FlexDirection.Row]}
          justifyContent={JustifyContent.SpaceEvenly}
        >
          <SvgStyled fileName="icon-hearts" />
        </Box>
      </Box>
      <Box
        maxWidth="100%"
        display={Display.Flex}
        alignItems={AlignItems.Center}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.Center}
        data-testing="boxPricesQuotationBlockContainer"
      >
        <Space size={6} direction="vertical" />
        {quotes.map((quote, index) => (
          <>
            <BoxPricesQuotationBlock key={`box-prices-quote-${quote.source}`} quote={quote} />
            {index === 0 && <Space size={[6, 6, 8]} direction="vertical" />}
          </>
        ))}
      </Box>
    </Fragment>
  )
}
