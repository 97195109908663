export const getHeroDetails = () => ({
  header: 'Box Prices',
  /* eslint-disable-next-line global-require */
  image: require('media/images/box-prices/box-prices-hero.jpg'),
})

export const cta = 'Build my box'

export const boxSizes: Record<string, string[]> = {
  1: ['2', '3', '4', '5'],
  2: ['2', '3', '4', '5'],
  3: ['2', '3', '4', '5'],
  4: ['2', '3', '4', '5'],
  5: ['2', '3'],
}

export const contentBlocks = [
  {
    titles: ['Introducing our Save & Savour range'],
    texts: [
      'Our Save & Savour recipes dish up the same Gousto deliciousness, with a lower price tag at 50p less a portion.',
    ],
  },
  {
    titles: ["Won't be home for delivery?"],
    texts: [
      'No problem. Choose a safe place where we can leave your delivery. Ice and insulation help keep your ingredients cool.',
    ],
  },
]

export const quotes = [
  {
    text: 'Exceptionally high quality ingredients, superb meat, great big portions. No waste - one of the best bits - and a significant saving on our weekly food bill. Super excited when the box arrives each week!',
    source: 'Rachel, Leeds',
  },
  {
    text: 'Our boring meals are once again adventurous! Food bill is reduced as I no longer buy veg and meat that I forget to use. On to our third week of orders now - delicious!',
    source: 'Alison, St Albans',
  },
]

export const seo = {
  title: 'Gousto Prices | Try Our Food Box Delivery Now | Gousto',
  meta: [
    {
      name: 'description',
      content:
        'Find prices on our 2 person or family size food boxes. Get free delivery on any day you like & subscribe for convenient fresh food. Order your first box now!',
    },
    {
      name: 'keywords',
      content: 'Gousto, recipe delivery, price, fresh, healthy food, cooking, recipe box',
    },
  ],
}
// discount per portion for the save and savour range, in pounds
export const saveAndSavourDiscountPerPortion = 0.5
