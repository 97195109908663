import React from 'react'

import { RibbonTriggerContainer } from 'RibbonTrigger'

import { DeliveryDetailsForm } from 'routes/Checkout/Components/DeliveryDetailsForm'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'

type DeliveryProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
  braintreeClientInstance: braintree.Client | null
}

export const Delivery = ({ onStepChange, braintreeClientInstance }: DeliveryProps) => (
  <>
    <DeliveryDetailsForm
      onStepChange={onStepChange}
      braintreeClientInstance={braintreeClientInstance}
    />

    <RibbonTriggerContainer name="checkout-delivery" />
  </>
)
