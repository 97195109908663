/* eslint-disable no-undef */
import { datadogLogs } from '@datadog/browser-logs'

import { Pricing } from 'routes/Menu/domains/pricing'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'
import type { DeliveryDetailsStore } from 'selectors/deliveryDetails'
import { canUseWindow } from 'utils/browserEnvironment'
import { getApplePayMerchantId } from 'utils/isomorphicEnvironment'

export type EventHandler<T, R> = (event: T) => R

export type ApplePaySessionCallbacks = {
  oncancel?: EventHandler<ApplePayJS.Event, void>
  onpaymentauthorized?: EventHandler<ApplePayJS.ApplePayPaymentAuthorizedEvent, void>
  onpaymentmethodselected?: EventHandler<ApplePayJS.ApplePayPaymentMethodSelectedEvent, void>
  onshippingcontactselected?: EventHandler<ApplePayJS.ApplePayShippingContactSelectedEvent, void>
  onshippingmethodselected?: EventHandler<ApplePayJS.ApplePayShippingMethodSelectedEvent, void>
  onvalidatemerchant?: EventHandler<ApplePayJS.ApplePayValidateMerchantEvent, void>
}

const APPLE_PAY_VERSION = 6

export const isApplePayAvailable = async (): Promise<boolean> => {
  if (!canUseWindow()) {
    return false
  }

  const APPLE_PAY_MERCHANT_ID = getApplePayMerchantId()

  const { ApplePaySession } = window
  const isApplePaySupported =
    !!ApplePaySession && ApplePaySession.supportsVersion(APPLE_PAY_VERSION)

  if (!isApplePaySupported) {
    return false
  }

  return ApplePaySession.canMakePaymentsWithActiveCard(APPLE_PAY_MERCHANT_ID)
    .then((canMakePayments: boolean) => canMakePayments)
    .catch(() => false)
}

export const createSignupRequest = (
  state: DeliveryDetailsStore,
  pricing: Pricing,
  deliveryDate: string,
  deliveryTime: string,
): ApplePayJS.ApplePayPaymentRequest | null => {
  const { total, deliveryTotal } = pricing
  if (total === null) {
    return null
  }
  const deliveryInstruction = getDeliveryDetailsInstructions(state)
  const deliveryInstructionCustom = getDeliveryDetailsInstructionsCustom(state)
  const deliveryInstructionLabel =
    deliveryInstruction + (deliveryInstructionCustom ? `: ${deliveryInstructionCustom}` : '')

  return {
    countryCode: 'GB',
    currencyCode: 'GBP',
    supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
    merchantCapabilities: ['supports3DS'],
    total: {
      type: 'final',
      label: 'Total',
      amount: total,
    },
    requiredShippingContactFields: ['name', 'phone', 'postalAddress'],
    shippingType: 'delivery',
    shippingMethods: [
      {
        label: `${deliveryDate} ${deliveryTime}`,
        detail: deliveryInstructionLabel,
        amount: deliveryTotal ?? '0.00',
        identifier: `${deliveryDate} ${deliveryTime}`,
      },
    ],
  }
}

export const createApplePaySession = (
  request: ApplePayJS.ApplePayPaymentRequest | braintree.ApplePayPaymentRequest,
  callbacks: ApplePaySessionCallbacks = {},
): ApplePaySession => {
  const { ApplePaySession } = window

  const session = new ApplePaySession(APPLE_PAY_VERSION, request)
  Object.assign(session, callbacks)

  try {
    session.begin()
  } catch (error) {
    datadogLogs.logger.error(`create apple pay session failed ${JSON.stringify(error)}`)
  }

  return session
}
