import React, { ReactNode } from 'react'

import {
  Box,
  Color,
  FontFamily,
  FontWeight,
  AlignItems,
  Heading3,
  Heading6,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'

type SectionTitleProps = {
  title: string
  subTitle?: ReactNode
  darkMode?: boolean
}

export const SectionTitle = ({ title, subTitle = '', darkMode = false }: SectionTitleProps) => (
  <Box alignItems={AlignItems.Center} paddingBottom={8}>
    <Heading3 color={darkMode ? Color.White : Color.ColdGrey_800} textAlign={TextAlign.Center}>
      {title}
    </Heading3>
    {subTitle && (
      <>
        <Space size={[4, 6]} />
        <Heading6
          color={darkMode ? Color.White : Color.ColdGrey_600}
          fontWeight={FontWeight.Normal}
          fontFamily={FontFamily.Book}
          textAlign={TextAlign.Center}
        >
          {subTitle}
        </Heading6>
      </>
    )}
  </Box>
)
