import React from 'react'

import {
  AlignItems,
  Box,
  Display,
  FlexDirection,
  Heading4,
  Text,
  FontWeight,
  Space,
  Color,
} from '@gousto-internal/citrus-react'

import { SaverRecipeImage } from './SaveAndSavour.styled'

export const SaveAndSavour = () => (
  <Box display={Display.Flex} alignItems={AlignItems.Center} bg={Color.NeutralGrey_50}>
    <Box width="40%">
      <SaverRecipeImage
        maxWidth={['210px', null, null, '450px']}
        height={['300px', null, null, '200px']}
      />
    </Box>
    <Box width="60%" paddingH={4}>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        alignItems={AlignItems.Center}
        style={{ backgroundColor: '#F3C435' }}
        width="110px"
        paddingV={1}
      >
        <Text size={1} fontWeight={FontWeight.Bold}>
          Save & Savour
        </Text>
      </Box>
      <Space size={2} />
      <Heading4>Big flavour for less</Heading4>
      <Space size={2} />
      <Text size={2}>
        Our Save & Savour recipes dish up the same Gousto deliciousness, with a lower price tag at
        50p less a portion.
      </Text>
    </Box>
  </Box>
)
