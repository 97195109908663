import { NumPersonsToBoxDescriptorsList } from '../boxPricesModels'

export enum BoxComparisonHeaderType {
  SAVE_AND_SAVOUR = 'saveAndSavour',
  SELECT_MORE_RECIPES = 'selectMoreRecipes',
  BEST_PRICE = 'bestPrice',
  CLASSIC_GOUSTO = 'classicGousto',
}

export type BoxPriceSuitableForSectionProps = {
  numPersons: number
}

export type BoxPriceBlockProps = {
  boxInfo: NumPersonsToBoxDescriptorsList
  numPersons: number
  isSelected: boolean
}

export type BoxComparisonTableProps = {
  headerType: BoxComparisonHeaderType
  headerValue: string
  pricePerPortion: string
  pricePerBox: string
}

export type BoxComparisonTableHeaderProps = {
  type: BoxComparisonHeaderType
  headerValue: string
}
