import React from 'react'

import {
  Box,
  Display,
  Col,
  DisplayPosition,
  Grid,
  Image,
  JustifyContent,
  Overflow,
  useTheme,
  withCitrusTheme,
  ZIndex,
} from '@gousto-internal/citrus-react'

import { Benefits } from './components/Benefits'
import effortless640webp from '../../assets/EffortlessHomeCookingSection/effortless-640px.webp'
import effortless1024webp from '../../assets/EffortlessHomeCookingSection/effortless-1024px.webp'

import patternNarrowSVG from '../../assets/EffortlessHomeCookingSection/egg-pattern-narrow.svg'
import patternWideSVG from '../../assets/EffortlessHomeCookingSection/egg-pattern-wide.svg'
import menuWebp from '../../assets/EffortlessHomeCookingSection/menu.webp'
import menuPng from '../../assets/EffortlessHomeCookingSection/menu.png'

export type EffortlessHomeCookingSectionProps = {
  signupCTA: string
  onCTAClick: () => void
}

export function EffortlessHomeCookingSection({
  signupCTA,
  onCTAClick,
}: EffortlessHomeCookingSectionProps) {
  const { theme } = useTheme()
  const breakpointFromSmall = `@media (min-width: ${theme.tokens.breakpoints[0]})`
  const breakpointFromMedium = `@media (min-width: ${theme.tokens.breakpoints[1]})`
  const breakpointFromLarge = `@media (min-width: ${theme.tokens.breakpoints[2]})`

  const GridWithBackgroundPattern = withCitrusTheme(Grid, () => ({
    backgroundImage: `url(${patternNarrowSVG})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right -20px bottom 280px',
    [breakpointFromSmall]: { backgroundPosition: 'right -90px' },
    [breakpointFromLarge]: {
      backgroundImage: `url(${patternWideSVG})`,
      backgroundPosition: 'left bottom',
      overflowX: Overflow.Visible,
    },
  }))

  const MainImage = withCitrusTheme(Box, () => ({
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundImage: `url(${effortless640webp})`,
    [breakpointFromSmall]: { backgroundPosition: 'right' },
    [breakpointFromMedium]: { backgroundImage: `url(${effortless1024webp})` },
  }))

  return (
    <section data-testid="effortlessHomeCooking">
      <Box overflowX={Overflow.Hidden}>
        <GridWithBackgroundPattern>
          <Col
            size={[12, null, null, 6]}
            display={Display.Flex}
            paddingV={[14, null, null, 16]}
            paddingBottom={[5, null, null, 16]}
            paddingH={4}
            justifyContent={[null, JustifyContent.Center]}
          >
            <Benefits signupCTA={signupCTA} onCTAClick={onCTAClick} />
          </Col>
          <Col
            size={[12, null, null, 6]}
            position={DisplayPosition.Relative}
            height={['400px', null, null, 'auto']}
          >
            <Box
              position={DisplayPosition.Absolute}
              left={['auto', null, null, '-20%']}
              height={['110%', null, null, '100%']}
              right={['-185px', '-160px', '-70px', 'auto']}
              bottom={'0'}
            >
              <Image
                srcSet={menuWebp}
                src={menuPng}
                alt="Gousto menu on mobile device"
                width="auto"
              />
            </Box>
            <MainImage
              position={DisplayPosition.Absolute}
              zIndex={ZIndex.Lowest}
              width={['100%', '90%', null, '50vw']}
              height="100%"
            />
          </Col>
        </GridWithBackgroundPattern>
      </Box>
    </section>
  )
}
