import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

/**
 * Custom hook to determine if Menu - Social Proof Experiment is
 * enabled
 * https://gousto.atlassian.net/browse/TG-8863
 */

export function useIsMenuSocialProofEnabled() {
  const { isAuthenticated } = useAuth()
  const [decision] = useDecision('beetroots_ofx_menu_social_proof')

  return decision.variationKey === 't1' && !isAuthenticated
}
