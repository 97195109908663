import React from 'react'

import { Box, DisplayPosition, Space } from '@gousto-internal/citrus-react'
import { Carousel } from 'Carousel'
import Immutable from 'immutable'
import { useSelector } from 'react-redux'

import { formatRecipeTitle } from 'utils/recipe'

import { Arrow } from '../CarouselArrow/CarouselArrow'
import { SimpleRecipe } from '../SimpleRecipe/SimpleRecipe'
import { getRecipesFromAllRecipesCollection } from './carouselRecipes'
import { orderRecipes } from './orderRecipes'

export const RecipeCarousel = () => {
  const homeCarouselRecipes = useSelector(getRecipesFromAllRecipesCollection)

  return (
    <Box width="100%" paddingBottom={8} style={{ cursor: 'pointer', marginTop: '-24px' }}>
      <Carousel
        dots={false}
        adaptiveHeight={false}
        speed={400}
        centerMode
        autoplay={false}
        slidesToShow={5}
        slidesToScroll={1}
        infinite
        arrows
        prevArrow={<Arrow side="arrowLeft" />}
        nextArrow={<Arrow side="arrowRight" />}
        focusOnSelect
        swipeToSlide
        responsive={[
          { breakpoint: 640, settings: { slidesToShow: 1 } },
          { breakpoint: 855, settings: { slidesToShow: 2 } },
          { breakpoint: 1280, settings: { slidesToShow: 3 } },
          { breakpoint: 1400, settings: { slidesToShow: 4 } },
          { breakpoint: 1600, settings: { slidesToShow: 5 } },
        ]}
        lazyLoad
      >
        {orderRecipes(homeCarouselRecipes)
          .map((recipe) => (
            <Box key={recipe?.get('id')}>
              <Box
                key={recipe?.get('id')}
                position={DisplayPosition.Relative}
                style={{ zIndex: 1, transition: 'all 0.3s linear', margin: '0 0.5rem' }}
              >
                <Space size={2} direction="horizontal" />
                <SimpleRecipe
                  title={formatRecipeTitle(
                    recipe?.get('title'),
                    recipe?.get('boxType', ''),
                    recipe?.get('dietType', ''),
                  )}
                  media={recipe?.getIn(['media', 'images', 0, 'urls'], Immutable.List([]))}
                  averageRating={recipe?.getIn(['rating', 'average']) || 0}
                  ratingCount={recipe?.getIn(['rating', 'count'])}
                  maxMediaSize={400}
                  cookingTime={recipe?.getIn(['prepTimes', 'for2'])}
                />
              </Box>
            </Box>
          ))
          .toArray()}
      </Carousel>
    </Box>
  )
}
