import type { RecipeListRecipe } from './RecipeList'

export const buildTracker =
  ({
    recipes,
    currentCollectionId,
    track,
  }: {
    recipes: RecipeListRecipe[]
    currentCollectionId: string
    track: any
  }) =>
  () => {
    const recipeIds = recipes.map(({ recipe }) => recipe.get('id'))
    track(currentCollectionId, recipeIds)
  }
