import Immutable from 'immutable'
import React, { SyntheticEvent, useCallback } from 'react'

import styled from '@emotion/styled'
import {
  Box,
  Display,
  DisplayPosition,
  FlexDirection,
  JustifyContent,
  Space,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { CookingTimeText } from '../CookingTimeText'
import { RecipeTilePurchaseInfo } from '../RecipeTilePurchaseInfo'
import { TileImage } from '../TileImage/TileImage'
import { Title } from '../Title'
import {
  cssAddRecipeButtonContainer,
  cssRecipeTagHolder,
  cssRecipeTile,
  cssRecipeTileContainer,
  cssRecipeTileIsBluey,
  cssRecipeTileIsFineDineIn,
} from './styles'
import { AddRecipeButton } from '../AddRecipeButton'
import { DietaryLabels } from '../DietaryLabels'
import { LabelWrapper } from '../Label/LabelWrapper'
import { SoldOutOverlay } from '../SoldOutOverlay'
import { SwapAlternativeOptions } from '../SwapAlternativeOptions/SwapAlternativeOptions'
import { getOverlayColor } from '../../utils/getOverlayColor'
import { CaloriesText } from '../Calories'
import { MenuApiPreferencesResponse } from '@library/menu-service'
import { Recipe } from '../../model/recipe'
import { AlternativeOptions } from '../../model/alternativeOptions/alternativeOptions'
import { RatingText } from '../Rating/RatingText'

const OuterContainer = styled.div(cssRecipeTile)

const TileContainer = styled.div((props: any) => ({
  ...cssRecipeTileContainer,
  ...(props.isFineDineIn ? cssRecipeTileIsFineDineIn : {}),
  ...(props.isBlueyRecipe ? cssRecipeTileIsBluey : {}),
}))

const RecipeTagContainer = styled.span(() => ({
  ...cssRecipeTagHolder,
}))

const AddRecipeButtonContainer = styled.span((props: any) => ({
  ...cssAddRecipeButtonContainer,
  ...(props.isFineDineIn ? cssRecipeTileIsFineDineIn : {}),
  ...(props.isBlueyRecipe ? cssRecipeTileIsBluey : {}),
}))

const ImageContainer = withCitrusTheme(Box, () => ({
  minHeight: '200px',
  maxHeight: '270px',
  position: 'relative',
  overflow: 'hidden',
}))

export type RecipeTileProps = {
  recipe: Recipe
  currentCollectionId: string
  onClick: (recipeId: string) => void
  isMenuSocialProofEnabled: boolean
  menuPreferences?: MenuApiPreferencesResponse
  setBrowserCTAVisible: () => void
  numPortions: number
  canAddRecipes: boolean
  addRecipe: (recipeId: string) => void
  removeRecipe: (recipeId: string) => void
  reachedLimit: boolean
  isRecipeInBasket: (recipeId: string) => boolean
  isRecipeOutOfStock: (recipeId: string) => boolean
  selectVariant: ({
    variantId,
    variantOutOfStock,
    originalRecipeId,
    recipeReference,
  }: {
    variantId: string
    variantOutOfStock: boolean
    originalRecipeId: string
    recipeReference: string
  }) => void
  alternativeOptions: AlternativeOptions
  recipeReference: string
  originalId: string
  dietaryPreferences: Immutable.List<string>
  isMenuPersonalisationEnabled: boolean
}

export const RecipeTile = ({
  recipe,
  currentCollectionId: categoryId,
  onClick,
  isMenuSocialProofEnabled,
  menuPreferences,
  setBrowserCTAVisible,
  numPortions,
  canAddRecipes,
  addRecipe,
  removeRecipe,
  reachedLimit,
  isRecipeInBasket,
  isRecipeOutOfStock,
  selectVariant,
  alternativeOptions,
  recipeReference,
  originalId,
  dietaryPreferences,
  isMenuPersonalisationEnabled,
}: RecipeTileProps) => {
  const { id: recipeId, isBlueyRecipe, isFineDineIn, rating } = recipe
  const isOutOfStock = isRecipeOutOfStock(recipeId)

  const selectRecipeVariant = useCallback(
    ({ variantId, variantOutOfStock }: { variantId: string; variantOutOfStock: boolean }) => {
      selectVariant({
        variantId,
        variantOutOfStock,
        originalRecipeId: originalId,
        recipeReference,
      })
    },
    [originalId, recipeReference, selectVariant],
  )

  // should never happen but caters for loading state
  if (categoryId === null) {
    return null
  }

  // alternative options include the recipe itself
  const hasAlternativeOptions = alternativeOptions.length > 1

  const handleOnClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClick(recipeId)
  }

  const handleOnClickRecipeButtonContainer = (e: SyntheticEvent) => {
    // Prevents opening recipe details modal when clicking on disabled add button
    if (isOutOfStock) e.stopPropagation()
  }

  // Show rating if Menu - social proof experiment is enabled and there are more than 50 reviews
  const shouldShowRating = rating && isMenuSocialProofEnabled && rating.count > 50

  return (
    <OuterContainer
      role="button"
      tabIndex={0}
      data-testing={isOutOfStock ? 'menuRecipeOutOfStock' : 'menuRecipeViewDetails'}
      data-testing-id={recipeId}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
    >
      <TileContainer isBlueyRecipe={isBlueyRecipe} isFineDineIn={isFineDineIn}>
        <ImageContainer>
          <SoldOutOverlay isRecipeOutOfStock={isRecipeOutOfStock} recipe={recipe} />
          <TileImage recipe={recipe} />
          <RecipeTagContainer>
            <DietaryLabels recipe={recipe} />
          </RecipeTagContainer>
        </ImageContainer>

        <Box position={DisplayPosition.Relative} flexGrow={1} style={{ margin: '1rem' }}>
          <Box display={Display.Flex}>
            <LabelWrapper
              isRecipeOutOfStock={isRecipeOutOfStock}
              recipe={recipe}
              dietaryPreferences={dietaryPreferences}
              isMenuPersonalisationEnabled={isMenuPersonalisationEnabled}
            />
            <AddRecipeButtonContainer
              isBlueyRecipe={isBlueyRecipe}
              isFineDineIn={isFineDineIn}
              isSoldOut={isOutOfStock}
              onClick={handleOnClickRecipeButtonContainer}
            >
              <AddRecipeButton
                recipeId={recipeId}
                setBrowserCTAVisible={setBrowserCTAVisible}
                canAddRecipes={canAddRecipes}
                addRecipe={addRecipe}
                removeRecipe={removeRecipe}
                reachedLimit={reachedLimit}
                isRecipeInBasket={isRecipeInBasket}
                isRecipeOutOfStock={isRecipeOutOfStock}
              />
            </AddRecipeButtonContainer>
          </Box>
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.SpaceBetween}
            height="100%"
            position={DisplayPosition.Relative}
          >
            <SoldOutOverlay
              backgroundColor={getOverlayColor(recipe)}
              isRecipeOutOfStock={isRecipeOutOfStock}
              recipe={recipe}
            />
            <Box>
              <Title title={recipe.title} />
              <Space size={2}></Space>
              <Box display={Display.Flex} wrap>
                <CookingTimeText numPortions={numPortions} recipe={recipe} />
                {(!menuPreferences || menuPreferences?.display_calories) && (
                  <CaloriesText numPortions={numPortions} recipe={recipe} />
                )}
                {shouldShowRating && (
                  <RatingText
                    rating={rating.average}
                    numReviews={rating.count}
                    isFineDineIn={isFineDineIn}
                  />
                )}
              </Box>
            </Box>
            <RecipeTilePurchaseInfo
              isRecipeOutOfStock={isRecipeOutOfStock}
              numPortions={numPortions}
              recipe={recipe}
            />
          </Box>
          {hasAlternativeOptions && (
            <Box position={DisplayPosition.Absolute} bottom="0" right="0" width="100%">
              <SwapAlternativeOptions
                recipeId={recipeId}
                categoryId={categoryId}
                recipe={recipe}
                selectVariant={selectRecipeVariant}
                alternativeOptions={alternativeOptions}
              />
            </Box>
          )}
        </Box>
      </TileContainer>
    </OuterContainer>
  )
}
