import React from 'react'

import { useTheme } from '@gousto-internal/citrus-react'

import { Picture } from '../../Picture'
import DesktopLargeBrandDish from '../assets/desktop-large-gousto-dish.jpg'
import DesktopLargeBrandDishWebp from '../assets/desktop-large-gousto-dish.webp'
import MobileBrand from '../assets/mobile-gousto-dish.jpg'
import MobileBrandWebp from '../assets/mobile-gousto-dish.webp'
import TabletDesktopBrandDish from '../assets/tablet-desktop-gousto-dish.jpg'
import TabletDesktopBrandDishWebp from '../assets/tablet-desktop-gousto-dish.webp'
import { HeroImageElement } from './HeroImageStyled'

export const HeroImage = () => {
  const { theme } = useTheme()

  const breakpointFromMedium = theme.tokens.breakpoints[0]
  const breakpointFromLarge = theme.tokens.breakpoints[1]

  const fromMediumWidth: number = +breakpointFromMedium.split('px')[0]
  const fromLargeWidth: number = +breakpointFromLarge.split('px')[0]

  const BreakpointToMedium = `(max-width: ${fromMediumWidth - 1}px)`
  const BreakpointFromMedium = `(min-width: ${breakpointFromMedium})`
  const BreakpointFromLarge = `(min-width: ${breakpointFromLarge})`

  return (
    <HeroImageElement data-testid="pictureElementWrapper">
      <Picture
        sourcesMatrix={[
          [
            {
              srcSet: MobileBrandWebp,
              media: BreakpointToMedium,
              type: 'image/webp',
            },
            {
              srcSet: MobileBrand,
              media: BreakpointToMedium,
            },
          ],
          [
            {
              srcSet: TabletDesktopBrandDishWebp,
              media: BreakpointFromMedium,
              type: 'image/webp',
            },
            {
              srcSet: TabletDesktopBrandDish,
              media: BreakpointFromMedium,
            },
          ],
          [
            {
              srcSet: DesktopLargeBrandDishWebp,
              media: BreakpointFromLarge,
              type: 'image/webp',
            },
            {
              srcSet: DesktopLargeBrandDish,
              media: BreakpointFromLarge,
            },
          ],
        ]}
        image={{
          srcSet: `${MobileBrand}, ${TabletDesktopBrandDish} ${fromMediumWidth}w, ${DesktopLargeBrandDish} ${fromLargeWidth}w`,
          src: TabletDesktopBrandDish,
          alt: '',
        }}
      />
    </HeroImageElement>
  )
}
