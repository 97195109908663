export const getRecipeTitle = (recipe) => recipe?.get('title')

export const getRecipeID = (recipe) => recipe?.get('id')

export const getRecipeTitleFromMenuApiRecipe = (recipe) => recipe.getIn(['attributes', 'name'])

export const getRecipeIDFromMenuApiRecipe = (recipe) =>
  recipe?.getIn(['attributes', 'core_recipe_id'])

export const getRecipeImagesFromMenuApiRecipe = (recipe) =>
  recipe?.getIn(['attributes', 'images', 0, 'crops'])

export const getRecipeImages = (recipe) => recipe?.getIn(['media', 'images', 0, 'urls'])

export const getRecipeById = ({ recipes }, recipeId) => recipes?.get(recipeId, null)
