import React, { SyntheticEvent, useRef, useState } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { incrementTutorialViewed, tutorialTracking } from 'actions/tutorial'

import { useClickOutside } from '../RecipeTile/SwapAlternativeOptions/useClickOutside'
import { CALORIES_TUTORIAL_NAME } from './CaloriesTutorial'
import { MenuPreferencesDesktop } from './MenuPreferencesDesktop'
import { MenuPreferencesMobile } from './MenuPreferencesMobile'

type MenuPreferencesProps = {
  variant: 'desktop' | 'mobile'
}

export const MenuPreferences = ({ variant }: MenuPreferencesProps) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const onClose = (event?: SyntheticEvent) => {
    event?.stopPropagation()
    if (!isOpen) return
    dispatch(incrementTutorialViewed(CALORIES_TUTORIAL_NAME))
    dispatch(tutorialTracking(CALORIES_TUTORIAL_NAME, 0, true))
    setIsOpen(false)
  }

  const selectRef = useRef(null)
  useClickOutside(selectRef, onClose, [isOpen])

  const onClickButton = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <Box
      display={Display.Flex}
      justifyContent={JustifyContent.FlexEnd}
      ref={variant === 'desktop' ? selectRef : null}
      style={{ marginLeft: '1rem' }}
    >
      <Box
        alignItems={AlignItems.Center}
        color={isOpen ? Color.Secondary_400 : Color.ColdGrey_800}
        display={Display.Flex}
        onClick={onClickButton}
        onKeyDown={onClickButton}
        role="button"
        tabIndex={-1}
      >
        {variant === 'desktop' && (
          <>
            <Text size={2}>Menu Settings</Text>
            <Space size={1} direction="horizontal" />
          </>
        )}
        <Icon
          name="settings"
          size={5}
          variant={isOpen ? IconVariant.Actionable : IconVariant.Default}
        >
          Menu settings
        </Icon>
      </Box>
      {isOpen &&
        (variant === 'mobile' ? (
          <MenuPreferencesMobile onClose={onClose} />
        ) : (
          <MenuPreferencesDesktop onClose={onClose} />
        ))}
    </Box>
  )
}
