import React from 'react'

import {
  Space,
  Box,
  Icon,
  Text,
  Bold,
  AlignItems,
  Color,
  BorderStyle,
  Display,
  IconVariant,
} from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

import Content from 'containers/Content'
import humanTimeFormat from 'utils/timeFormat'

import css from './OrderStatus.css'

const OrderStatus = ({
  restorable,
  orderState,
  isProjected,
  whenCutoff,
  whenMenuOpen,
  boxSize,
}) => {
  if (restorable && orderState === 'cancelled' && !isProjected) {
    return (
      <div>
        <Space size={2} />
        <p className={css.orderStatus}>
          <Content contentKeys="myDeliveriesOrderOrderStatusCancelled">
            <span>
              {`Restore anytime before 12:00pm, ${humanTimeFormat(whenCutoff, 'dayAndMonth')}`}
            </span>
          </Content>
        </p>
      </div>
    )
  }

  if (restorable) {
    return null
  }

  const whenCutoffFormatted = whenCutoff ? humanTimeFormat(whenCutoff, 'timeLeft') : 'No time'

  return (
    <div>
      {orderState === 'scheduled' ? (
        <p className={css.orderStatus}>
          {`Menu open ${humanTimeFormat(whenMenuOpen, 'hourAndDay')}`}
        </p>
      ) : null}
      {orderState === 'menu open' ? (
        <p className={css.orderStatus}>{`${whenCutoffFormatted} left to choose recipes`}</p>
      ) : null}
      {orderState === 'recipes chosen' ? (
        <>
          <p className={css.orderStatus}>{`${whenCutoffFormatted} left to edit this order`}</p>
          <Box
            display={Display.Flex}
            bg={Color.Informative_50}
            borderColor={Color.Informative_200}
            width="100%"
            paddingV={3}
            paddingH={3}
            borderStyle={BorderStyle.Solid}
            borderWidth={0.5}
            borderRadius={1.5}
            data-testid="box-size-info-panel"
            alignItems={AlignItems.Center}
          >
            <Icon name="info" size={5} variant={IconVariant.Informative} />
            <Space direction="horizontal" size={2} />
            <Text size={1}>
              <Bold>{`${boxSize}-person box. `}</Bold>
              Edit this order to change size.
            </Text>
          </Box>
          <Space size={2} />
        </>
      ) : null}
      {orderState === 'confirmed' ? <Space size={2} /> : null}
      {orderState === 'dispatched' ? (
        <p className={css.orderStatus}>
          <Content contentKeys="myDeliveriesOrderOrderStatusDispatched">
            <span>This order is out for delivery</span>
          </Content>
        </p>
      ) : null}
      {orderState === 'cancelled' ? (
        <p className={css.orderStatus}>
          <Content contentKeys="myDeliveriesOrderOrderStatusCancelled">
            <span>You cannot restore this order. Please add a new one.</span>
          </Content>
        </p>
      ) : null}
    </div>
  )
}

OrderStatus.propTypes = {
  orderState: PropTypes.string,
  whenCutoff: PropTypes.string,
  whenMenuOpen: PropTypes.string,
  isProjected: PropTypes.bool.isRequired,
  restorable: PropTypes.bool.isRequired,
  boxSize: PropTypes.string,
}

OrderStatus.defaultProps = {
  orderState: '',
  whenCutoff: '',
  whenMenuOpen: '',
  boxSize: '',
}

export default OrderStatus
