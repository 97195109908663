import React from 'react'

import { Crop, MenuRecipeImage } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { Carousel as SlickCarousel } from 'components/Carousel'

import { DetailImage } from '../DetailImage'
import { DetailViewType } from '../types'
import { Arrow } from './Arrow'
import { ContentMask } from './ContentMask'

import css from './Carousel.css'

type DetailCarouselProps = {
  images: MenuRecipeImage[]
  isBlueyRecipe: boolean
  view?: DetailViewType
  dots?: boolean
  arrows?: boolean
  fallbackImage: Crop[]
}

export const DetailCarousel = ({
  images = [],
  isBlueyRecipe,
  view = 'fineDineInDetail',
  dots = true,
  arrows = true,
  fallbackImage,
}: DetailCarouselProps) =>
  images.length ? (
    <div className={css.carousel}>
      <SlickCarousel
        dots={dots}
        dotsClass={isBlueyRecipe ? css['bluey-dots'] : css.dots}
        arrows={arrows}
        prevArrow={<Arrow direction="left" />}
        nextArrow={<Arrow direction="right" />}
        infinite
        autoplaySpeed={5000}
        speed={200}
      >
        {images.map((image) => (
          <div className={css.slide} key={image.type}>
            <ContentMask className={css.mask} isBlueyRecipe={isBlueyRecipe}>
              <p className={css.imageTitle}>{image.title}</p>
              <p className={css.imageDescription}>{image.description}</p>
            </ContentMask>
            <DetailImage media={image.crops} title={image.title} view={view} lazy={false} />
          </div>
        ))}
      </SlickCarousel>
    </div>
  ) : (
    <DetailImage media={fallbackImage} lazy={false} />
  )
