import { basketOrderLoad } from 'actions/basket'
import {
  productsLoadCategories,
  productsLoadProducts,
  productsLoadStock
} from 'actions/products'
import config from 'config/routes'
import Immutable from 'immutable'
import { push } from 'react-router-redux'
import * as orderV2 from 'routes/Menu/apis/orderV2'
import logger from 'utils/logger'
import { getUserOrderRecipeUuIds } from 'utils/user'
import { invokeHotjarEvent } from 'utils/hotjarUtils'
import { actionTypes } from './actionTypes'
import { orderCheckPossibleDuplicate } from './order'
import recipeActions from './recipes'

export const orderConfirmationRedirect = (orderId, orderAction) => (dispatch) => {
  const confirmationUrl = config.client.orderConfirmation.replace(':orderId', orderId)
  dispatch(orderCheckPossibleDuplicate(orderId))
  dispatch(push(orderAction ? `${confirmationUrl}?order_action=${orderAction}` : confirmationUrl))
}

export const orderDetails = (orderId, addRecipe) => async (dispatch, getState) => {
  const FIVE_RECIPES_ORDER_HOTJAR_SURVEY_NAME = 'menu_5recipes_order_confirmation'

  try {
    dispatch(productsLoadCategories())
    dispatch(productsLoadStock())
    const include = 'shipping_address'
    const { data: order } = await orderV2.fetchOrder(dispatch, getState, orderId, include)
    const immutableOrderDetails = Immutable.fromJS(order)
    const orderRecipeUuIds = getUserOrderRecipeUuIds(immutableOrderDetails)
    dispatch(recipeActions.recipesLoadFromMenuRecipesById(orderRecipeUuIds))
    await dispatch(productsLoadProducts(order.whenCutoff, order.periodId, { reload: true }))

    const isFiveRecipesOrder = immutableOrderDetails && immutableOrderDetails.get('recipeItems').size > 4

    if (isFiveRecipesOrder) {
      invokeHotjarEvent(FIVE_RECIPES_ORDER_HOTJAR_SURVEY_NAME)
    }

    dispatch(basketOrderLoad(orderId, addRecipe, immutableOrderDetails))
    dispatch({
      type: actionTypes.BASKET_ORDER_DETAILS_LOADED,
      orderId,
      orderDetails: immutableOrderDetails,
    })
  } catch (err) {
    logger.error({ message: 'Error loading order data', errors: [err] })
  }
}

export const orderConfirmationProductTracking = (trackingData) => (dispatch) => {
  dispatch({
    type: actionTypes.BASKET_PRODUCT_TRACKING,
    trackingData: { actionType: trackingData.eventName, trackingData },
  })
}

export const orderConfirmationUpdateOrderTracking = () => (dispatch, getState) => {
  const { basket, user } = getState()
  const orderId = basket.get('orderId')
  const basketOrderDetails = basket.get('orderDetails')
  const prices = basketOrderDetails.get('prices')
  const orderTotal = prices.get('total')
  const promoCode = prices.get('promoCode')
  const subscription = user.get('subscription')
  const subscriptionActive = subscription.get('state') === 'active'

  dispatch({
    type: actionTypes.ORDER_CONFIRMATION_EDITED_TRACKING,
    trackingData: {
      actionType: 'Order Edited',
      order_id: orderId,
      order_total: orderTotal,
      promo_code: promoCode,
      signup: false,
      subscription_active: subscriptionActive,
    },
  })
}
