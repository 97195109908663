// FYI: This file should be removed after home page refactor
// https://gousto.atlassian.net/browse/TG-6987
import React from 'react'

import { Carousel } from './Carousel'
import { useGetCarouselData } from './carouselHooks'

export const CarouselContainer = () => {
  const { numRecipes, ctaUri, ctaText } = useGetCarouselData()

  return <Carousel numRecipes={numRecipes} ctaUri={ctaUri} ctaText={ctaText} />
}
