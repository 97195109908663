import { Color } from '@gousto-internal/citrus-react'
import { colors } from '../styles'
import { Recipe } from '../model/recipe'

export const getOverlayColor = (recipe: Recipe) => {
  const { isFineDineIn, isBlueyRecipe } = recipe

  if (isFineDineIn) return Color.ColdGrey_800

  if (isBlueyRecipe) return colors.bluey

  return Color.White
}
