import { datadogLogs } from '@datadog/browser-logs'
import { updateBraintreeCustomerPaymentDetails } from '@library/api-payment'
import { fetchCurrentUser } from '@library/api-user'
import Cookies from 'cookies-js'
import { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import { ApiKeys, DataFrame } from '../../../enums'

declare const braintree: typeof import('braintree-web')

export enum BraintreeCardErrorMessages {
  CUSTOMER_CANCELLED_THREEDSECURE = 'Please try again and complete the security check through your bank',
  CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE = 'Please use a different card. If you’re still experiencing difficulties, contact your bank.',
  CUSTOMER_INVALID_CARD_DETAILS = 'Try again or use a different card. If you’re still experiencing difficulties, contact your bank.',
  CUSTOMER_BANK_DECLINED_UPDATE = 'Use a different card. If you’re still experiencing difficulties, contact your bank or try again later.',
}

const initialState = {
  errorStatus: {
    showError: false,
    isEmpty: true,
  },
  isFormValid: false,
  isFormSubmitting: false,
  isUpdateSuccess: false,
}

const hostedFieldsOptions = {
  number: {
    container: `#${DataFrame.CARD_NUMBER}`,
    formatInput: true,
  },
  expirationDate: {
    container: `#${DataFrame.EXPIRY_DATE}`,
  },
  cvv: {
    container: `#${DataFrame.CVV}`,
  },
}

const hostedFieldStyles = {
  input: {
    color: '#516272',
    'font-family': 'AxiformaBook, Helvetica, sans-serif',
    'font-size': '16px',
    'line-height': '20px',
  },
}
type useHostedFieldsFrameProps = {
  braintreeClientInstance: braintree.Client | null | undefined
  deviceData: string | null
}

export const useHostedFieldsFrame = ({
  braintreeClientInstance,
  deviceData,
}: useHostedFieldsFrameProps) => {
  const { data } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const isPaymentUpdateBraintree = true
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<braintree.HostedFields | null>(
    null,
  )
  const [threeDSecureInstance, setThreeDSecureInstance] = useState<braintree.ThreeDSecure | null>(
    null,
  )
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(initialState.isUpdateSuccess)
  const [inputCardNumberError, setInputCardNumberError] = useState(initialState.errorStatus)
  const [inputExpiryDateError, setInputExpiryDateError] = useState(initialState.errorStatus)
  const [inputCvvError, setInputCvvError] = useState(initialState.errorStatus)
  const [isFormValid, setIsFormValid] = useState<boolean>(initialState.isFormValid)
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(initialState.isFormSubmitting)
  const [sessionId, setSessionId] = useState('')
  const [braintreeCardConfirmationError, setCardConfirmationError] = useState('')
  const [fieldsCleared, setFieldsCleared] = useState(false)
  const [lastFourDigits, setLastFourDigits] = useState('')

  const resetFields = () => {
    if (!hostedFieldsInstance) return
    hostedFieldsInstance.clear('number')
    hostedFieldsInstance.clear('cvv')
    hostedFieldsInstance.clear('expirationDate')
    setInputCardNumberError(initialState.errorStatus)
    setInputExpiryDateError(initialState.errorStatus)
    setInputCvvError(initialState.errorStatus)
    setIsFormSubmitting(initialState.isFormSubmitting)
    setIsFormValid(initialState.isFormValid)
    setFieldsCleared(true)
  }

  const handleCustomerCancelledThreeDSecure = useCallback(() => {
    if (!threeDSecureInstance) return
    threeDSecureInstance.cancelVerifyCard(() =>
      setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_CANCELLED_THREEDSECURE),
    )
  }, [threeDSecureInstance])

  const handleLookUpComplete = useCallback(
    (data, next) => {
      if (data?.requiresUserAuthentication || data?.threeDSecureInfo?.liabilityShifted) {
        next()
      } else {
        setIsFormSubmitting(false)
        threeDSecureInstance?.cancelVerifyCard(() => {
          setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE)
        })
      }
    },
    [threeDSecureInstance],
  )

  // when user submits the form
  const handleCardChange = async () => {
    if (!hostedFieldsInstance || !threeDSecureInstance) return
    setCardConfirmationError('')
    setIsUpdateSuccessful(false)

    try {
      setIsFormSubmitting(true)
      const payload = await hostedFieldsInstance.tokenize()

      threeDSecureInstance.on('lookup-complete', handleLookUpComplete)

      threeDSecureInstance.on('customer-canceled', handleCustomerCancelledThreeDSecure)

      const result = await threeDSecureInstance.verifyCard({
        nonce: payload.nonce,
        amount: '0.0' as any,
        challengeRequested: true,
        bin: payload.details.bin,
        additionalInformation: {
          acsWindowSize: '03',
        },
      })

      if (result.threeDSecureInfo.liabilityShiftPossible) {
        if (result.threeDSecureInfo.liabilityShifted) {
          try {
            if (!data || !deviceData) return
            const response = await updateBraintreeCustomerPaymentDetails({
              body: {
                user_id: data.user.id,
                gousto_ref: data.user.goustoReference,
                card_token: result.nonce,
                user_flow: 'card-change',
                device_data: deviceData,
              },
              query: {
                session_id: sessionId,
              },
            })
            if (response.status === 'authorised' || response.status === 'succeeded') {
              setIsUpdateSuccessful(true)
              setLastFourDigits(response.responsePayload.cardDetails.last4)
              datadogLogs.logger.info('user updated card successfully')
            } else {
              setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_BANK_DECLINED_UPDATE)
            }
          } catch (error) {
            setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_BANK_DECLINED_UPDATE)
            datadogLogs.logger.error(`update payment request failed with ${error}`)
          }
        } else {
          setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_BANK_DECLINED_UPDATE)
          datadogLogs.logger.error(
            `liabilityShiftPossible failed ${result.threeDSecureInfo.liabilityShiftPossible}`,
          )
        }
      } else {
        setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_INVALID_CARD_DETAILS)
        datadogLogs.logger.error(
          `liabilityShifted failed ${result.threeDSecureInfo.liabilityShifted}`,
        )
      }
    } catch (error) {
      setCardConfirmationError(BraintreeCardErrorMessages.CUSTOMER_INVALID_CARD_DETAILS)
      datadogLogs.logger.error(`update card failed with ${JSON.stringify(error)}`)
    } finally {
      setIsFormSubmitting(false)
    }
  }

  // when user clicks submit without filling form details
  const handleCardDetailsValidation = () => {
    if (inputCardNumberError.isEmpty) {
      setInputCardNumberError({ ...inputCardNumberError, showError: true })
    }
    if (inputCvvError.isEmpty) {
      setInputCvvError({ ...inputCvvError, showError: true })
    }
    if (inputExpiryDateError.isEmpty) {
      setInputExpiryDateError({
        ...inputExpiryDateError,
        showError: true,
      })
    }
  }

  const handleOnValidityChanged = useCallback((event: any) => {
    const errorField = event.emittedBy
    const formValid = Object.keys(event.fields).every((key) => event.fields[key].isValid)
    setIsFormValid(formValid)

    const { isEmpty, isValid } = event.fields[errorField]
    switch (event.emittedBy) {
      case 'number':
        return setInputCardNumberError({
          showError: !isValid,
          isEmpty,
        })
      case 'expirationDate':
        return setInputExpiryDateError({
          showError: !isValid,
          isEmpty,
        })
      case 'cvv':
        return setInputCvvError({ showError: !isValid, isEmpty })
      default:
        return undefined
    }
  }, [])

  const initThreeDSecure = useCallback(async () => {
    const createThreeDSecureInstance = async () => {
      if (!braintreeClientInstance) return
      const threeDSecure = await braintree.threeDSecure.create({
        client: braintreeClientInstance,
        version: 2,
      })
      setThreeDSecureInstance(threeDSecure)
    }
    try {
      await createThreeDSecureInstance()
    } catch (error) {
      datadogLogs.logger.error(`update threeDSecureInstance failed with ${JSON.stringify(error)}`)
    }
  }, [braintreeClientInstance])

  const initHostedFields = useCallback(async () => {
    const createHostedFieldInstance = async () => {
      if (!braintreeClientInstance) return
      const hostedField = await braintree.hostedFields.create({
        client: braintreeClientInstance,
        fields: hostedFieldsOptions,
        styles: hostedFieldStyles,
      })
      setHostedFieldsInstance(hostedField)
    }
    try {
      await createHostedFieldInstance()
    } catch (error) {
      datadogLogs.logger.error(`update hostedFieldInstance failed with ${JSON.stringify(error)}`)
    }
  }, [braintreeClientInstance])

  const initCardDetailsValidation = useCallback(async () => {
    if (!hostedFieldsInstance) return
    if (fieldsCleared) {
      // If the fields are being cleared, remove the event listener
      hostedFieldsInstance.off('validityChange', handleOnValidityChanged)
      setFieldsCleared(false) // Reset fieldsCleared to false
    } else {
      hostedFieldsInstance.on('validityChange', handleOnValidityChanged)
      hostedFieldsInstance.on('blur', handleOnValidityChanged)
    }
  }, [hostedFieldsInstance, fieldsCleared, handleOnValidityChanged])

  // to initalise hostedfields client
  useEffect(() => {
    if (braintreeClientInstance && isPaymentUpdateBraintree) {
      initHostedFields()
    }
  }, [braintreeClientInstance, initHostedFields, isPaymentUpdateBraintree])

  // to initalise 3DSecure
  useEffect(() => {
    if (braintreeClientInstance) {
      initThreeDSecure()
    }
  }, [braintreeClientInstance, initThreeDSecure])

  // do card verification
  useEffect(() => {
    if (hostedFieldsInstance) {
      initCardDetailsValidation()
    }
  }, [hostedFieldsInstance, initCardDetailsValidation])

  useEffect(() => {
    const sessionIdenifier = Cookies.get('gousto_session_id')
    setSessionId(sessionIdenifier)
  }, [])

  return {
    braintreeFormValidationError: {
      showCardNumberError: inputCardNumberError.showError,
      showCVVError: inputCvvError.showError,
      showExpiryDateError: inputExpiryDateError.showError,
    },
    isFormValid,
    handleCardDetailsValidation,
    handleOnValidityChanged,
    setHostedFieldsInstance,
    setThreeDSecureInstance,
    handleCardChange,
    isFormSubmitting,
    handleLookUpComplete,
    handleCustomerCancelledThreeDSecure,
    isUpdateSuccessful,
    isHostedFieldsReady: !!hostedFieldsInstance,
    braintreeCardConfirmationError,
    resetFields,
    lastFourDigits,
  }
}
