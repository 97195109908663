import { CSSObject } from '@emotion/styled'
import { Heading2, Heading2Props, withCitrusTheme } from '@gousto-internal/citrus-react'

type TitleProps = Heading2Props & {
  isFineDineIn: boolean
}

export const Title = withCitrusTheme<TitleProps>(Heading2, (theme, props) => ({
  ...(theme.responsiveFont([6, null, 7, 10]) as CSSObject),
  maxWidth: '90%',
  fontFamily: props.isFineDineIn ? theme.tokens.FontFamilies.book : theme.tokens.FontFamilies.bold,
}))
