import styled from '@emotion/styled'

interface BreakpointProps {
  BreakpointFromExtraSmall: string
  BreakpointFromSmall: string
  BreakpointFromMedium: string
  BreakpointFromLarge: string
}

export const Container = styled.div(
  ({
    BreakpointFromMedium,
    BreakpointFromLarge,
  }: Omit<BreakpointProps, 'BreakpointFromExtraSmall' | 'BreakpointFromSmall'>) => ({
    padding: '0.75rem 0.75rem 0',
    width: '100%',
    maxWidth: '75rem',
    margin: '0 auto 1rem',
    display: 'flex',
    flexDirection: 'column-reverse',

    [BreakpointFromMedium]: {
      flexDirection: 'row',
      padding: '1rem 2rem 1rem 2rem',
      marginBottom: 0,
    },

    [BreakpointFromLarge]: {
      padding: '2.5rem 2rem 2.5rem 2rem',
    },
  }),
)

export const TextContainer = styled.div(
  ({
    BreakpointFromMedium,
    BreakpointFromLarge,
  }: Omit<BreakpointProps, 'BreakpointFromExtraSmall' | 'BreakpointFromSmall'>) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    textAlign: 'center',

    [BreakpointFromMedium]: {
      marginRight: '1.5rem',
      textAlign: 'left',
      maxWidth: '27rem',
      minWidth: '20rem',
    },

    [BreakpointFromLarge]: {
      maxWidth: '35rem',
      minWidth: '27rem',
    },
  }),
)

export const Title = styled.div(
  ({
    BreakpointFromExtraSmall,
    BreakpointFromSmall,
    BreakpointFromMedium,
    BreakpointFromLarge,
  }: BreakpointProps) => ({
    marginBottom: '0.5rem',
    marginRight: '.25rem',

    [BreakpointFromExtraSmall]: {
      h1: {
        fontSize: '28px',
        lineHeight: '32px',
      },
    },

    [BreakpointFromSmall]: {
      h1: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },

    [BreakpointFromMedium]: {
      marginBottom: '1rem',
      maxWidth: '22.5rem',
      h1: {
        fontSize: '36px',
      },
    },

    [BreakpointFromLarge]: {
      maxWidth: '32rem',
      h1: {
        fontSize: '44px',
      },
    },
  }),
)

export const SubTitle = styled.div(
  ({
    BreakpointFromExtraSmall,
    BreakpointFromMedium,
    BreakpointFromLarge,
  }: Omit<BreakpointProps, 'BreakpointFromSmall'>) => ({
    marginBottom: '1rem',

    [BreakpointFromExtraSmall]: {
      h2: {
        fontSize: '20px',
      },
    },

    [BreakpointFromMedium]: {
      h2: {
        fontSize: '24px',
      },
    },

    [BreakpointFromLarge]: {
      h2: {
        fontSize: '32px',
        paddingRight: '32px',
      },
    },
  }),
)
