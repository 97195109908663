import React from 'react'

import { Box, Button, Display, FlexDirection, Visible } from '@gousto-internal/citrus-react'

import { HomepageSections, TrackingActions, trackCTAClick } from '../../../tracking'

type CTABlockProps = {
  signupCTA: string
  onCTAClick: () => void
}

export const CTABlock = ({ signupCTA, onCTAClick }: CTABlockProps) => {
  const signupCTAClick = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, HomepageSections.ENVIRONMENTAL_BENEFITS)
  }

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Row}>
      <Box minWidth="280px">
        <Visible display={[Display.None, Display.Block, Display.Block]}>
          <Button onClick={signupCTAClick} width="100%" height="48px">
            {signupCTA}
          </Button>
        </Visible>
      </Box>
    </Box>
  )
}
