import { client } from 'config/routes'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { useAuth } from 'routes/Menu/domains/auth'

import { CtaTexts } from './data'

/**
 * Used to get cta text & uri based on condition
 * if user logged in or not
 */

export const useGetCtaProps = ({ withDiscount = false }: { withDiscount?: boolean } = {}) => {
  const { isAuthenticated } = useAuth()
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()

  const { menu, signup } = client

  const dynamicCtaText = () =>
    withDiscount ? CtaTexts.GET_STARTED_WITH_DISCOUNT : CtaTexts.GET_STARTED

  const dynamicBuildBoxCtaText = () =>
    withDiscount ? CtaTexts.BUILD_BOX_WITH_DISCOUNT : CtaTexts.BUILD_BOX

  const ctaText = isSkipTheWizardEnabled ? dynamicBuildBoxCtaText() : dynamicCtaText()

  const ctaURI = isSkipTheWizardEnabled ? menu : signup

  if (!isAuthenticated) {
    return {
      ctaUri: ctaURI,
      ctaText,
    }
  }

  return {
    ctaUri: menu,
    ctaText: CtaTexts.OPEN_MENU,
  }
}
