import React from 'react'

import styled from '@emotion/styled'
import {
  Box,
  Icon,
  Image,
  IconVariant,
  Space,
  Heading3,
  FlexDirection,
  Display,
  AlignItems,
  JustifyContent,
} from '@gousto-internal/citrus-react'

import { STEPS_LIST } from '../data'

const StepListItem = styled.li({
  marginBottom: '0.5rem',
  listStyle: 'none',
  display: 'flex',
  font: '15px/24px AxiformaBook, Helvetica, sans-serif',
  ':last-child': {
    marginBottom: '0',
  },
})

const UnorderedList = styled.ul({
  margin: '0',
  padding: '0',
  textAlign: 'left',
})
interface StepProps {
  title: string
  img: string
  list: string[]
  index: number
}

interface ListItemProps {
  listItem: string
  index: number
}

const ListItem = ({ listItem, index }: ListItemProps) => (
  <StepListItem key={index} title={listItem}>
    <Icon name="tick" variant={IconVariant.Confirmation} />
    <Space size={3} direction="horizontal" />
    {listItem}
  </StepListItem>
)

const Step = ({ title, img, list, index }: StepProps) => (
  <>
    <Box
      maxWidth={['25rem', '17.5rem', '22.5rem']}
      width={['100%', '32%']}
      minWidth="11rem"
      key={index}
    >
      <Image width="100%" minHeight={['6.5rem']} src={img} alt="" data-testid={title} />
      <Space size={4} />
      <Heading3 size={[5, 6, 7]}>{title}</Heading3>
      <Space size={[1, 0.75, 1]} direction="vertical" />
      <Space size={[1, 0.75, 1]} direction="vertical" />
      <Space size={[0, 0, 0.25]} direction="vertical" />
      <UnorderedList>
        {list.map((listItem: string, itemIndex: number) =>
          ListItem({ listItem, index: itemIndex }),
        )}
        <Space direction="vertical" size={[4, 0]} />
      </UnorderedList>
    </Box>
  </>
)

export const StepsGuide = () => (
  <>
    <Box
      display={Display.Flex}
      flexDirection={[FlexDirection.Column, FlexDirection.Row]}
      alignItems={[AlignItems.Center, AlignItems.FlexStart]}
      justifyContent={JustifyContent.SpaceBetween}
    >
      {STEPS_LIST.map((step, order) => Step({ ...step, index: order }))}
    </Box>
  </>
)
