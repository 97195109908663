import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackCheckoutError, trackUTMAndPromoCode } from 'actions/tracking'
import * as trackingKeys from 'actions/trackingKeys'
import {
  checkoutSignupPayment,
  clearGoustoRef,
  handleCheckoutError,
  checkoutCreatePreviewOrder,
} from 'routes/Checkout/checkoutActions'
import { checkPayment } from 'routes/Checkout/checkoutPaymentsApis'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'
import { getSessionId } from 'selectors/cookies'

const errorCodes = {
  duplicateDetails: '409-duplicate-details',
  promoCodeHasBeenUsed: '409-offer-has-been-used',
  challengeFailed: '3ds-challenge-failed',
  signupLoginFailed: 'signupLoginFailed',
}

export const checkPaymentAuth: CheckoutActionCreator =
  (checkoutSessionId: string, { pricing }: { pricing: Pricing }) =>
  async (dispatch, _getState) => {
    dispatch({ type: actionTypes.PAYMENT_HIDE_MODAL })
    dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, true))

    try {
      const { data } = await checkPayment(checkoutSessionId, getSessionId())
      if (data && data.approved) {
        dispatch(trackUTMAndPromoCode(trackingKeys.signupChallengeSuccessful))

        await dispatch(checkoutSignupPayment(data.sourceId, { pricing }))
      } else {
        dispatch(trackUTMAndPromoCode(trackingKeys.signupChallengeFailed))
        dispatch(
          trackCheckoutError(
            actionTypes.CHECKOUT_SIGNUP,
            errorCodes.challengeFailed,
            'checkPaymentAuth',
          ),
        )
        dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, errorCodes.challengeFailed))
        await dispatch(checkoutCreatePreviewOrder())
        dispatch(clearGoustoRef())
        dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, false))
      }
    } catch (err) {
      await dispatch(handleCheckoutError(err, 'checkPaymentAuth'))
      dispatch(clearGoustoRef())
      dispatch(statusActions.pending(actionTypes.CHECKOUT_SIGNUP, false))
    }
  }
