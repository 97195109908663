import { connect } from 'react-redux'

import { userFetchReferralOffer } from 'actions/user'
import { trackWelcomeAppPromoClick, fetchData } from 'actions/welcome'

import { WelcomeWrapper } from './WelcomeWrapper'

function mapStateToProps(state, ownProps) {
  const { user, request } = state
  const { params, query } = ownProps

  return {
    orderId: params.orderId,
    user,
    device: request.get('browser'),
    query,
  }
}

const WelcomeContainer = connect(mapStateToProps, {
  userFetchReferralOffer,
  trackWelcomeAppPromoClick,
  fetchData,
})(WelcomeWrapper)

export { WelcomeContainer }
