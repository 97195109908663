import React, { memo } from 'react'

import {
  Box,
  Text,
  Color,
  Display,
  AlignItems,
  JustifyContent,
  Heading1,
  Icon,
  IconVariant,
  Space,
  FontWeight,
  TextAlign,
  BorderStyle,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import { PricePerServingMessage } from 'PricePerServingMessage'
import { useSelector } from 'react-redux'

import { Receipt } from 'components/Receipt'
import { deliveryPriceConfig } from 'config/deliveryPrice'
import { useIsCheckoutBoxTangibilityV3Enabled } from 'routes/Checkout/hooks'
import { usePricing } from 'routes/Menu/domains/pricing'
import { getBasketRecipesCount } from 'selectors/basket'

import { PromoCode } from '../PromoCode'
import { TickText } from '../TickText'
import { summaryTexts } from './data'

type SummaryProps = {
  isLoading: boolean
}

export const Summary = memo(({ isLoading }: SummaryProps) => {
  const { pricing } = usePricing()
  const numRecipes = useSelector(getBasketRecipesCount)

  const isCheckoutBoxTangibilityV3Enabled = useIsCheckoutBoxTangibilityV3Enabled()

  const deliveryPrice = deliveryPriceConfig.deliveryPriceFormatted
  const { orderSummaryTitle, highlightedText, ordinaryTextStart, ordinaryTextEnd } = summaryTexts

  return (
    <Box bg={Color.White} paddingH={3} paddingV={6} data-testing="checkoutOrderSummary">
      <Heading1 size={7}>{orderSummaryTitle}</Heading1>
      <Space size={4} direction="vertical" />

      {isLoading ? (
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          minHeight="18.75rem"
          data-testid="loadingModal"
        >
          <Box width="3rem">
            <Loading />
          </Box>
        </Box>
      ) : (
        <Box data-testid="contentContainer">
          <Box
            bg={Color.Success_50}
            borderColor={Color.Success_200}
            paddingV={2}
            paddingH={3}
            display={Display.Flex}
            alignItems={AlignItems.Center}
            justifyContent={JustifyContent.Center}
            borderWidth={0.5}
            borderStyle={BorderStyle.Solid}
            borderRadius={1.5}
            data-testid="pricePerServingMessage"
          >
            <Text color={Color.Success_900}>
              <Space size={2} direction="vertical" />
              <Icon name="offer_percentage" variant={IconVariant.Inherit} />
            </Text>
            <Space size={2} direction="horizontal" />
            <PricePerServingMessage
              isPriceInCheckout
              fullPrice={pricing?.pricePerPortion}
              discountedPrice={pricing?.pricePerPortionDiscounted}
            />
          </Box>
          {isCheckoutBoxTangibilityV3Enabled ? (
            <Space size={2} direction="vertical" />
          ) : (
            <Space size={5} direction="vertical" />
          )}
          <Box>
            {/* We already pass "pricing" to "prices" prop of the Receipt component so Receipt should be refactored to extract it's additional props from the prices prop? */}
            <Receipt
              numRecipes={numRecipes}
              prices={pricing}
              deliveryTotalPrice={pricing?.deliveryTotal}
              surchargeCount={pricing?.surchargeCount}
              surchargeTotal={pricing?.surchargeTotal}
              recipeTotalPrice={pricing?.recipeTotal}
              totalToPay={pricing?.total || ''}
              recipeDiscountAmount={pricing?.recipeDiscount}
              recipeDiscountPercent={pricing?.percentageOff}
              extrasTotalPrice={pricing?.productTotal}
              isDeliveryFree={pricing?.isDeliveryFree}
              isReceiptInCheckout
            />
            <PromoCode />
            {pricing?.isDeliveryFree && (
              <TickText
                textAlign={TextAlign.Left}
                fontWeight={FontWeight.Normal}
                tickPadding={2}
                highlightedText={highlightedText}
                ordinaryText={`${ordinaryTextStart} ${deliveryPrice}${ordinaryTextEnd}`}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
})

Summary.displayName = 'Summary'
