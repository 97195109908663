// [TR-2087] - Used a forEach for all the below to catch any missing data that may be in normalisedRelationships but missing from normalisedData

const cropsTransformer = (media) =>
  media.map((url) => ({
    src: url.url,
    width: url.width,
  }))

const surchargeTransformer = (surcharge) => {
  if (!surcharge) return null
  const decimalSurcharge = surcharge.price.value / 100

  return {
    listPrice: decimalSurcharge,
  }
}

const isBlueyRecipeTransformer = (attributes) =>
  Boolean(attributes.food_brand && attributes.food_brand.slug === 'bluey')

const isFineDineInTransformer = (attributes) =>
  Boolean(attributes.food_brand && attributes.food_brand.slug === 'fine-dine-in')

const isNewTransformer = (debutRecipes, recipeId) => {
  if (debutRecipes && debutRecipes.data && debutRecipes.data.length) {
    return debutRecipes.data.some((recipe) => recipe.core_recipe_id === recipeId)
  }

  return false
}

export {
  cropsTransformer,
  surchargeTransformer,
  isBlueyRecipeTransformer,
  isFineDineInTransformer,
  isNewTransformer,
}
