import React, { HTMLAttributes } from 'react'

import styled from '@emotion/styled'
import { useTheme } from '@gousto-internal/citrus-react'

export const HeroImageElement = (props: HTMLAttributes<HTMLDivElement>) => {
  const { theme } = useTheme()

  const breakpointFromMedium = theme.tokens.breakpoints[0]
  const fromMediumWidth: number = +breakpointFromMedium.split('px')[0]
  const breakpointFromXLarge = theme.tokens.breakpoints[2]

  const BreakpointToMedium = `@media (max-width: ${fromMediumWidth - 1}px)`
  const BreakpointFromMedium = `@media (min-width: ${breakpointFromMedium})`
  const BreakpointFromXLarge = `@media (min-width: ${breakpointFromXLarge})`

  const Component = styled.div({
    width: '100%',

    [BreakpointToMedium]: {
      minHeight: '57vw',
      height: '57vw',
      marginBottom: '1rem',
    },

    [BreakpointFromMedium]: {
      maxWidth: '32rem',
    },

    [BreakpointFromXLarge]: {
      maxWidth: '35rem',
    },

    picture: {
      display: 'flex',

      width: '100%',
      height: '100%',
    },

    img: {
      width: '100%',

      [BreakpointToMedium]: {
        minHeight: '57vw',
        height: '57vw',

        objectPosition: 'center left',
        objectFit: 'cover',
      },

      [BreakpointFromMedium]: {
        maxWidth: '32rem',

        objectPosition: '50% 50%',
        objectFit: 'cover',
      },

      [BreakpointFromXLarge]: {
        maxWidth: '35rem',
      },
    },
  })

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />
}
