import React from 'react'

import {
  BorderStyle,
  Box,
  Color,
  DisplayPosition,
  Heading3,
  Overflow,
  Space,
  TextAlign,
  Stars,
  TimeIndicator,
  Text,
  AlignItems,
} from '@gousto-internal/citrus-react'
import GoustoImage from 'Image'
import Immutable from 'immutable'

import { removeDiacritics } from 'utils/sanitizeText'

import { TimeIndicatorContainer } from './SimpleRecipe.styled'

type SimpleRecipeProps = {
  title: string
  media: Immutable.List<{
    width: number
    src: string
  }>
  maxMediaSize: number
  averageRating: number
  ratingCount: number
  cookingTime: number
}

export const SimpleRecipe = ({
  media,
  title,
  maxMediaSize,
  averageRating,
  ratingCount,
  cookingTime,
}: SimpleRecipeProps) => {
  const recipeTitle = removeDiacritics(title)

  return (
    <Box
      position={DisplayPosition.Relative}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      bg={Color.ColdGrey_900}
      width="100%"
      borderColor={Color.ColdGrey_600}
    >
      <Box minHeight="10rem" position={DisplayPosition.Relative}>
        <GoustoImage media={media} title={title} maxMediaSize={maxMediaSize} lazy />
        <Box position={DisplayPosition.Absolute} bottom="1rem" left="1rem">
          <TimeIndicatorContainer data-testid="TimeIndicator">
            <TimeIndicator time={cookingTime} />
          </TimeIndicatorContainer>
        </Box>
      </Box>
      <Box paddingH={4} paddingV={4}>
        <Box overflowX={Overflow.Hidden} overflowY={Overflow.Hidden} height="3rem">
          <Heading3 textAlign={TextAlign.Left} size={4} color={Color.White}>
            {recipeTitle}
          </Heading3>
        </Box>
        <Space direction="vertical" size={2} />
        <Box height="1.25rem">
          {ratingCount && averageRating ? (
            <Box
              data-testid="Rating"
              display="inline-flex"
              gap={1}
              alignItems={AlignItems.Center}
              minWidth="200px"
            >
              <Stars rating={averageRating} size={[4, 5]} />
              <Text
                color={Color.White}
                size={1}
                style={{ paddingBottom: '4px', paddingLeft: '4px' }}
              >
                {ratingCount} reviews
              </Text>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}
