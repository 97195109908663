import React, { useEffect, useRef, useState } from 'react'

import { BorderStyle, Box, Color, Space } from '@gousto-internal/citrus-react'
import { HotjarTrigger } from 'HotjarTrigger'
import { Recaptcha } from 'Recaptcha'
import { NullableReCAPTCHA } from 'Recaptcha/useChallengeClosingObserver'
import { RibbonTriggerContainer } from 'RibbonTrigger'
import { useSelector, useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import {
  CheckoutPaymentDetails,
  HostedFields,
} from 'routes/Checkout/Components/CheckoutPaymentDetails'
import { Checkout3DSModal } from 'routes/Checkout/Components/CheckoutPaymentDetails/Checkout3DSModal/Checkout3DSModal'
import { CheckoutPayPalDetailsContainer } from 'routes/Checkout/Components/CheckoutPaymentDetails/CheckoutPayPalDetails'
import { CheckoutPaymentMethodSelector } from 'routes/Checkout/Components/CheckoutPaymentDetails/CheckoutPaymentMethodSelector/CheckoutPaymentMethodSelector'
import { PayPalConfirmation } from 'routes/Checkout/Components/CheckoutPaymentDetails/PayPalConfirmation'
import { SectionHeader } from 'routes/Checkout/Components/SectionHeader'
import { SubscriptionTransparency } from 'routes/Checkout/Components/SubscriptionTransparency'
import { TermsAndConditions } from 'routes/Checkout/Components/TermsAndConditions'
import { loginAfterSignup } from 'routes/Checkout/checkoutActions'
import { getCurrentPaymentMethod, isPayPalReady } from 'routes/Checkout/checkoutPaymentSelectors'
import { getRecaptchaExecutionStarted } from 'routes/Checkout/checkoutSelectors'
import { PaymentMethod } from 'routes/Signup/signupConfig'

export type PaymentProps = {
  prerender: boolean
  checkoutScriptReady: boolean
  submitOrder: () => void
  onLoginClick: () => void
  isPayPalInitialized: boolean
  setIsPayPalInitialized: (value: boolean) => void
  braintreeClientInstance: braintree.Client | null
  setBraintreeClientInstance: (value: braintree.Client | null) => void
}

export const Payment = ({
  prerender,
  checkoutScriptReady,
  submitOrder,
  onLoginClick,
  isPayPalInitialized,
  setIsPayPalInitialized,
  braintreeClientInstance,
  setBraintreeClientInstance,
}: PaymentProps) => {
  const dispatch = useDispatch()
  const recaptchaExecutionStarted = useSelector(getRecaptchaExecutionStarted)
  const isPayPalPaymentApproved = useSelector(isPayPalReady)
  const currentPaymentMethod = useSelector(getCurrentPaymentMethod)
  const recaptchaRef = useRef<NullableReCAPTCHA>(null)
  const [hasGotRecaptchaToken, setHasGotRecaptchaToken] = useState(false)
  const isMigrateToBraintreeEnabled = true

  const onRecaptchaTokenChange = (token: string | null) => {
    // to react only on first reCAPTCHA token (when token is expired, this method is called with null)
    if (hasGotRecaptchaToken) return

    setHasGotRecaptchaToken(true)
    dispatch(loginAfterSignup(token))
  }

  const onRecaptchaChallengeClose = () => {
    if (!hasGotRecaptchaToken) {
      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP_LOGIN, true))
    }
  }

  useEffect(() => {
    if (!recaptchaExecutionStarted) return

    if (recaptchaRef.current) {
      recaptchaRef.current.execute()
    } else {
      dispatch(loginAfterSignup())
    }
  }, [dispatch, recaptchaExecutionStarted])

  return (
    <Box
      data-testid="checkoutPaymentSection"
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      bg={Color.White}
      paddingH={[3, 6]}
      paddingV={[6, 8]}
      display={prerender ? 'none' : 'block'}
    >
      {/* Refactor SectionHeader to accept subtitle as children */}
      <SectionHeader title="Payment method" />
      <CheckoutPaymentMethodSelector />
      <Space direction="vertical" size={2} />
      {isMigrateToBraintreeEnabled ? (
        <HostedFields
          submitOrder={submitOrder}
          onLoginClick={onLoginClick}
          hide={currentPaymentMethod !== PaymentMethod.Card}
          braintreeClientInstance={braintreeClientInstance}
          setBraintreeClientInstance={setBraintreeClientInstance}
        />
      ) : (
        <CheckoutPaymentDetails
          checkoutScriptReady={checkoutScriptReady}
          submitOrder={submitOrder}
          onLoginClick={onLoginClick}
          hide={currentPaymentMethod !== PaymentMethod.Card}
        />
      )}
      {isPayPalPaymentApproved && (
        <PayPalConfirmation submit={submitOrder} onLoginClick={onLoginClick} />
      )}
      <CheckoutPayPalDetailsContainer
        hide={currentPaymentMethod !== PaymentMethod.PayPal}
        submit={submitOrder}
        isPayPalInitialized={isPayPalInitialized}
        setIsPayPalInitialized={setIsPayPalInitialized}
        braintreeClientInstance={braintreeClientInstance}
        setBraintreeClientInstance={setBraintreeClientInstance}
      />
      <Checkout3DSModal />
      {!prerender && (
        <Recaptcha
          ref={recaptchaRef}
          onChange={onRecaptchaTokenChange}
          onChallengeClose={onRecaptchaChallengeClose}
        />
      )}
      <SubscriptionTransparency />
      <TermsAndConditions />

      {!prerender && <HotjarTrigger name="psd2_modal" shouldInvoke />}
      {!prerender && <RibbonTriggerContainer name="checkout-payment" />}
    </Box>
  )
}
