import { Box } from '@gousto-internal/citrus-react'
import React from 'react'

import { BrandEndorsementsSection } from './components/BrandEndorsementsSection/BrandEndorsementsSection'
import { EffortlessHomeCookingSection } from './components/EffortlessHomeCookingSection/EffortlessHomeCookingSection'
import { EndlessVarietySection } from './components/EndlessVarietySection'
import { EnvironmentalBenefitsSection } from './components/EnvironmentalBenefitsSection/EnvironmentalBenefitsSection'
import { HeroCarouselSection } from './components/HeroCarouselSection/HeroCarouselSection'
import { HeroSection } from './components/HeroSection/HeroSection'
import { PromoBanner, PromoBannerText } from './components/PromoBanner'
import { TrustPilotSection } from './components/TrustPilotSection/TrustPilotSection'
import { ValueYouCanTasteSection } from './components/ValueYouCanTasteSection/ValueYouCanTasteSection'
import type { Recipe } from './types/endlessVarietyCarousel'

export type HomepageProps = {
  applyDiscount: () => void
  isPromoBannerVisible: boolean
  promoBannerText: string
  onSignupCTAClick: () => void
  signupCTA: string
  recipes: Record<string, Recipe>
}

export function Homepage({
  applyDiscount,
  promoBannerText,
  isPromoBannerVisible,
  signupCTA,
  onSignupCTAClick,
  recipes,
}: HomepageProps) {
  return (
    <Box data-testid="homepage-redesigned">
      {isPromoBannerVisible && (
        <PromoBanner applyDiscount={applyDiscount}>
          <PromoBannerText>{promoBannerText}</PromoBannerText>
        </PromoBanner>
      )}
      <HeroSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
      <HeroCarouselSection />
      <TrustPilotSection />
      <EffortlessHomeCookingSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
      <ValueYouCanTasteSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
      <EndlessVarietySection
        signupCTA={signupCTA}
        onCTAClick={onSignupCTAClick}
        recipes={recipes}
      />
      <BrandEndorsementsSection />
      <EnvironmentalBenefitsSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
    </Box>
  )
}
