import React from 'react'

import {
  Box,
  Space,
  Text,
  Display,
  FontFamily,
  FontWeight,
  FlexDirection,
  AlignItems,
  Color,
} from '@gousto-internal/citrus-react'

import { Cta, useGetCtaProps } from '../Cta'
import { SectionTitle } from '../SectionTitle'
import { lowestPricePerServing } from '../homeConfig'
import { StepsGuide } from './StepsGuide'
import { SECTION_TITLE } from './data'

export const WhyChooseGoustoSubtitle = () => (
  <>
    Impressively easy meals from just{' '}
    <Text display={Display.Inline} fontFamily={FontFamily.Bold} data-testid="PricePerServing">
      £{lowestPricePerServing} per serving
    </Text>
    <Text display={Display.Inline} fontWeight={FontWeight.Bold}>
      <sup>*</sup>
    </Text>
    . All of the flavour, none of the fuss.
  </>
)

export const WhyChooseGousto = () => {
  const { ctaUri, ctaText } = useGetCtaProps()

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      data-testid="WhyChooseGousto"
      alignItems={AlignItems.Center}
      paddingV={[12, 10]}
      paddingH={[3, 8]}
      paddingBottom={[10, 6]}
      bg={Color.White}
    >
      <SectionTitle title={SECTION_TITLE} subTitle={<WhyChooseGoustoSubtitle />} />
      <StepsGuide />
      <Space direction="vertical" size={[0, 8]} />
      <Box display={[Display.None, Display.Block]} width="17.5rem">
        <Cta ctaUri={ctaUri} trackingSectionName="whyChooseGousto">
          {ctaText}
        </Cta>
      </Box>
    </Box>
  )
}
