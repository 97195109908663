import React from 'react'

import { Box, Button, Color, Display, Space, Visible } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { cta } from 'routes/BoxPrices/boxPricesConfig'
import { Benefits, BenefitType } from 'routes/Home/Benefits'

import {
  BoxComparisonTable,
  BoxComparisonTableHeader,
} from '../BoxPriceBlock/BoxPriceComparisonTable'
import { BoxComparisonHeaderType } from '../BoxPriceBlock/boxPriceBlockModels'
import { FixedCtaContainer } from '../BoxPricesList/FixedCtaContainer/FixedCtaContainer'
import { boxPricesBoxSizeSelected } from '../boxPricesActions'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'
import { applySaveAndSavourDiscount, isNumberString } from '../boxPricesUtils'

type BoxPricesComparisonSectionType = Pick<
  BoxPricesWithAllPortionsType,
  'activeNumPortion' | 'activeRecipes' | 'boxInfo'
> & {
  selectedRecipeIndex: number
  isBestPrice: number
}

export const BoxPricesComparisonSection = ({
  selectedRecipeIndex,
  isBestPrice,
  activeNumPortion,
  activeRecipes,
  boxInfo,
}: BoxPricesComparisonSectionType) => {
  const hasBestPrice = isBestPrice === selectedRecipeIndex
  const dispatch = useDispatch()
  const boxPricesBoxSizeSelect = () => dispatch(boxPricesBoxSizeSelected(activeNumPortion))
  const moreRecipesHeader = `Select ${isBestPrice - selectedRecipeIndex} more recipe${
    selectedRecipeIndex < isBestPrice ? '' : 's'
  } for the best price`
  const bestPriceHeader = `You've got the best price per portion!`
  const {
    num_portions: numPortions,
    price_per_portion: pricePerPortion,
    total: pricePerBox,
  } = boxInfo[activeNumPortion][activeRecipes]

  const { saveAndSavourPricePerBox, saveAndSavourPricePerPortion } =
    isNumberString(pricePerBox) && isNumberString(pricePerPortion)
      ? applySaveAndSavourDiscount(pricePerBox, numPortions * activeNumPortion, pricePerPortion)
      : {
          saveAndSavourPricePerBox: null,
          saveAndSavourPricePerPortion: null,
        }

  const CtaBlock = (
    <>
      <Button onClick={boxPricesBoxSizeSelect} color={Color.Secondary_600} width="100%">
        <Space size={10} direction="vertical" />
        {cta}
      </Button>
      <Space size={2} direction="vertical" />
      <Benefits pickFilter={[BenefitType.NO_LOCK_IN]} />
    </>
  )

  return (
    <Box>
      <Space size={5} direction="vertical" />
      <BoxComparisonTableHeader
        type={
          hasBestPrice
            ? BoxComparisonHeaderType.BEST_PRICE
            : BoxComparisonHeaderType.SELECT_MORE_RECIPES
        }
        headerValue={hasBestPrice ? bestPriceHeader : moreRecipesHeader}
      />
      <BoxComparisonTable
        headerType={BoxComparisonHeaderType.CLASSIC_GOUSTO}
        headerValue={`${selectedRecipeIndex + 2} classic Gousto recipes`}
        pricePerBox={pricePerBox || '--.--'}
        pricePerPortion={pricePerPortion || '--.--'}
      />

      <BoxComparisonTable
        headerType={BoxComparisonHeaderType.SAVE_AND_SAVOUR}
        headerValue={`${selectedRecipeIndex + 2} Save and Savour recipes`}
        pricePerBox={saveAndSavourPricePerBox || '--.--'}
        pricePerPortion={saveAndSavourPricePerPortion || '--.--'}
      />

      <Space size={4} direction="vertical" />
      <Box>
        <Benefits pickFilter={[BenefitType.PAID_DELIVERY]} />
      </Box>
      <Space size={4} direction="vertical" />

      <Visible display={[Display.None, Display.Block]}>{CtaBlock}</Visible>

      <Visible display={[Display.Block, Display.None]}>
        <FixedCtaContainer>{CtaBlock}</FixedCtaContainer>
      </Visible>
    </Box>
  )
}
