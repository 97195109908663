export type ImageData = {
  imageUrl: string
}

// minTwoDigits because of how the images are named, i.e. 1 should be 01
const minTwoDigits = (num: number) => (num < 10 ? '0' : '') + num

export const getHeroCarouselData = () => {
  const numArray = Array.from(Array(20).keys())
  const HERO_CAROUSEL_DATA = numArray.map((num) => {
    const recipeNum = minTwoDigits(num + 1)

    return {
      imageUrl: `https://production-media.gousto.co.uk/cms/homepage-image/carousel-imgs-${recipeNum}.jpg`,
      key: `carousel-imgs-${recipeNum}`,
    }
  })

  return HERO_CAROUSEL_DATA
}
