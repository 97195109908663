import React from 'react'

import { Button } from './CarouselArrow.styled'

type ArrowProp = {
  side: 'arrowLeft' | 'arrowRight'
}

type onClick = {
  onClick?: () => void
}

export const Arrow = ({ side, ...props }: ArrowProp) => {
  const stripInvalidHtmlProps = (allProps: Partial<Record<string, unknown>>) => {
    const carouselPropKeys = ['currentSlide', 'slideCount']

    return Object.entries(allProps).reduce(
      (_htmlProps, [key, val]) => ({
        ..._htmlProps,
        ...(!carouselPropKeys.includes(key) && { [key]: val }),
      }),
      {},
    )
  }

  const { onClick } = stripInvalidHtmlProps(props) as onClick

  return <Button onClick={onClick} type="button" tabIndex={0} side={side} data-role="none" />
}
